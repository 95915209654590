import ReactDOM from 'react-dom';
import App from '@/App';
import '@/index.css';
import '@/global.css';

if ('IntersectionObserver' in window) {
  require('intersection-observer');
}

ReactDOM.render(<App />, document.getElementById('root'));
