import { useEffect, useRef, useState, useCallback } from 'react';
import MainHeader from '@/components/custom/MainHeader';
import { Link } from 'react-router-dom';
import { Paths } from '@/utils/constants';
import { colorOpt } from '@/interactive/questionSteps';

const About = () => {
  const dotRef = useRef(undefined);
  const [dotWrapSize, setDotWrapSize] = useState(0);

  useEffect(() => {
    function handleResize() {
      setDotWrapSize(dotRef?.current.clientWidth);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleEvtEnter = useCallback((e) => {
    if (e.target) e.target.style.backgroundColor = `#${colorOpt[Math.floor(Math.random() * colorOpt.length)]}`;
  }, []);

  return (
    <>
      <div className="w-full h-fill flex flex-col overflow-hidden fixed">
        <div className="w-full flex-grow flex flex-col justify-between px-4 py-8 lg:p-8 lg:text-xl overflow-y-auto overflow-x-hidden">
          <div className="flex flex-col lg:flex-row gap-10">
            <div ref={dotRef} className="w-full lg:w-2/5 relative" style={{ height: dotWrapSize + 'px' }}>
              {[...Array(6)].map((x, i) => (
                <div key={i} className="flex flex-row h-1/6">
                  {i > 3
                    ? [...Array(4)].map((y, j) => (
                        <div
                          key={j}
                          onMouseEnter={(e) => handleEvtEnter(e)}
                          className="dot w-1/6 h-full rounded-full"
                          style={{ backgroundColor: 'black' }}
                        />
                      ))
                    : [...Array(6)].map((y, j) => (
                        <div
                          key={j}
                          onMouseEnter={(e) => handleEvtEnter(e)}
                          className="dot w-1/6 h-full rounded-full"
                          style={{ backgroundColor: 'black' }}
                        />
                      ))}
                </div>
              ))}
              <Link to={Paths.draw} className="about-join text-xl xl:text-3xl">
                참여하기 →
              </Link>
            </div>
            <div className="w-full lg:w-3/5">
              <h2 className="font-normal">「2022 박물관·미술관 주간」 ‘박물관의 힘’ 주제형 프로그램</h2>
              <h1 className="text-2xl lg:text-4xl my-2 lg:my-4">《뮤지엄 타임캡슐》 : 미래로 향한 뮤지엄</h1>
              <div className="text-2xl lg:text-4xl pt-4 lg:pt-8 font-extralight">
                <p className="leading-normal">환기미술관의 미래를 함께할 모든 이들을 위한 디지털 전시 콘텐츠 '뮤지엄 타임캡슐'.</p>
                <p className="mt-2 leading-normal">
                  뮤지엄 타임캡슐은 관람객에게 질문을 던지고 이에 답한 관람객의 감상은 색·면·점이 되어 새로운 조형언어로 공간을 넘어 미래로 향해
                  갑니다.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col pt-10 md:text-lg">
            <div className="flex flex-col lg:flex-row gap-10">
              <div className="flex flex-col gap-y-2 w-full lg:w-2/5 leading-relaxed">
                <h3 className="mt-6 font-normal">크레딧</h3>
                <div className="flex flex-row">
                  <span className="flex-shrink-0 mr-6">주최</span>
                  <div>
                    <a className="creditLink" target="_blank" href="https://www.mcst.go.kr/" rel="noreferrer">
                      문화체육관광부,
                    </a>
                    &ensp;
                    <a className="creditLink" target="_blank" href="http://www.icomkorea.org/" rel="noreferrer">
                      국제박물관협의회 한국위원회(ICOM Korea),
                    </a>
                    &ensp;
                    <a className="creditLink" target="_blank" href="https://www.뮤지엄위크.kr/" rel="noreferrer">
                      박물관·미술관 주간 운영위원회
                    </a>
                  </div>
                </div>
                <div className="flex flex-row">
                  <span className="flex-shrink-0 mr-6">주관</span>
                  <a className="creditLink" target="_blank" href="https://www.nmf.or.kr/" rel="noreferrer">
                    국립박물관문화재단
                  </a>
                </div>
                <div className="flex flex-row">
                  <span className="flex-shrink-0 mr-6">운영</span>
                  <a className="creditLink" target="_blank" href="http://www.whankimuseum.org/" rel="noreferrer">
                    (재)환기재단 · 환기미술관
                  </a>
                </div>
                <div className="flex flex-row">
                  <span className="flex-shrink-0 mr-6">디지털아트</span>
                  <a className="creditLink" target="_blank" href="https://commoners.co.kr" rel="noreferrer">
                    커머너즈
                  </a>
                </div>
              </div>
              <div className="w-full lg:w-3/5 leading-relaxed ">
                <h3 className="mt-6 font-normal">2022 박물관·미술관 주간</h3>
                <p className="py-2">
                  환기미술관 「《뮤지엄 타임캡슐》 : 미래로 향한 뮤지엄」은 「2022 박물관·미술관 주간」이 진행한 ‘박물관의 힘’ 주제형 프로그램 공모에
                  선정된 전시 프로그램입니다.
                </p>
                <p className="py-2">
                  「2022 박물관·미술관 주간」은 “박물관의 힘(The Power of Museum)”을 주제로 사회발전에 기여하는 박물관·미술관의 선한 영향력과 역할을
                  모색해 보고, 연계 프로그램 운영을 통해 국민 문화체험 기회 확대 및 박물관·미술관 활성화를 도모하고자 진행되는 사업입니다.
                </p>
              </div>
            </div>
            <div className="flex flex-row flex-wrap w-full items-center gap-x-4 pt-10 select-none">
              <img className="h-10" src="/about/logo-mcst.png" alt="문화체육관광부" />
              <img className="h-10" src="/about/logo-icom.png" alt="국제박물관협의회 한국위원회" />
              <img className="h-10" src="/about/logo-week.png" alt="박물관·미술관 주간 운영위원회" />
              <img className="h-10" src="/about/logo-nmfk.png" alt="국립박물관문화재단" />
              <img className="h-10" src="/about/logo-whanki.png" alt="(재)환기재단·환기미술관" />
            </div>
          </div>
        </div>
        <MainHeader />
      </div>
    </>
  );
};

export default About;
