export const checkMobile = () => {
  if (typeof window === 'undefined') return false;
  return 'ontouchstart' in window;
};

export const checkWebViewAndDevice = () => {
  const standalone = window.navigator.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  let isWebView;
  let device;
  if (ios) {
    device = 'ios';
    if (!standalone && safari) {
      // Safari
      isWebView = false;
    } else if (!standalone && !safari) {
      // iOS webview
      isWebView = true;
    }
  } else {
    device = 'android';
    if (userAgent.includes('wv') || userAgent.includes('kakaotalk') || userAgent.includes('naver') || userAgent.includes('whale')) {
      // Android webview
      isWebView = true;
    } else {
      // Chrome
      isWebView = false;
    }
  }

  return {
    isWebView: isWebView,
    device: device,
  };
};

export const createEvtKey = (opt) => {
  opt.evtKey = {};
  const isMobile = opt.isMobile ? opt.isMobile : checkMobile();
  switch (isMobile) {
    case false: {
      // mouse
      opt.evtKey.down = 'mousedown';
      opt.evtKey.move = 'mousemove';
      opt.evtKey.up = 'mouseup';
      opt.evtKey.out = 'mouseout';
      opt.evtKey.wheel = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
      opt.evtKey.dragStart = 'dragstart';
      opt.evtKey.dragOver = 'dragover';
      opt.evtKey.enter = 'mouseenter';
      opt.evtKey.click = 'click';
      break;
    }
    case true:
    default: {
      // touch
      opt.evtKey.down = 'touchstart';
      opt.evtKey.move = 'touchmove';
      opt.evtKey.up = 'touchend';
      opt.evtKey.out = 'dragleave';
      opt.evtKey.wheel = 'touchmove';
      opt.evtKey.dragStart = 'touchstart';
      opt.evtKey.dragOver = 'dragover';
      opt.evtKey.enter = 'touchmove';
      opt.evtKey.click = 'click';
      break;
    }
  }
};

export const createEvtListener = (evtKey, callback, targetDom = window) => {
  return {
    evtKey: evtKey,
    callback: callback,
    targetDom: targetDom,
  };
};

export const mapRange = (value, start1, stop1, start2, stop2) => {
  const newValue = ((value - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  if (start2 < stop2) {
    return minMax(newValue, start2, stop2);
  } else {
    return minMax(newValue, stop2, start2);
  }
};

export const minMax = (number, low, high) => {
  return Math.max(Math.min(number, high), low);
};

export const capitalize = (str) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const rgbToHsl = (r, g, b) => {
  if (Array.isArray(r)) {
    const arr = JSON.parse(JSON.stringify(r));
    r = parseFloat(arr[0]);
    g = parseFloat(arr[1]);
    b = parseFloat(arr[2]);
  } else {
    r = parseFloat(r);
    g = parseFloat(g);
    b = parseFloat(b);
  }

  r = Math.min(Math.max(r, 0), 255) / 255;
  g = Math.min(Math.max(g, 0), 255) / 255;
  b = Math.min(Math.max(b, 0), 255) / 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  h = Math.min(Math.max(Math.round(h * 360), 0), 360);
  s = Math.min(Math.max(Math.round(s * 100), 0), 100);
  l = Math.min(Math.max(Math.round(l * 100), 0), 100);

  return [h, s, l]; // [360, 100, 100]
};

export const hslToRgb = (h, s, l) => {
  if (Array.isArray(h)) {
    const arr = JSON.parse(JSON.stringify(h));
    h = parseFloat(arr[0]);
    s = parseFloat(arr[1]);
    l = parseFloat(arr[2]);
  } else {
    h = parseFloat(h);
    s = parseFloat(s);
    l = parseFloat(l);
  }

  h = Math.min(Math.max(h, 0), 360) / 360;
  s = Math.min(Math.max(s, 0), 100) / 100;
  l = Math.min(Math.max(l, 0), 100) / 100;

  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  r = Math.min(Math.max(Math.round(r * 255), 0), 255);
  g = Math.min(Math.max(Math.round(g * 255), 0), 255);
  b = Math.min(Math.max(Math.round(b * 255), 0), 255);

  return [r, g, b]; // [255, 255, 255]
};

export const hexToRgb = (str) => {
  if (str.length !== 6) return;

  const aRgbHex = str.match(/.{1,2}/g);
  const r = parseInt(aRgbHex[0], 16);
  const g = parseInt(aRgbHex[1], 16);
  const b = parseInt(aRgbHex[2], 16);

  return [r, g, b]; // [255, 255, 255]
};

const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

export const rgbToHex = (r, g, b) => {
  r = Math.min(Math.max(Math.round(r), 0), 255);
  g = Math.min(Math.max(Math.round(g), 0), 255);
  b = Math.min(Math.max(Math.round(b), 0), 255);
  return `${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

export const waitForEl = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};
