import short from 'short-uuid';
import { now } from '@/utils';

function id() {
  return `${short.generate()}`;
}

export const PARTS_DATA: PartDataList = [
  {
    id: id(),
    createTime: now(),
    sceneColor: '3096CD',
    holeScaleId: '2',
    holeScale: '0.85',
    holeColor: 'f5EBE1',
    cellColorId: '1',
    cellColor: 'ACD5EC',
    cellOpacity: '0.5',
    dotColor: '3361A2',
    dotDistortId: '2',
    dotDistortSize: '0.03',
    dotScaleId: '2',
    dotScale: '0.4675',
    textFuture: `내 작품은 공간의 세계란다. 서울을 생각하며 오만가지 생각하며 찍어 가는 점.`,
    textPower: `어쩌면 내 맘속을 잘 말해 주는 것일까.`,
  },
  {
    id: id(),
    createTime: now(),
    sceneColor: '254573',
    holeScaleId: '2',
    holeScale: '0.9',
    holeColor: 'f5EBE1',
    cellColorId: '0',
    cellColor: '254573',
    cellOpacity: '0',
    dotColor: '56B3AE',
    dotDistortId: '2',
    dotDistortSize: '0.115',
    dotScaleId: '2',
    dotScale: '0.8',
    textFuture: `미술은 하나의 질서다.`,
    textPower: `선과 점을 좀 더 밀고 가보자.`,
  },
  {
    id: id(),
    createTime: now(),
    sceneColor: 'f5EBE1',
    holeScaleId: '2',
    holeScale: '1',
    holeColor: '3B6693',
    cellColorId: '4',
    cellColor: '3B6693',
    cellOpacity: '1',
    dotColor: '32383A',
    dotDistortId: '2',
    dotDistortSize: '0.2',
    dotScaleId: '2',
    dotScale: '0.6',
    textFuture: `이제 이 자신이 똑바로 섰다. 한눈팔지 말고 나는 내 일을 밀고 나가자.`,
    textPower: `그 길밖에 없다. 이 순간부터 막막한 생각이 무너지고 진실로 희망으로 가득 차다.`,
  },
];
