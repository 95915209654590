import { gsap, Power1, Back } from 'gsap';
import { saveAs } from 'file-saver';
import * as THREE from 'three';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TTFLoader } from 'three/examples/jsm/loaders/TTFLoader';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { hexToRgb, hslToRgb, mapRange, rgbToHsl } from './commonUtils';
import { threeOpt, assetIndex } from './mainConfig';

export const getIntersectedLetter = (mousePos, renderer, camera, raycaster, targetObjArr) => {
  if (!mousePos) return;

  const raycasterPos = {
    x: (mousePos.x / renderer.size.width) * 2 - 1,
    y: -(mousePos.y / renderer.size.height) * 2 + 1,
  };
  raycaster.setFromCamera(raycasterPos, camera);
  const intersects = raycaster.intersectObjects(targetObjArr);
  if (!intersects.length) return;
  const obj = intersects[0].object;
  if (obj.name === 'text' || obj.name === 'space' || !obj.name) return;
  if (obj.name === 'letter') {
    return obj;
  } else {
    return obj.parent;
  }
};

export const getMousePosVec = (evt) => {
  if (evt.clientX && evt.clientY) {
    return new THREE.Vector2(evt.clientX, evt.clientY);
  } else if (evt.touches) {
    return new THREE.Vector2(evt.touches[0].clientX, evt.touches[0].clientY);
  }
};

export const flipLetter = (letter) => {
  gsap.to(letter.rotation, {
    duration: threeOpt.animation.flipLetter.time.forward,
    overwrite: true,
    y: Math.PI,
    onComplete: () => {
      gsap.to(letter.rotation, {
        duration: threeOpt.animation.flipLetter.time.backward,
        delay: threeOpt.animation.flipLetter.time.intermission,
        overwrite: true,
        y: 0,
      });
    },
  });
};

const createLetter = (scene, holeColor, holeScale, cellColor, cellOpacity, dotColor, dotDistortSize, dotScale, realLetter, letterOffset) => {
  const letter = new THREE.Group();
  letter.name = 'letter';
  letter.rotationBackUp = {
    x: letter.rotation.x,
    y: letter.rotation.y,
    z: letter.rotation.z,
  };

  // hole
  const holePtArr = [];
  const holeTotalNodeNum = (threeOpt.hole.sideNodeNum + 2) * 4;
  for (let i = 0; i < holeTotalNodeNum; i++) {
    const pt = new THREE.Vector3();
    pt.wiggleParam = Math.max(Math.random() * Math.PI * 2, 0.000001);
    holePtArr.push(pt);
  }
  makePtArrSquared(holePtArr, threeOpt.hole.size, threeOpt.hole.filletRadius);
  const holeMaxDistortSize = threeOpt.hole.size * threeOpt.hole.distortSize;
  makePtArrDistortedNum(holePtArr, holeMaxDistortSize);
  const holeGeo = createGeoFromPtArr(holePtArr);
  const holeMat = new THREE.MeshBasicMaterial({ color: holeColor, transparent: true });
  const hole = new THREE.Mesh(holeGeo, holeMat);
  hole.name = 'hole';
  hole.scale.set(holeScale, holeScale, holeScale);
  hole.renderOrder = 10;
  hole.ptArr = holePtArr;
  letter.add(hole);

  // cell
  const cellPtArr = [];
  const cellTotalNodeNum = (threeOpt.cell.sideNodeNum + 2) * 4;
  const cellMat = new THREE.MeshBasicMaterial({ color: cellColor, transparent: true, opacity: cellOpacity });
  let cell;
  const sceneHasFilled = testSceneHasFilled(scene, scene.colorBlank.clone);
  const sceneAndCellSameColor = testSameColor(scene.background, cellColor) ? (cellOpacity === 1 ? true : false) : false;
  if (sceneAndCellSameColor) {
    hole.material.color = cellColor.clone();
  }
  if (sceneHasFilled && !sceneAndCellSameColor) {
    for (let i = 0; i < cellTotalNodeNum; i++) {
      const pt = new THREE.Vector3();
      pt.wiggleParam = Math.max(Math.random() * Math.PI * 2, 0.000001);
      cellPtArr.push(pt);
    }
    const cellSize = threeOpt.hole.size * threeOpt.cell.scale.default;
    makePtArrSquared(cellPtArr, cellSize, threeOpt.cell.filletRadius);
    const cellMaxDistortSize = cellSize * threeOpt.cell.distortSize;
    makePtArrDistortedNum(cellPtArr, cellMaxDistortSize);
    const cellGeo = createGeoFromPtArr(cellPtArr);
    cell = new THREE.Mesh(cellGeo, cellMat);
  } else {
    for (let i = 0; i < cellTotalNodeNum; i++) {
      const pt = deepClonePt(holePtArr[i]);
      cellPtArr.push(pt);
    }
    cell = hole.clone();
    cell.material = cellMat;
  }
  cell.name = 'cell';
  cell.scale.copy(hole.scale);
  cell.position.z = threeOpt.hole.size * 0.01;
  cell.renderOrder = 20;
  cell.ptArr = cellPtArr;
  letter.add(cell);

  // dot
  const dotPtArr = [];
  const dotTotalNodeNum = (threeOpt.dot.sideNodeNum + 2) * 4;
  for (let i = 0; i < dotTotalNodeNum; i++) {
    const pt = new THREE.Vector3();
    pt.wiggleParam = Math.max(Math.random() * Math.PI * 2, 0.000001);
    dotPtArr.push(pt);
  }
  const dotSize = threeOpt.hole.size;
  makePtArrRadial(dotPtArr, dotSize);
  const dotMaxDistortSize = dotSize * dotDistortSize;
  makePtArrDistortedNum(dotPtArr, dotMaxDistortSize);
  const dotGeo = createGeoFromPtArr(dotPtArr);
  const dotMat = new THREE.MeshBasicMaterial({ color: dotColor, transparent: true });
  const dot = new THREE.Mesh(dotGeo, dotMat);
  dot.name = 'dot';
  dot.renderOrder = 30;
  dot.ptArr = dotPtArr;
  const holeConsideredScale = hole.scale.clone().multiplyScalar(dotScale);
  dot.position.z = threeOpt.hole.size * 0.02;
  dot.scale.copy(holeConsideredScale);
  letter.add(dot);

  // backface (real letter)
  if (realLetter && scene.font) {
    const backShape = scene.font.generateShapes(realLetter, threeOpt.hole.size);
    const backGeo = new THREE.ShapeGeometry(backShape);
    backGeo.rotateY(Math.PI);
    const sceneAndDotSameColor = testSameColor(scene.background, dotColor);
    const letterColor = sceneAndDotSameColor ? scene.colorBlank.clone() : dotColor;
    const backMat = new THREE.MeshBasicMaterial({ color: letterColor });
    const back = new THREE.Mesh(backGeo, backMat);
    back.position.set(threeOpt.hole.size * letterOffset.x, -threeOpt.hole.size * letterOffset.y, threeOpt.hole.size * -0.01);
    const backScale = hole.scale.x * threeOpt.text.letter.scalePerHole;
    back.scale.set(backScale, backScale, backScale);
    letter.add(back);
  }

  return letter;
};

export const createSpace = () => {
  const spaceGeo = new THREE.PlaneGeometry(threeOpt.hole.size * threeOpt.text.letter.interval.space, threeOpt.hole.size);
  const spaceMat = new THREE.MeshBasicMaterial();
  const space = new THREE.Mesh(spaceGeo, spaceMat);
  space.name = 'space';
  space.visible = false;

  return space;
};

const addLettersOfNum = (group, addNum, addPatternArr, addLetterArr, letterOffset, tempScene) => {
  return new Promise((resolve) => {
    if (addNum <= 0) resolve();

    const text = group.parent;
    const scene = tempScene ? tempScene : text.parent;

    for (let i = 0; i < addNum; i++) {
      let addPattern = addPatternArr[i];
      let addLetter = addLetterArr[i];
      let letterObj;
      if (addPattern) {
        letterObj = createLetter(
          scene,
          text.buildSpec.hole.color,
          text.buildSpec.hole.scale,
          text.buildSpec.cell.color,
          text.buildSpec.cell.opacity,
          text.buildSpec.dot.color,
          text.buildSpec.dot.distortSize,
          text.buildSpec.dot.scale,
          addLetter,
          letterOffset,
        );
      } else {
        letterObj = createSpace();
      }

      group.add(letterObj);

      if (i === addNum - 1) resolve();
    }
  });
};

const deleteLettersById = (group, startId, endId, runDelete) => {
  return new Promise((resolve) => {
    if (!runDelete || !group.children.length) resolve();
    let remainNum = endId - startId + 1;

    for (let i = group.children.length - 1; i >= 0; i--) {
      const letter = group.children[i];

      if (i >= startId && i <= endId) {
        letter.removeFromParent();
        remainNum--;
      }

      if (!remainNum) resolve();
    }
  });
};

const arrayLettersByMatrix = (letterObjArr, matrix, textSpec) => {
  const scale = textSpec.letter.size / threeOpt.hole.size;
  const totalHeight = textSpec.letter.bBox.height * matrix.length;
  const yStart = totalHeight * 0.5 + textSpec.letter.bBox.height * -0.5;
  let letterId = 0;
  matrix.forEach((rowData, i) => {
    const y = yStart - i * textSpec.letter.bBox.height;
    const xStart = rowData.sizeSum * -0.5 + rowData.sizeArr[0] * -0.5;
    let xSum = 0;
    rowData.sizeArr.forEach((size, j) => {
      const x = xStart + xSum + size;
      xSum += rowData.sizeArr[j];
      if (letterObjArr[letterId]) {
        letterObjArr[letterId].position.x = x;
        letterObjArr[letterId].position.y = y;
        letterObjArr[letterId].scale.set(scale, scale, scale);
        letterObjArr[letterId].scaleBackUp = scale;
        letterId++;
      }
    });
  });
};

const testUpdateText = (oldPattern, newPattern) => {
  let updatable, deleteNum, addNum;
  if (oldPattern.length === newPattern.length) {
    for (let i = 0; i < newPattern.length; i++) {
      if (oldPattern[i] !== newPattern[i]) {
        deleteNum = addNum = newPattern.length - i;
        updatable = true;
        break;
      }
      if (i === newPattern.length - 1) {
        updatable = false;
      }
    }
  } else if (oldPattern.length > newPattern.length) {
    updatable = true;
    for (let i = 0; i < oldPattern.length; i++) {
      if (newPattern[i]) {
        if (oldPattern[i] !== newPattern[i]) {
          deleteNum = oldPattern.length - i;
          addNum = newPattern.length - i;
          break;
        }
      } else {
        deleteNum = oldPattern.length - i;
        addNum = 0;
        break;
      }
    }
  } else {
    updatable = true;
    for (let i = 0; i < newPattern.length; i++) {
      if (oldPattern[i]) {
        if (newPattern[i] !== oldPattern[i]) {
          deleteNum = oldPattern.length - i;
          addNum = newPattern.length - i;
          break;
        }
      } else {
        deleteNum = 0;
        addNum = newPattern.length - i;
        break;
      }
    }
  }

  return {
    result: updatable,
    num: {
      delete: deleteNum,
      add: addNum,
    },
  };
};

export const updateText = (text, subGroup, textStr) => {
  return new Promise((resolve) => {
    const letterArr = splitText(textStr);
    const test = testUpdateText(subGroup.letterArr, letterArr);

    const deleteStartId = subGroup.patternArr.length - test.num.delete;
    const deleteEndId = deleteStartId + test.num.delete - 1;

    deleteLettersById(subGroup, deleteStartId, deleteEndId).then(() => {
      const patternArr = getPatternArr(letterArr);
      const addPatternArr = JSON.parse(JSON.stringify(patternArr)).splice(patternArr.length - test.num.add, patternArr.length);
      const addLetterArr = JSON.parse(JSON.stringify(letterArr)).splice(letterArr.length - test.num.add, letterArr.length);
      subGroup.letterArr = letterArr;
      subGroup.patternArr = patternArr;
      subGroup.str = textStr;
      addLettersOfNum(subGroup, test.num.add, addPatternArr, addLetterArr, threeOpt.text.letter.offset).then(() => {
        let mergedPatternArr;
        const text0 = text[threeOpt.text.subGroupNameArr[0]];
        const gap = text[threeOpt.text.subGroupNameArr[1]];
        const text1 = text[threeOpt.text.subGroupNameArr[2]];
        let mergedLetterObjArr;

        if (text1.patternArr.length) {
          mergedPatternArr = [...text0.patternArr, ...gap.patternArr, ...text1.patternArr];
          mergedLetterObjArr = [...text0.children, ...gap.children, ...text1.children];
          text1.visible = true;
        } else {
          mergedPatternArr = [...text0.patternArr];
          mergedLetterObjArr = [...text0.children];
          text1.visible = false;
        }

        const scene = text.parent;
        calcFittedTextSpec(mergedPatternArr, scene.size).then((textSpec) => {
          const letterInterval = textSpec.letter.interval.default;
          const spaceInterval = textSpec.letter.interval.space;
          const matrix = getMatrix(mergedPatternArr, letterInterval, spaceInterval, scene.size.width * threeOpt.text.scalePerScene);
          arrayLettersByMatrix(mergedLetterObjArr, matrix, textSpec);
          resolve();
        });
      });
    });
  });
};

export const updateTextStyle = (text) => {
  const backUpKeyArr = threeOpt.text.subGroupNameArr;
  const backUpData = {};
  backUpKeyArr.forEach((backUpKey, i) => {
    if (i !== 1) {
      // exclude gap
      const group = text.getObjectByName(backUpKey);
      backUpData[backUpKey] = {
        letterArr: JSON.parse(JSON.stringify(group.letterArr)),
        patternArr: JSON.parse(JSON.stringify(group.patternArr)),
        str: group.str === '' ? '' : (' ' + group.str).slice(1),
      };

      for (let i = group.children.length - 1; i >= 0; i--) {
        group.children[i].removeFromParent();
      }
      group.letterArr = [];
      group.patternArr = [];
      group.str = '';
    }
  });

  if (backUpData[threeOpt.text.subGroupNameArr[0]].str === '' || backUpData[threeOpt.text.subGroupNameArr[0]].str === undefined) {
    if (backUpData[threeOpt.text.subGroupNameArr[2]].str === '' || backUpData[threeOpt.text.subGroupNameArr[2]].str === undefined) {
      const text0 = text[threeOpt.text.subGroupNameArr[0]];
      backUpData[threeOpt.text.subGroupNameArr[0]].str = (' ' + text0.placeholder).slice(1);
    }
  }

  const textFutureGroup = text.getObjectByName('textFuture');
  const textPowerGroup = text.getObjectByName('textPower');

  updateText(text, textFutureGroup, backUpData[threeOpt.text.subGroupNameArr[0]].str).then(() => {
    updateText(text, textPowerGroup, backUpData[threeOpt.text.subGroupNameArr[2]].str).then(() => {});
  });
};

const getMatrix = (textPattern, letterInterval, spaceInterval, maxWidth) => {
  let rowSum = 0;
  let rowNum = 0;

  const textMatrix = [{ colPattern: [], sizeArr: [], sizeSum: 0 }];
  textPattern.forEach((p) => {
    const size = p === true ? letterInterval : spaceInterval;
    if (rowSum + size <= maxWidth) {
      rowSum += size;
    } else {
      rowNum++;
      rowSum = 0;
      textMatrix.push({ colPattern: [], sizeArr: [], sizeSum: 0 });
    }

    textMatrix[rowNum].colPattern.push(p);
    textMatrix[rowNum].sizeArr.push(size);
    textMatrix[rowNum].sizeSum += size;
  });

  return textMatrix;
};

const splitText = (textStr) => {
  return textStr.split('');
};

const getPatternArr = (letterArr) => {
  let patternArr = [];
  letterArr.forEach((letter) => {
    if (letter === ' ') {
      patternArr.push(false);
    } else {
      patternArr.push(true);
    }
  });

  return patternArr;
};

const calcFittedTextSpec = (patternArr, size) => {
  return new Promise((resolve) => {
    let letterNum = 0;
    patternArr.forEach((pattern) => {
      if (pattern) {
        letterNum += threeOpt.text.letter.interval.default;
      } else {
        letterNum += threeOpt.text.letter.interval.space;
      }
    });

    const xNum = Math.ceil(Math.sqrt(letterNum * size.aspect));
    const yNum = Math.ceil(letterNum / xNum);

    let bBoxWidth = (size.width * threeOpt.text.scalePerScene) / xNum;
    let bBoxHeight = (size.height * threeOpt.text.scalePerScene) / yNum;
    let letterSize;
    if (bBoxWidth > bBoxHeight) {
      letterSize = bBoxHeight / threeOpt.text.line.height;
      bBoxWidth = letterSize * (threeOpt.text.letter.interval.default + threeOpt.text.letter.interval.space);
    } else {
      letterSize = bBoxWidth / (threeOpt.text.letter.interval.default + threeOpt.text.letter.interval.space);
      bBoxHeight = letterSize * threeOpt.text.line.height;
    }

    resolve({
      matrix: {
        x: xNum,
        y: yNum,
      },
      letter: {
        bBox: {
          width: bBoxWidth,
          height: bBoxHeight,
        },
        size: letterSize,
        interval: {
          default: letterSize * threeOpt.text.letter.interval.default,
          space: letterSize * threeOpt.text.letter.interval.space,
        },
      },
    });
  });
};

const brushScene = (scene, color) => {
  return new Promise((resolve) => {
    const build = scene.getObjectByName('build');
    const yStep = scene.size.height / threeOpt.scene.background.brush.strokeNum;
    const yOffset = scene.size.height / 2;
    const slope = Math.sqrt(Math.pow(scene.size.width, 2) + Math.pow(yStep, 2));
    const capHeight = (2 * Math.pow(yStep, 2)) / slope;
    const strokeHeight = (2 * yStep * scene.size.width) / slope;
    const strokeWidth = slope + capHeight + strokeHeight;
    const strokeRadius = Math.min(strokeWidth, strokeHeight) / 2;
    const strokeVec = new THREE.Vector3(scene.size.width, yStep * -1, 0).normalize();
    const strokeAngle = Math.atan2(strokeVec.y, strokeVec.x);

    const strokeDataArr = [];
    for (let i = 0; i <= threeOpt.scene.background.brush.strokeNum - 1; i++) {
      const xDir = 1 - (i % 2) * 2;
      const endPos = new THREE.Vector3(0, yOffset - yStep * (i + 0.5), 0);
      const startPos = endPos.clone().sub(new THREE.Vector3(strokeVec.x * xDir, strokeVec.y, strokeVec.z).multiplyScalar(strokeWidth));
      const rotateY = strokeAngle * xDir;
      strokeDataArr.push({
        startPos: startPos,
        endPos: endPos,
        rotateY: rotateY,
      });
    }

    const strokeShape = createRoundedRectShape(0, 0, strokeWidth, strokeHeight, strokeRadius, true);
    const strokeGeo = new THREE.ShapeGeometry(strokeShape);
    const strokeMat = new THREE.MeshBasicMaterial({
      color: color,
    });
    const defaultStroke = new THREE.Mesh(strokeGeo, strokeMat);

    const brush = new THREE.Group();
    brush.name = 'brush';
    build.add(brush);

    let strokeId = 0;
    let strokeRunTime = threeOpt.animation.brushScene.time / threeOpt.scene.background.brush.strokeNum;
    function runSingleStroke(strokeId) {
      const stroke = defaultStroke.clone();
      const strokeData = strokeDataArr[strokeId];
      stroke.position.copy(strokeData.startPos);

      stroke.rotation.z = strokeData.rotateY;
      brush.add(stroke);
      gsap.to(stroke.position, {
        duration: strokeRunTime,
        x: strokeData.endPos.x,
        y: strokeData.endPos.y,
        z: strokeData.endPos.z,
        onComplete: () => {
          if (strokeId < strokeDataArr.length - 1) {
            strokeId++;
            runSingleStroke(strokeId);
          } else {
            resolve(brush);
          }
        },
      });
    }

    runSingleStroke(strokeId);
  });
};

export const fillScene = (scene, color, runToggleCtrl, animation) => {
  return new Promise((resolve) => {
    if (!scene) return;
    if (runToggleCtrl) toggleCtrl(scene, false, 'fillScene');

    const backUpColor = testSceneHasFilled(scene, color);

    testSceneHasFilled(scene, color);

    if (animation) {
      brushScene(scene, color).then((brush) => {
        scene.background = color;
        if (backUpColor) scene.colorBackUp = color.clone();
        brush.removeFromParent();

        if (runToggleCtrl) toggleCtrl(scene, true, 'fillScene');
        resolve();
      });
    } else {
      scene.background = color;
      if (backUpColor) scene.colorBackUp = color.clone();

      if (runToggleCtrl) toggleCtrl(scene, true, 'fillScene');
      resolve();
    }
  });
};

const changeSceneColor = (scene, color, runToggleCtrl, animation, runtime) => {
  if (!scene) return;
  if (runToggleCtrl) toggleCtrl(scene, false, 'changeSceneColor');

  if (animation) {
    gsap.to(scene.background, {
      duration: runtime,
      r: color.r,
      g: color.g,
      b: color.b,
      onComplete: () => {
        if (runToggleCtrl) toggleCtrl(scene, true, 'changeSceneColor');
      },
    });
  } else {
    scene.background = color;
    if (runToggleCtrl) toggleCtrl(scene, true, 'changeSceneColor');
  }
};

// [STEP 2-1]
export const createHole = (scene, runToggleCtrl) => {
  return new Promise((resolve) => {
    if (!scene) return;
    const build = scene.getObjectByName('build');
    if (runToggleCtrl) toggleCtrl(scene, false, 'createHole');

    const oldHole = build.getObjectByName('hole');
    if (oldHole) oldHole.removeFromParent();

    const totalNodeNum = (threeOpt.hole.sideNodeNum + 2) * 4;
    const diameter = 1;

    const ptArr = [];
    for (let i = 0; i < totalNodeNum; i++) {
      const pt = new THREE.Vector3();
      pt.wiggleParam = Math.max(Math.random() * Math.PI * 2, 0.000001);
      ptArr.push(pt);
    }

    makePtArrRadial(ptArr, diameter);

    const holeGeo = createGeoFromPtArr(ptArr);
    const holeMat = new THREE.MeshBasicMaterial({ color: threeOpt.scene.background.color.blank, transparent: true });
    const hole = new THREE.Mesh(holeGeo, holeMat);
    hole.name = 'hole';
    hole.renderOrder = 10;
    hole.visible = false;
    hole.ptArr = ptArr;

    if (runToggleCtrl) toggleCtrl(scene, true, 'createHole');
    resolve(hole);
  });
};

// [STEP 2-2]
export const expandHole = (hole, runToggleCtrl, animation) => {
  return new Promise((resolve) => {
    if (!hole) return;
    const build = hole.parent;
    const scene = build.parent;
    if (runToggleCtrl) toggleCtrl(scene, false, 'expandHole');
    const totalNodeNum = hole.ptArr.length;
    const size = threeOpt.hole.size;

    const ptArr = [];
    for (let i = 0; i < totalNodeNum; i++) {
      ptArr.push(new THREE.Vector3());
    }
    makePtArrSquared(ptArr, size, threeOpt.hole.filletRadius);
    const maxDistortSize = threeOpt.hole.size * threeOpt.hole.distortSize;
    makePtArrDistortedNum(ptArr, maxDistortSize);

    hole.visible = true;

    if (animation) {
      expandObj(hole, ptArr, true, threeOpt.animation.expandHole.time).then(() => {
        if (runToggleCtrl) toggleCtrl(scene, true, 'expandHole');
        resolve();
      });
    } else {
      expandObj(hole, ptArr);
      if (runToggleCtrl) toggleCtrl(scene, true, 'expandHole');
      resolve();
    }
  });
};

// [STEP 2-3]
export const changeHoleScale = (hole, scale, runToggleCtrl, animation) => {
  return new Promise((resolve) => {
    if (!hole) return;
    const build = hole.parent;
    const scene = build.parent;
    if (!scene) return;
    if (runToggleCtrl) toggleCtrl(scene, false, 'changeHoleScale');

    if (animation) {
      changeObjScale(hole, scale, true, threeOpt.animation.changeHoleScale.time).then(() => {
        if (runToggleCtrl) toggleCtrl(scene, true, 'changeHoleScale');
        resolve();
      });
    } else {
      changeObjScale(hole, scale);
      if (runToggleCtrl) toggleCtrl(scene, true, 'changeHoleScale');
      resolve();
    }
  });
};

// [STEP 3-1]
export const createCell = (scene, colorId, runToggleCtrl) => {
  return new Promise((resolve) => {
    if (!scene) return;
    const build = scene.getObjectByName('build');
    if (!build) return;
    const hole = build.getObjectByName('hole');
    if (!hole) return;
    if (runToggleCtrl) toggleCtrl(scene, false, 'createCell');

    const oldCell = build.getObjectByName('cell');
    if (oldCell) oldCell.removeFromParent();

    const colorData = getCellColorById(scene, colorId);
    const totalNodeNum = (threeOpt.cell.sideNodeNum + 2) * 4;
    const diameter = 1;

    const ptArr = [];
    for (let i = 0; i < totalNodeNum; i++) {
      const pt = new THREE.Vector3();
      pt.wiggleParam = Math.max(Math.random() * Math.PI * 2, 0.000001);
      ptArr.push(pt);
    }
    makePtArrRadial(ptArr, diameter);

    const cellGeo = createGeoFromPtArr(ptArr);
    const cellMat = new THREE.MeshBasicMaterial({ color: colorData.cell.color, transparent: true, opacity: colorData.cell.opacity });
    const cell = new THREE.Mesh(cellGeo, cellMat);
    cell.name = 'cell';
    cell.renderOrder = 20;
    cell.visible = false;
    cell.ptArr = ptArr;
    cell.scale.copy(hole.scale);
    cell.colorId = colorId;

    if (runToggleCtrl) toggleCtrl(scene, true, 'createCell');
    resolve(cell);
  });
};

export const getCellColorById = (scene, colorId) => {
  const backgroundColor = scene.background.clone();
  const backgroundRGB = [backgroundColor.r * 255, backgroundColor.g * 255, backgroundColor.b * 255];
  const backgroundHSL = rgbToHsl(backgroundRGB[0], backgroundRGB[1], backgroundRGB[2]);

  let levelDelta, opacity;
  switch (colorId) {
    case 0: {
      // make cell blank
      levelDelta = 0;
      opacity = 0;
      break;
    }
    case 1: {
      // fill cell with background-brighter color
      levelDelta = threeOpt.cell.colorLevelDelta.brighter;
      opacity = threeOpt.cell.opacity.brighter;
      break;
    }
    case 2: {
      // fill cell with background-darker color
      levelDelta = threeOpt.cell.colorLevelDelta.darker;
      opacity = threeOpt.cell.opacity.darker;
      break;
    }
    case 3: {
      // fill cell with background-identical color
      levelDelta = 0;
      opacity = 1;
      break;
    }
    case 4:
    default: {
      // fill cell with background-identical color and make background blank
      levelDelta = 0;
      opacity = 1;
      break;
    }
  }
  const cellLevel =
    colorId === 1 || colorId === 2
      ? Math.min(Math.max(backgroundHSL[2] + levelDelta, threeOpt.cell.colorLevelBound.min), threeOpt.cell.colorLevelBound.max)
      : backgroundHSL[2] + levelDelta;
  const cellHSL = [backgroundHSL[0], backgroundHSL[1], cellLevel];
  const cellRGB = colorId === 4 ? backgroundRGB : hslToRgb(cellHSL);
  const cellColor = new THREE.Color(cellRGB[0] / 255, cellRGB[1] / 255, cellRGB[2] / 255);

  if (colorId === 4) {
    return {
      cell: {
        color: backgroundColor,
        opacity: opacity,
      },
      background: {
        color: cellColor,
        opacity: 1,
      },
    };
  } else {
    return {
      cell: {
        color: cellColor,
        opacity: opacity,
      },
      background: {
        color: backgroundColor,
        opacity: 1,
      },
    };
  }
};

// [STEP 3-2]
export const expandCell = (cell, runToggleCtrl, animation) => {
  return new Promise((resolve) => {
    if (!cell) return;
    const build = cell.parent;
    const scene = build.parent;
    const hole = build.getObjectByName('hole');
    if (!hole) return;
    if (runToggleCtrl) toggleCtrl(scene, false, 'expandCell');

    const totalNodeNum = cell.ptArr.length;
    const size = threeOpt.hole.size * threeOpt.cell.scale.default;

    const ptArr = [];
    switch (cell.colorId) {
      case 0:
      case 3:
      case 4: {
        hole.ptArr.forEach((pt) => {
          const clonedPt = deepClonePt(pt);
          ptArr.push(clonedPt);
        });
        break;
      }
      case 1:
      case 2:
      default: {
        for (let i = 0; i < totalNodeNum; i++) {
          const pt = new THREE.Vector3();
          pt.wiggleParam = cell.ptArr[i].wiggleParam;
          ptArr.push(pt);
        }
        makePtArrSquared(ptArr, size, threeOpt.cell.filletRadius);
        const maxDistortSize = size * threeOpt.cell.distortSize;
        makePtArrDistortedNum(ptArr, maxDistortSize);
        break;
      }
    }

    switch (cell.colorId) {
      case 0:
      case 3:
      case 4: {
        morphObjByPtArr(hole, hole.ptArr, ptArr, animation, threeOpt.animation.expandCell.time);
        break;
      }
      default: {
        break;
      }
    }

    cell.visible = true;
    if (animation) {
      expandObj(cell, ptArr, true, threeOpt.animation.expandCell.time).then(() => {
        if (runToggleCtrl) toggleCtrl(scene, true, 'expandCell');
        resolve();
      });
    } else {
      expandObj(cell, ptArr);
      if (runToggleCtrl) toggleCtrl(scene, true, 'expandCell');
      resolve();
    }
  });
};

// [STEP 3-3]
export const changeCellColor = (cell, colorId, runToggleCtrl, animation) => {
  return new Promise((resolve) => {
    if (cell.colorId === colorId) return;
    const build = cell.parent;
    const scene = build.parent;
    const hole = build.getObjectByName('hole');
    if (runToggleCtrl) toggleCtrl(scene, false, 'changeCellColor');

    let colorData;
    if (cell.colorId === 4 && colorId !== 4) {
      const backgroundColor = scene.colorBackUp.clone();
      colorData = getCellColorById({ background: backgroundColor }, colorId);
      changeSceneColor(scene, backgroundColor, false, animation, threeOpt.animation.changeCellOpacity.time);
    } else if (cell.colorId !== 4 && colorId === 4) {
      const backgroundColor = new THREE.Color(threeOpt.scene.background.color.blank);
      colorData = getCellColorById(scene, colorId);
      changeSceneColor(scene, backgroundColor, false, animation, threeOpt.animation.changeCellOpacity.time);
    } else {
      if (colorId !== 3) {
        colorData = getCellColorById(scene, colorId);
      } else {
        colorData = {
          cell: {
            color: scene.background.clone(),
            opacity: 1,
          },
          background: {
            color: scene.background.clone(),
            opacity: 1,
          },
        };
      }
    }

    if (animation) {
      // fade out old cell
      const oldCell = deepCloneObj(cell);
      oldCell.name = 'cell-old';
      build.add(oldCell);
      changeObjOpacity(oldCell, 0, true, threeOpt.animation.changeCellOpacity.time).then(() => {
        oldCell.removeFromParent();
      });

      const totalNodeNum = cell.ptArr.length;
      const diameter = 1;

      const ptArr = [];
      for (let i = 0; i < totalNodeNum; i++) {
        const pt = new THREE.Vector3();
        pt.wiggleParam = cell.ptArr[i].wiggleParam;
        ptArr.push(pt);
      }
      makePtArrRadial(ptArr, diameter);
      cell.geometry.dispose();
      const cellGeo = createGeoFromPtArr(ptArr);
      cell.geometry = cellGeo;
      cell.ptArr = ptArr;
      cell.colorId = colorId;
      changeObjColor(cell, colorData.cell.color);
      changeObjOpacity(cell, colorData.cell.opacity);

      if (colorId !== 3) {
        changeObjColor(hole, scene.colorBlank.clone(), true, threeOpt.animation.changeCellOpacity.time);
      } else {
        changeObjColor(hole, scene.background.clone(), true, threeOpt.animation.changeCellOpacity.time);
      }

      expandCell(cell, true, true).then(() => {
        if (runToggleCtrl) toggleCtrl(scene, true, 'changeCellColor');
        resolve();
      });
    } else {
      cell.colorId = colorId;
      changeObjColor(cell, colorData.cell.color);
      changeObjOpacity(cell, colorData.cell.opacity);

      if (colorId !== 3) {
        changeObjColor(hole, scene.colorBlank.clone());
      } else {
        changeObjColor(hole, scene.background.clone());
      }

      if (runToggleCtrl) toggleCtrl(scene, true, 'changeCellColor');
      resolve();
    }
  });
};

// [STEP 4-1]
export const createDot = (scene, color, runToggleCtrl) => {
  return new Promise((resolve) => {
    if (!scene) return;
    const build = scene.getObjectByName('build');
    if (!build) return;
    const hole = build.getObjectByName('hole');
    if (!hole) return;
    if (runToggleCtrl) toggleCtrl(scene, false, 'createDot');
    const oldDot = build.getObjectByName('dot');
    if (oldDot) oldDot.removeFromParent();

    const totalNodeNum = (threeOpt.dot.sideNodeNum + 2) * 4;
    const diameter = 1;

    const ptArr = [];
    for (let i = 0; i < totalNodeNum; i++) {
      const pt = new THREE.Vector3();
      pt.wiggleParam = Math.max(Math.random() * Math.PI * 2, 0.000001);
      ptArr.push(pt);
    }
    makePtArrRadial(ptArr, diameter);

    const dotGeo = createGeoFromPtArr(ptArr);
    const dotMat = new THREE.MeshBasicMaterial({ color: color, transparent: true });
    const dot = new THREE.Mesh(dotGeo, dotMat);
    dot.name = 'dot';
    dot.renderOrder = 30;
    dot.visible = false;
    dot.ptArr = ptArr;
    const holeConsideredScale = hole.scale.clone().multiplyScalar(threeOpt.dot.scale.default);
    dot.scale.copy(holeConsideredScale);

    if (runToggleCtrl) toggleCtrl(scene, true, 'createDot');
    resolve(dot);
  });
};

// [STEP 4-2]
export const expandDot = (dot, runToggleCtrl, animation) => {
  return new Promise((resolve) => {
    if (!dot) return;
    const build = dot.parent;
    const scene = build.parent;
    const hole = build.getObjectByName('hole');
    if (!hole) return;
    if (runToggleCtrl) toggleCtrl(scene, false, 'expandDot');

    const totalNodeNum = dot.ptArr.length;
    const diameter = threeOpt.hole.size;

    const ptArr = [];
    for (let i = 0; i < totalNodeNum; i++) {
      ptArr.push(new THREE.Vector3());
    }
    makePtArrRadial(ptArr, diameter);
    const maxDistortSize = diameter * dot.distortSizeOpt.default;
    makePtArrDistortedNum(ptArr, maxDistortSize);

    dot.visible = true;

    if (animation) {
      expandObj(dot, ptArr, true, threeOpt.animation.expandDot.time).then(() => {
        if (runToggleCtrl) toggleCtrl(scene, true, 'expandDot');
        resolve();
      });
    } else {
      expandObj(dot, ptArr);
      if (runToggleCtrl) toggleCtrl(scene, true, 'expandDot');
      resolve();
    }
  });
};

// [STEP 4-3]
export const changeDotColor = (dot, color, runToggleCtrl, animation) => {
  if (!dot) return;
  const build = dot.parent;
  const scene = build.parent;
  if (!scene) return;
  if (runToggleCtrl) toggleCtrl(scene, false, 'changeDotColor');

  if (animation) {
    // fade out old dot
    const oldDot = deepCloneObj(dot);
    oldDot.name = 'dot-old';
    build.add(oldDot);
    changeObjOpacity(oldDot, 0, true, threeOpt.animation.changeDotOpacity.time).then(() => {
      oldDot.removeFromParent();
    });

    // expand new cell
    dot.visible = false;
    const nextOpt = { param: 0 };
    gsap.to(nextOpt, {
      duration: 0.01,
      ease: Power1.easeOut,
      param: 1,
      onComplete: () => {
        const totalNodeNum = dot.ptArr.length;
        const diameter = 1;

        const ptArr = [];
        for (let i = 0; i < totalNodeNum; i++) {
          const pt = new THREE.Vector3();
          pt.wiggleParam = dot.ptArr[i].wiggleParam;
          ptArr.push(pt);
        }
        makePtArrRadial(ptArr, diameter);
        dot.geometry.dispose();
        const dotGeo = createGeoFromPtArr(ptArr);
        dot.geometry = dotGeo;
        dot.ptArr = ptArr;
        changeObjColor(dot, color);

        expandDot(dot, false, true).then(() => {
          if (runToggleCtrl) toggleCtrl(scene, true, 'changeDotColor');
        });
      },
    });
  } else {
    changeObjColor(dot, color);
    if (runToggleCtrl) toggleCtrl(scene, true, 'changeDotColor');
  }
};

// [STEP 5]
export const distortDot = (dot, distortSize, runToggleCtrl, animation) => {
  return new Promise((resolve) => {
    if (!dot) return;
    const build = dot.parent;
    const scene = build.parent;
    if (!scene) return;
    if (runToggleCtrl) toggleCtrl(scene, false, 'distortDot');

    const fromPtArr = [],
      toPtArr = [];
    for (let i = 0; i < dot.ptArr.length; i++) {
      fromPtArr.push(deepClonePt(dot.ptArr[i]));
      toPtArr.push(new THREE.Vector3());
    }

    dot.distortSize = distortSize;
    const diameter = threeOpt.hole.size;

    makePtArrRadial(toPtArr, diameter);
    const maxDistortSize = threeOpt.hole.size * distortSize;
    makePtArrDistortedNum(toPtArr, maxDistortSize);

    if (animation) {
      const easeOpt = Power1.easeInOut;
      morphObjByPtArr(dot, fromPtArr, toPtArr, true, threeOpt.animation.distortDot.time, easeOpt).then(() => {
        if (runToggleCtrl) toggleCtrl(scene, true, 'distortDot');
        resolve();
      });
    } else {
      morphObjByPtArr(dot, fromPtArr, toPtArr);
      if (runToggleCtrl) toggleCtrl(scene, true, 'distortDot');
      resolve();
    }
  });
};

// [STEP 6]
export const changeDotScale = (dot, scale, runToggleCtrl, animation) => {
  return new Promise((resolve) => {
    if (!dot) return;
    const build = dot.parent;
    const scene = build.parent;
    if (!scene) return;
    const hole = build.getObjectByName('hole');
    if (!hole) return;
    if (runToggleCtrl) toggleCtrl(scene, false, 'changeDotScale');

    const holeConsideredScale = hole.scale.clone().x * scale;

    if (animation) {
      changeObjScale(dot, holeConsideredScale, true, threeOpt.animation.changeDotScale.time).then(() => {
        if (runToggleCtrl) toggleCtrl(scene, true, 'changeDotScale');
        resolve();
      });
    } else {
      changeObjScale(dot, holeConsideredScale);
      if (runToggleCtrl) toggleCtrl(scene, true, 'changeDotScale');
      resolve();
    }
  });
};

const expandObj = (obj, toPtArr, animation, runtime) => {
  return new Promise((resolve) => {
    const fromBBoxData = getBBoxDataFromPtArr(obj.ptArr);
    const fromSize = Math.max(fromBBoxData.size.x, fromBBoxData.size.y);
    const fromPtArr = [];
    obj.ptArr.forEach((pt) => {
      fromPtArr.push(pt.clone());
    });

    if (animation) {
      const toBBoxData = getBBoxDataFromPtArr(toPtArr);
      const toSize = Math.max(toBBoxData.size.x, toBBoxData.size.y);

      const midPtArr = [];
      for (let i = 0; i < obj.ptArr.length; i++) {
        midPtArr.push(new THREE.Vector3());
      }
      const midSize = (fromSize + toSize) * 0.5;
      makePtArrRadial(midPtArr, midSize);
      const maxDistortSize = midSize * 0.1;
      makePtArrDistortedNum(midPtArr, maxDistortSize);

      const firstRunTime = runtime * 0.3;
      const secondRunTime = runtime - firstRunTime;

      const firstEaseOpt = Power1.easeIn;
      morphObjByPtArr(obj, fromPtArr, midPtArr, animation, firstRunTime, firstEaseOpt, 'first').then(() => {
        const secondEaseOpt = Power1.easeOut;
        morphObjByPtArr(obj, midPtArr, toPtArr, animation, secondRunTime, secondEaseOpt, 'secnd').then(() => {
          resolve();
        });
      });
    } else {
      morphObjByPtArr(obj, fromPtArr, toPtArr);
      resolve();
    }
  });
};

export const changeObjOpacity = (obj, opacity, animation, runtime) => {
  return new Promise((resolve) => {
    if (animation) {
      gsap.to(obj.material, {
        duration: runtime,
        opacity: opacity,
        onComplete: () => {
          resolve();
        },
      });
    } else {
      obj.material.opacity = opacity;
      resolve();
    }
  });
};

const changeObjColor = (obj, color, animation, runtime) => {
  return new Promise((resolve) => {
    if (animation) {
      gsap.to(obj.material.color, {
        duration: runtime,
        r: color.r,
        g: color.g,
        b: color.b,
        onComplete: () => {
          resolve();
        },
      });
    } else {
      obj.material.color = color;
      resolve();
    }
  });
};

const morphObjByPtArr = (obj, fromPtArr, toPtArr, animation, runtime, easeOpt) => {
  return new Promise((resolve) => {
    if (fromPtArr.length !== toPtArr.length) return;
    if (animation) {
      const ptNum = fromPtArr.length;
      const domArr = [];
      for (let i = 0; i < ptNum; i++) {
        const dom = toPtArr[i].clone().sub(fromPtArr[i]);
        domArr.push(dom);
      }

      const morphOpt = { param: 0 };
      gsap.to(morphOpt, {
        duration: runtime,
        ease: easeOpt,
        param: 1,
        onUpdate: () => {
          const morphPtArr = [];
          for (let i = 0; i < ptNum; i++) {
            const morphVec = domArr[i].clone().multiplyScalar(morphOpt.param);
            const morphPt = fromPtArr[i].clone().add(morphVec);
            morphPtArr.push(morphPt);
          }
          obj.geometry.dispose();
          obj.geometry = createGeoFromPtArr(morphPtArr);
        },
        onComplete: () => {
          obj.ptArr.forEach((pt, i) => {
            pt.x = toPtArr[i].x;
            pt.y = toPtArr[i].y;
            pt.z = toPtArr[i].z;
            pt.vec = toPtArr[i].vec;
          });
          resolve();
        },
      });
    } else {
      obj.geometry.dispose();
      obj.geometry = createGeoFromPtArr(toPtArr);
      obj.ptArr.forEach((pt, i) => {
        pt.x = toPtArr[i].x;
        pt.y = toPtArr[i].y;
        pt.z = toPtArr[i].z;
        pt.vec = toPtArr[i].vec;
      });
      resolve();
    }
  });
};

const createGeoFromPtArr = (ptArr, closed = true) => {
  const crv = new THREE.CatmullRomCurve3(ptArr);
  crv.closed = closed;
  crv.curveType = 'chordal';

  const vertices = crv.getPoints(200);
  const shape = new THREE.Shape(vertices);
  const geo = new THREE.ShapeGeometry(shape);
  return geo;
};
const makePtArrRadial = (ptArr, diameter, center = new THREE.Vector3()) => {
  const radius = diameter / 2;
  const angleStep = (Math.PI * 2) / ptArr.length;

  for (let i = 0; i < ptArr.length; i++) {
    const angle = (Math.PI / 4) * 3 - angleStep * (i + 0.5);
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    const z = 0;
    const pos = new THREE.Vector3(x, y, z).add(center);

    ptArr[i].x = pos.x;
    ptArr[i].y = pos.y;
    ptArr[i].z = pos.z;
    ptArr[i].vec = pos.clone().sub(center).normalize();
  }
};

const makePtArrSquared = (ptArr, squareSize, filletRadius, center = new THREE.Vector3()) => {
  filletRadius = Math.min(Math.max(filletRadius, 0), squareSize / 2);

  const partNodeNum = ptArr.length / 4;
  const partLng = squareSize - filletRadius * squareSize * 2;
  const partPtStep = partLng / (partNodeNum - 1);
  const axis = new THREE.Vector3(0, 0, 1).add(center);

  for (let i = 0; i < ptArr.length; i++) {
    const partId = Math.floor(i / partNodeNum);
    const partAngle = -Math.PI * (partId / 2 + 1);
    const ptId = i % partNodeNum;

    const x = partLng / 2 - ptId * partPtStep;
    const y = (squareSize / 2) * -1;
    const z = 0;
    const pos = new THREE.Vector3(x, y, z).add(center).applyAxisAngle(axis, partAngle);

    ptArr[i].x = pos.x;
    ptArr[i].y = pos.y;
    ptArr[i].z = pos.z;
    ptArr[i].vec = pos.clone().sub(center).normalize();
  }
};

const makePtArrDistortedNum = (ptArr, maxDistortSize) => {
  for (let i = 0; i < ptArr.length; i++) {
    const distortParam = Math.random() * 2 - 1;
    const distortSize = maxDistortSize * distortParam;
    const distortVec = ptArr[i].vec.clone().multiplyScalar(distortSize);

    ptArr[i].x += distortVec.x;
    ptArr[i].y += distortVec.y;
    ptArr[i].z += distortVec.z;
  }
};

const deepClonePt = (pt) => {
  const clonedPt = pt.clone();
  Object.keys(pt).forEach((key) => {
    const data = pt[key];
    if (typeof data === 'number') {
      clonedPt[key] = data;
    } else if (data.x) {
      clonedPt[key] = data.clone();
    }
  });

  return clonedPt;
};

export const deepCloneObj = (obj) => {
  const clonedObj = obj.clone();
  if (Array.isArray(obj.material)) {
    clonedObj.material.forEach((mat) => {
      mat = mat.clone();
    });
  } else {
    clonedObj.material = clonedObj.material?.clone();
  }

  if (obj.ptArr) {
    clonedObj.ptArr = [];
    obj.ptArr.forEach((pt) => {
      clonedObj.ptArr.push(deepClonePt(pt));
    });
  }

  return clonedObj;
};

export const getZoomPerCvsAspect = (cvsAspect) => {
  const zoomKeyArr = Object.keys(threeOpt.camera.zoomPerAspect);
  zoomKeyArr.forEach((zoomKey, i) => {
    zoomKeyArr[i] = parseFloat(zoomKey);
  });
  zoomKeyArr.sort((a, b) => {
    return b - a;
  });

  let zoom;
  for (let i = 0; i < zoomKeyArr.length; i++) {
    const testCvsAspect = zoomKeyArr[i];
    if (cvsAspect > testCvsAspect) {
      zoom = threeOpt.camera.zoomPerAspect[testCvsAspect.toString()];
      break;
    }

    if (i === zoomKeyArr.length - 1 && cvsAspect <= testCvsAspect) {
      zoom = threeOpt.camera.zoomPerAspect[testCvsAspect.toString()];
    }
  }

  return zoom;
};

const createRoundedRectShape = (x, y, width, height, radius, centerPivot = false) => {
  radius = Math.min(radius, width / 2, height / 2);
  let offsetX, offsetY;
  if (centerPivot) {
    offsetX = -width / 2;
    offsetY = -height / 2;
  }

  const roundedRectShape = new THREE.Shape();
  roundedRectShape.moveTo(x + offsetX, y + offsetY + radius);
  roundedRectShape.lineTo(x + offsetX, y + offsetY + height - radius);
  roundedRectShape.quadraticCurveTo(x + offsetX, y + offsetY + height, x + offsetX + radius, y + offsetY + height);
  roundedRectShape.lineTo(x + offsetX + width - radius, y + offsetY + height);
  roundedRectShape.quadraticCurveTo(x + offsetX + width, y + offsetY + height, x + offsetX + width, y + offsetY + height - radius);
  roundedRectShape.lineTo(x + offsetX + width, y + offsetY + radius);
  roundedRectShape.quadraticCurveTo(x + offsetX + width, y + offsetY, x + offsetX + width - radius, y + offsetY);
  roundedRectShape.lineTo(x + offsetX + radius, y + offsetY);
  roundedRectShape.quadraticCurveTo(x + offsetX, y + offsetY, x + offsetX, y + offsetY + radius);

  return roundedRectShape;
};

const changeObjScale = (obj, scale, animation, runtime) => {
  return new Promise((resolve) => {
    if (animation) {
      gsap.to(obj.scale, {
        duration: runtime,
        ease: Back.easeOut.config(1.4),
        x: scale,
        y: scale,
        z: scale,
        onComplete: () => {
          resolve();
        },
      });
    } else {
      obj.scale.set(scale, scale, scale);
      resolve();
    }
  });
};

const getBBoxDataFromPtArr = (ptArr) => {
  const ptNum = ptArr.length;
  let xArr = [],
    yArr = [],
    zArr = [];
  let xSum = 0,
    ySum = 0,
    zSum = 0;
  ptArr.forEach((pt) => {
    xArr.push(pt.x);
    yArr.push(pt.y);
    zArr.push(pt.z);

    xSum += pt.x;
    ySum += pt.y;
    zSum += pt.z;
  });

  const center = new THREE.Vector3(xSum, ySum, zSum).divideScalar(ptNum);
  const size = new THREE.Vector3(Math.max(...xArr) - Math.min(...xArr), Math.max(...yArr) - Math.min(...yArr), Math.max(...zArr) - Math.min(...zArr));

  return {
    center: center,
    size: size,
  };
};

export const toggleText = (scene, boolean) => {
  if (!scene) return;
  const build = scene.getObjectByName('build');
  const text = scene.getObjectByName('text');

  switch (boolean) {
    case true:
    case 'show': {
      build.visible = false;
      text.visible = true;
      scene.wiggleable = false;

      const sceneColor = scene.background.clone();

      const hole = build.getObjectByName('hole');
      const holeColor = hole.material.color.clone();
      const holeScale = hole.scale.x;

      const cell = build.getObjectByName('cell');
      const cellColor = cell.material.color;
      const cellOpacity = cell.material.opacity;

      const dot = build.getObjectByName('dot');
      const dotColor = dot.material.color.clone();
      const dotDistortSize = dot.distortSize;
      const dotScale = dot.scale.x / holeScale;

      text.buildSpec = {
        scene: {
          color: sceneColor,
        },
        hole: {
          color: holeColor,
          scale: holeScale,
        },
        cell: {
          color: cellColor,
          opacity: cellOpacity,
        },
        dot: {
          color: dotColor,
          distortSize: dotDistortSize,
          scale: dotScale,
        },
      };
      updateTextStyle(text);

      break;
    }
    case false:
    case 'hide':
    default: {
      build.visible = true;
      text.visible = false;
      scene.wiggleable = true;

      if (text.buildSpec) delete text.buildSpec;
      break;
    }
  }
};

export const toggleCtrl = (scene, boolean, callFuncName) => {
  if (!scene || !scene.elDivCtrl) return;

  switch (boolean) {
    case true:
    case 'enable': {
      scene.elDivCtrl.classList.add('pointer-events-auto');
      scene.elDivCtrl.classList.remove('pointer-events-none');
      scene.wiggleable = true;
      break;
    }
    case false:
    case 'disable':
    default: {
      scene.elDivCtrl.classList.add('pointer-events-none');
      scene.elDivCtrl.classList.remove('pointer-events-auto');
      scene.wiggleable = false;
      break;
    }
  }
};

export const toggleInputArr = (inputArr, step) => {
  inputArr.forEach((input) => {
    const inputStep = parseFloat(input.getAttribute('data-step'));

    if (inputStep === step) {
      input.classList.add('block');
      input.classList.remove('hidden');
    } else {
      input.classList.add('hidden');
      input.classList.remove('block');
    }
  });
};

export const testSceneHasFilled = (scene, color) => {
  const sceneColor = color ? color : scene.background;
  const blankColor = scene.colorBlank;
  const colorDiff = new THREE.Vector3(blankColor.r, blankColor.g, blankColor.b).distanceTo(
    new THREE.Vector3(sceneColor.r, sceneColor.g, sceneColor.b),
  );
  const sceneHasFilled = colorDiff > threeOpt.scene.background.colorDiffLimit ? true : false;

  return sceneHasFilled;
};

export const testDotHasFilled = (dot) => {
  const dotHasFilled = dot && dot.visible ? true : false;

  return dotHasFilled;
};

const testSameColor = (colorA, colorB) => {
  const rDelta = Math.abs(colorA.r - colorB.r);
  const gDelta = Math.abs(colorA.g - colorB.g);
  const bDelta = Math.abs(colorA.b - colorB.b);

  const delta = rDelta + gDelta + bDelta;
  return delta < 0.001 ? true : false;
};

export const resetInputsOverCurrentLayer = (inputArr, layerId) => {
  inputArr.forEach((input) => {
    const inputStep = input.getAttribute('data-step');
    if (inputStep > layerId) {
      input.value = input.defaltValue;
    }
  });
};

export const removeObjsOverCurrentLayer = (scene, layerId, runToggleCtrl, animation) => {
  if (!scene) return;
  const build = scene.getObjectByName('build');
  if (!build) return;
  if (runToggleCtrl) toggleCtrl(scene, false, 'removeObjs');

  const layerKeyArr = ['scene', 'hole', 'cell', 'dot'];
  layerKeyArr.forEach((layerKey, i) => {
    if (i >= layerId) {
      const layerObj = build.getObjectByName(layerKey);
      if (layerObj) {
        switch (layerKey) {
          case 'dot': {
            if (layerId < 5) {
              removeObj(layerObj, animation).then(() => {
                if (runToggleCtrl) toggleCtrl(scene, true, 'removeObjs');
              });
            }
            break;
          }
          default: {
            removeObj(layerObj, animation).then(() => {
              if (runToggleCtrl) toggleCtrl(scene, true, 'removeObjs');
            });
            break;
          }
        }
      }
    }
  });
};

export const removeObj = (obj, animation, runtime) => {
  return new Promise((resolve) => {
    if (!obj) return;
    const scene = obj.parent;
    if (!scene) return;

    runtime = runtime ? runtime : threeOpt.animation.removeObj.time;

    if (animation) {
      changeObjOpacity(obj, 0, true, runtime).then(() => {
        obj.removeFromParent();
        resolve();
      });
    } else {
      obj.removeFromParent();
      resolve();
    }
  });
};

export const wiggleObj = (obj, delta) => {
  if (!obj || !obj.ptArr) return;

  const ptArr = [];
  obj.ptArr.forEach((pt, i) => {
    pt.wiggleParam += delta;
    ptArr.push(pt.clone().add(pt.vec.clone().multiplyScalar(Math.sin(pt.wiggleParam))));
  });

  obj.geometry.dispose();
  obj.geometry = createGeoFromPtArr(ptArr);
};

export const checkLetterExist = (str) => {
  return !str || str === 'undefined' || str === '' ? false : true;
};

export const loadFontByJSON = (isLeftoverSpinner) => {
  return new Promise((resolve) => {
    const spinnerArr = document.querySelectorAll('.spinner');
    const dim = document.getElementById('dim');
    const spinnerNum = isLeftoverSpinner ? spinnerArr.length - 1 : spinnerArr.length;
    if (assetIndex.fontJSON.obj) {
      if (!isLeftoverSpinner) dim.style.display = 'none';
      resolve(assetIndex.fontJSON.obj);
    } else {
      new FontLoader().load(
        assetIndex.fontJSON.dir,
        (font) => {
          if (!isLeftoverSpinner) dim.style.display = 'none';
          assetIndex.fontJSON.obj = font;
          resolve(assetIndex.fontJSON.obj);
        },
        (xhr) => {
          const param = mapRange(xhr.loaded / assetIndex.fontJSON.contentLng, 0, 1, 0, spinnerNum - 1);
          const lastSolidId = Math.floor(param);
          spinnerArr.forEach((spinner, i) => {
            if (i <= lastSolidId) spinner.style.backgroundColor = 'black';
          });
        },
      );
    }
  });
};

/*
MY DOT PAGE
*/

export const createCopyright = (svgData, sceneColor, dotColor, squareSize) => {
  const planeWidth = squareSize;
  const planeHeight = planeWidth / threeOpt.customArray.copyright.aspect;
  const copyright = new THREE.Group();
  copyright.visible = false;
  copyright.name = 'copyright';
  copyright.position.set(0, squareSize * -0.5 + planeHeight * 0.5, 100);
  copyright.posBackUp = copyright.position.clone();

  const fillMat = new THREE.MeshBasicMaterial({ color: dotColor, side: THREE.DoubleSide });
  const fillGroup = new THREE.Group();
  copyright.add(fillGroup);
  fillGroup.name = 'copyright-text';

  const strokeMat = new THREE.MeshBasicMaterial({ color: sceneColor, side: THREE.DoubleSide });
  const strokeGroup = new THREE.Group();
  copyright.add(strokeGroup);
  fillGroup.name = 'copyright-stroke';
  const strokeStyle = SVGLoader.getStrokeStyle(threeOpt.customArray.copyright.strokeWidth, sceneColor.getStyle());

  svgData.paths.forEach((path) => {
    const shapeArr = SVGLoader.createShapes(path);
    const holeShapeArr = [];
    shapeArr.forEach((shape) => {
      const fillGeo = new THREE.ShapeGeometry(shape);
      fillGeo.rotateX(Math.PI);
      const fill = new THREE.Mesh(fillGeo, fillMat);
      fillGroup.add(fill);

      if (shape.holes && shape.holes.length > 0) {
        for (let j = 0; j < shape.holes.length; j++) {
          const hole = shape.holes[j];
          holeShapeArr.push(hole);
        }
      }
    });

    shapeArr.push.apply(shapeArr, holeShapeArr);

    shapeArr.forEach((shape) => {
      const strokePtArr = shape.getPoints();
      const strokeGeo = SVGLoader.pointsToStroke(strokePtArr, strokeStyle);
      strokeGeo.rotateX(Math.PI);
      const stroke = new THREE.Mesh(strokeGeo, strokeMat);
      strokeGroup.add(stroke);
    });
  });
  const fillGroupBBox = getBBoxData(fillGroup);
  const fillGroupPadding = planeWidth * threeOpt.customArray.copyright.padding;
  const fillGroupWidth = planeWidth - fillGroupPadding * 2;
  const fillGroupScale = (fillGroupWidth / fillGroupBBox.size.x) * threeOpt.customArray.copyright.textWidthPerBBox;
  const fillGroupHeight = fillGroupBBox.size.y;
  fillGroup.scale.set(fillGroupScale, fillGroupScale, fillGroupScale);
  fillGroup.position.set(fillGroupWidth * -0.5, fillGroupHeight, 1);

  strokeGroup.scale.set(fillGroupScale, fillGroupScale, fillGroupScale);
  strokeGroup.position.set(fillGroup.position.x, fillGroup.position.y, fillGroup.position.z - 1);

  return copyright;
};

export const updateCopyrightPosAndScale = (copyright, camera) => {
  const param = 1 / camera.zoom;
  const posY = copyright.posBackUp.y * param + camera.position.y;

  copyright.scale.set(param, param, param);
  copyright.position.y = posY;
};

export const loadSVG = (svgData) => {
  return new Promise((resolve) => {
    new SVGLoader().load(svgData.dir, (data) => {
      resolve(data);
    });
  });
};

export const refineUserData = (rawData) => {
  const userData = {};
  Object.keys(rawData).forEach((key) => {
    const data = rawData[key];
    switch (key) {
      case 'sceneColor':
      case 'holeColor':
      case 'cellColor':
      case 'dotColor': {
        const rgbArr = hexToRgb(data);
        userData[key] = new THREE.Color(rgbArr[0] / 255, rgbArr[1] / 255, rgbArr[2] / 255);
        break;
      }
      case 'holeScale':
      case 'cellOpacity':
      case 'dotDistortSize':
      case 'dotScale': {
        userData[key] = parseFloat(data);
        break;
      }
      default: {
        break;
      }
    }
  });
  userData.id = rawData.id;
  userData.text = concatUserText(rawData.textFuture, rawData.textPower);

  return userData;
};

export const arrayLetters = (type, userData, arrayGroup) => {
  const scene = arrayGroup.parent;
  if (!scene) return;

  const arraySpec =
    type === 'linear'
      ? calcLinearArraySpec(scene.size, arrayGroup.letterStep[type], arrayGroup.posNoise[type])
      : calcRadialArraySpec(scene.size, arrayGroup.letterStep[type], arrayGroup.posNoise[type]);
  const letterNumNow = arrayGroup.children.length;
  const letterNumNext = arraySpec.pos.length;
  matchLetterNumToArray(userData, arrayGroup, letterNumNow, letterNumNext);

  arrayGroup.children.forEach((letter, i) => {
    letter.position.copy(arraySpec.pos[i]);
    letter.rotation.z = arraySpec.angle[i];
  });

  if (arrayGroup.arrayType !== type) {
    if (type === 'linear') {
      // radial -> linear
      arrayGroup.position.y = 0;
    } else {
      // linear -> radial
      arrayGroup.rotation.z = 0;
    }
  }
  arrayGroup.arrayType = type;
};

const calcLinearArraySpec = (sceneSize, letterStep, posNoise) => {
  const sceneDiagonal = Math.sqrt(Math.pow(sceneSize.width, 2) + Math.pow(sceneSize.height, 2));
  const xBase = Math.ceil(sceneDiagonal / letterStep.x);
  const yBase = Math.ceil(sceneDiagonal / letterStep.y);
  const xNum = xBase % 2 ? xBase : xBase + 1;
  const yNum = yBase % 2 ? yBase : yBase + 1;

  const arrayBBox = {
    x: { max: ((xNum - 1) / 2) * letterStep.x },
    y: { max: ((yNum - 1) / 2) * letterStep.y },
  };
  arrayBBox.x.min = arrayBBox.x.max * -1;
  arrayBBox.y.min = arrayBBox.y.max * -1;

  const posArr = [];
  const angleArr = [];
  let zigzagId = 0;
  for (let y = arrayBBox.y.min; y <= arrayBBox.y.max; y += letterStep.y) {
    const xOffset = ((zigzagId % 2) * 2 - 1) * 0.25 * letterStep.x + mapRange(Math.random(), 0, 1, -posNoise.x, posNoise.x);
    zigzagId++;
    for (let x = arrayBBox.x.min; x <= arrayBBox.x.max; x += letterStep.x) {
      const yOffset = mapRange(Math.random(), 0, 1, -posNoise.y, posNoise.y);
      posArr.push(new THREE.Vector3(x + xOffset, y + yOffset, 0));
      angleArr.push(0);
    }
  }

  return {
    pos: posArr,
    angle: angleArr,
  };
};

const calcRadialArraySpec = (sceneSize, letterStep, posNoise) => {
  const sceneMaxSize = Math.max(sceneSize.width, sceneSize.height);
  const base = Math.ceil(sceneMaxSize / letterStep.y);
  const circleNum = base % 2 ? base + 1 : base + 2;
  const rMin = 0;

  const posArr = [];
  const angleArr = [];

  const extraLng = (threeOpt.gallery.letter.size + posNoise.y) * Math.sqrt(2);
  const arrayBBox = {
    x: { max: sceneSize.width * 0.5 + extraLng },
    y: { max: sceneSize.height * 0.5 + extraLng + sceneSize.width * threeOpt.customArray.sizePerScene },
  };
  arrayBBox.x.min = arrayBBox.x.max * -1;
  arrayBBox.y.min = arrayBBox.y.max * -1;

  for (let i = 0; i < circleNum; i++) {
    if (i === 0) {
      posArr.push(new THREE.Vector3(0, 0, 0));
      angleArr.push(0);
    } else {
      const rBase = i * letterStep.y + rMin;
      let angleStep = Math.asin(letterStep.x / (2 * rBase)) * 2;
      const radialNum = Math.round((2 * Math.PI) / angleStep);
      angleStep = (Math.PI * 2) / radialNum;

      for (let j = 0; j < radialNum; j++) {
        const angle = angleStep * j + mapRange(Math.random(), 0, 1, -posNoise.angle, posNoise.angle);
        const r = rBase + mapRange(Math.random(), 0, 1, -posNoise.y, posNoise.y);
        const x = r * Math.cos(angle);
        const y = r * Math.sin(angle);
        if (checkPtIsInBBox({ x: x, y: y }, arrayBBox)) {
          posArr.push(new THREE.Vector3(x, y, 0));
          angleArr.push(angle);
        }
      }
    }
  }

  return {
    pos: posArr,
    angle: angleArr,
  };
};

const matchLetterNumToArray = (userData, arrayGroup, numNow, numNext) => {
  if (numNow < numNext) {
    const addNum = numNext - numNow;
    for (let i = 0; i < addNum; i++) {
      const letter = createLetter(
        arrayGroup.sceneData,
        userData.holeColor,
        userData.holeScale,
        userData.cellColor,
        userData.cellOpacity,
        userData.dotColor,
        userData.dotDistortSize,
        userData.dotScale,
      );
      arrayGroup.add(letter);
    }
  } else if (numNow > numNext) {
    const deleteNum = numNow - numNext;
    const deleteLastId = numNow - deleteNum;
    for (let i = numNow - 1; i >= deleteLastId; i--) {
      const letter = arrayGroup.children[i];
      letter.removeFromParent();
    }
  }
};

export const calcArrayFitSceneSize = (aspect, squareSize) => {
  let sceneWidth, sceneHeight;
  if (aspect > 1) {
    // landscape
    sceneHeight = squareSize / threeOpt.customArray.sizePerScene;
    sceneWidth = sceneHeight * aspect;
  } else {
    // portrait
    sceneWidth = squareSize / threeOpt.customArray.sizePerScene;
    sceneHeight = sceneWidth / aspect;
  }

  return {
    width: sceneWidth,
    height: sceneHeight,
  };
};

export const renderScene = (renderer, scene, camera) => {
  renderer.render(scene, camera);
};

const addTempImg = (renderer) => {
  return new Promise((resolve) => {
    const divTemp = document.getElementById('div-temp');
    const imgTemp = new Image();
    imgTemp.onload = () => {
      divTemp.appendChild(imgTemp);
      divTemp.style.zIndex = 10;
      resolve({
        img: imgTemp,
        div: divTemp,
      });
    };
    imgTemp.src = renderer.domElement.toDataURL();
  });
};

const clearTempImg = (tempData) => {
  tempData.img.remove();
  tempData.div.style.zIndex = -10;
};

const modifySceneBeforeShare = (renderer, scene, camera, squareSize, rendererSize, showCopyright) => {
  const copyright = scene.getObjectByName('copyright');
  if (showCopyright) copyright.visible = true;
  renderer.setSize(rendererSize, rendererSize);
  renderer.setPixelRatio(1);
  renderer.domElement.style.opacity = 0;
  fitCamToScene(camera, { width: squareSize, height: squareSize });
  renderScene(renderer, scene, camera);
};

const restoreSceneAfterShare = (renderer, scene, camera) => {
  const copyright = scene.getObjectByName('copyright');
  copyright.visible = false;
  renderer.setSize(renderer.size.width, renderer.size.height);
  renderer.setPixelRatio(renderer.dpr);
  renderer.domElement.style.opacity = 1;
  fitCamToScene(camera, scene.size);
  renderScene(renderer, scene, camera);
};

export const runSaveAsImg = (renderer, scene, camera, squareSize) => {
  addTempImg(renderer).then((tempData) => {
    modifySceneBeforeShare(renderer, scene, camera, squareSize, threeOpt.customArray.rendererSize.saveAsImg, true);
    saveCvsAsImg(renderer.domElement, 'image/jpeg').then(() => {
      restoreSceneAfterShare(renderer, scene, camera);
      clearTempImg(tempData);
    });
  });
};

const saveCvsAsImg = (cvs, dataUrl) => {
  return new Promise((resolve) => {
    const dataURL = cvs.toDataURL(dataUrl);
    cvs.toBlob((blob) => {
      saveAs(blob, '뮤지엄 타임캡슐_미래로 향한 뮤지엄');
      resolve();
    });
  });
};

// const runPrintAsImg = (renderer, scene, camera, squareSize) => {
//   addTempImg(renderer).then((tempData) => {
//     modifySceneBeforeShare(renderer, scene, camera, squareSize, threeOpt.customArray.rendererSize.printAsImg, true);
//     const timeout = setTimeout(() => {
//       printCvsAsImg(renderer.domElement, 'image/jpeg').then(() => {
//         restoreSceneAfterShare(renderer, scene, camera);
//         clearTempImg(tempData);
//         if (timeout) clearTimeout(timeout);
//       }, 500);
//     });
//   });
// };

const printCvsAsImg = (cvs, dataurl) => {
  return new Promise((resolve) => {
    if (window?.print) window.print();
    resolve();
  });
};

export const runMakeKakaoThumb = (renderer, scene, camera, squareSize, isCopyright) => {
  return new Promise((resolve) => {
    addTempImg(renderer).then((tempData) => {
      modifySceneBeforeShare(renderer, scene, camera, squareSize, threeOpt.customArray.rendererSize.saveAsImg, isCopyright);
      const dataURL = renderer.domElement.toDataURL('image/jpeg');
      const timeout = setTimeout(() => {
        restoreSceneAfterShare(renderer, scene, camera);
        clearTempImg(tempData);
        if (timeout) clearTimeout(timeout);
        resolve(dataURL);
      }, 500);
    });
  });
};

export const runCopyUrl = () => {
  const tempEl = document.createElement('input');
  const url = window.location.origin;

  document.body.appendChild(tempEl);
  tempEl.value = url;
  tempEl.select();
  document.execCommand('copy');
  document.body.removeChild(tempEl);
  window.alert('링크가 복사되었습니다.');
};

export const fitCamToScene = (camera, sceneSize) => {
  camera.left = sceneSize.width / -2;
  camera.right = sceneSize.width / 2;
  camera.top = sceneSize.height / 2;
  camera.bottom = sceneSize.height / -2;
  camera.updateProjectionMatrix();
};

export const popUpWebViewGuide = (device) => {
  alert(`${threeOpt.customArray.webViewAlert.front}\n${threeOpt.customArray.webViewAlert.back[device]}`);
};

/*
GALLERY
*/

export const concatUserText = (textFuture, textPower) => {
  return `${textFuture} ${textPower}`;
};

export const createText = (userData, scene) => {
  return new Promise((resolve) => {
    const text = new THREE.Group();
    text.name = 'text';
    text.flipState = 3;
    text.wobbleAngleCoe = 1;
    text.userDataId = userData.id;
    text.patternArr = [];
    text.letterArr = [];
    text.str = '';
    text.buildSpec = {
      hole: {
        color: userData.holeColor,
        scale: userData.holeScale,
      },
      cell: {
        color: userData.cellColor,
        opacity: userData.cellOpacity,
      },
      dot: {
        color: userData.dotColor,
        distortSize: userData.dotDistortSize,
        scale: userData.dotScale,
      },
    };

    let letterArr = splitText(userData.text);
    let patternArr = getPatternArr(letterArr);
    const galleryWidth = scene.size.width - threeOpt.gallery.letter.size;
    const lineSpec = calcFittedLineSpec(patternArr, galleryWidth);
    text.size = {
      width: scene.size.width,
      height: lineSpec.size.height,
    };
    letterArr = [...letterArr, ...lineSpec.extraLetterArr];
    const test = testUpdateText([], letterArr);
    patternArr = getPatternArr(letterArr);

    const letterGroup = new THREE.Group();
    letterGroup.name = 'letterGroup';
    text.add(letterGroup);

    const textCvs = createTextCvs(text.size, userData.sceneColor);
    textCvs.name = 'textCvs';
    textCvs.position.z = threeOpt.gallery.letter.size * -2;
    text.add(textCvs);

    const tempScene = {
      background: userData.sceneColor,
      colorBlank: scene.colorBlank.clone(),
      font: scene.font,
    };

    addLettersOfNum(letterGroup, test.num.add, patternArr, letterArr, threeOpt.gallery.letter.offset, tempScene).then(() => {
      const letterInterval = threeOpt.gallery.letter.size * threeOpt.text.letter.interval.default;
      const spaceInterval = threeOpt.gallery.letter.size * threeOpt.text.letter.interval.space;
      const letterBBox = {
        width: letterInterval,
        height: lineSpec.size.height / lineSpec.lineNum,
      };
      const textSpec = {
        letter: {
          bBox: letterBBox,
          size: threeOpt.gallery.letter.size,
          interval: {
            default: letterInterval,
            space: spaceInterval,
          },
        },
      };
      const matrix = getMatrix(patternArr, letterInterval, spaceInterval, galleryWidth);
      arrayLettersByMatrix(letterGroup.children, matrix, textSpec);
      resolve(text);
    });
  });
};

const createTextCvs = (size, color) => {
  const xBaseDom = size.width / (threeOpt.gallery.textCvs.nodeNum - 1);
  const xRandomDom = xBaseDom * threeOpt.gallery.textCvs.randomness.x * 0.5;
  const yRandomDom = threeOpt.gallery.letter.size * threeOpt.gallery.textCvs.randomness.y;
  const posArr = { top: [], bottom: [] };
  for (let i = 0; i < threeOpt.gallery.textCvs.nodeNum; i++) {
    const xPosBase = xBaseDom * i - size.width / 2;
    const xPosRandom = i !== 0 && i !== threeOpt.gallery.textCvs.nodeNum - 1 ? mapRange(Math.random(), 0, 1, -xRandomDom, xRandomDom) : 0;
    const posTop = new THREE.Vector3();
    const posBottom = new THREE.Vector3();
    posTop.x = xPosBase + xPosRandom;
    posTop.y = size.height * 0.5 + Math.random() * yRandomDom;
    posBottom.x = xPosBase + xPosRandom;
    posBottom.y = size.height * -0.5 - Math.random() * yRandomDom;

    posArr.top.push(posTop);
    posArr.bottom.push(posBottom);
  }
  posArr.bottom.reverse();

  let allPtArr = [];
  Object.keys(posArr).forEach((key) => {
    const crv = new THREE.CatmullRomCurve3(posArr[key]);
    crv.closed = false;
    crv.curveType = 'chordal';
    const ptArr = crv.getPoints(threeOpt.gallery.textCvs.verticeNum);
    allPtArr = [...allPtArr, ...ptArr];
  });

  allPtArr = [...allPtArr, ...[allPtArr[0]]];

  const crvShape = new THREE.Shape(allPtArr);
  const cvsGeo = new THREE.ShapeGeometry(crvShape);
  const cvsMat = new THREE.MeshBasicMaterial({ color: color, transparent: true });
  const cvs = new THREE.Mesh(cvsGeo, cvsMat);
  return cvs;
};

const calcFittedLineSpec = (patternArr, width) => {
  let letterNum = 0;
  patternArr.forEach((pattern) => {
    if (pattern) {
      letterNum += threeOpt.text.letter.interval.default;
    } else {
      letterNum += threeOpt.text.letter.interval.space;
    }
  });

  const baseLineNum = (letterNum * threeOpt.gallery.letter.size) / width;
  const lineNum = Math.max(Math.ceil(baseLineNum), 1);
  const height = lineNum * threeOpt.text.line.height * threeOpt.gallery.letter.size;
  const extraLetterNum = Math.floor(((lineNum - baseLineNum) * width) / (threeOpt.text.letter.interval.default * threeOpt.gallery.letter.size));
  let extraLetterArr = [];
  let extraPatternArr = [];
  const extraLetter = '·';
  for (let i = 0; i < extraLetterNum; i++) {
    extraLetterArr.push(extraLetter);
    extraPatternArr.push(true);
  }

  return {
    lineNum: lineNum,
    extraLetterArr: extraLetterArr,
    extraPatternArr: extraPatternArr,
    size: {
      width: width,
      height: height,
      aspect: width / height,
    },
  };
};

export const updateAllTextsPos = (scene, updateLater) => {
  const posArr = [];
  scene.textArr.forEach((text, i) => {
    let posY;
    if (i) {
      const prevText = scene.textArr[i - 1];
      const prevPos = posArr[i - 1];
      posY = prevPos.y - prevText.size.height / 2 - text.size.height / 2;
    } else {
      posY = scene.size.height / 2 - text.size.height / 2;
    }

    posArr.push(new THREE.Vector3(0, posY, i));
    if (!updateLater) {
      text.position.y = posY;
      text.position.z = i;
    }
  });

  return posArr;
};

export const scrollCam = (camera, deltaY) => {
  const posY = camera.position.y + deltaY;
  if (posY >= 0) {
    camera.position.y = 0;
    camera.lookAt(0, 0, 0);
    return;
  }

  camera.position.y = posY;
  camera.lookAt(0, posY, 0);
};

export const flipText = (text) => {
  const letterGroup = text.getObjectByName('letterGroup');
  const letterNum = letterGroup.children.length;
  const lastFlipId = Math.floor(Math.random() * letterNum);

  switch (flipText.flipState) {
    case 1: {
      letterGroup.children.forEach((letter, i) => {
        const backwardTime =
          i !== lastFlipId
            ? mapRange(Math.random(), 0, 1, threeOpt.animation.flipText.time.backward.min, threeOpt.animation.flipText.time.backward.max)
            : threeOpt.animation.flipText.time.backward.max;
        gsap.to(letter.rotation, {
          duration: backwardTime,
          delay: threeOpt.animation.flipText.time.intermisstion,
          overwrite: true,
          y: 0,
          onStart: () => {
            if (i === 0) text.flipState = 2;
          },
          onComplete: () => {
            if (i === lastFlipId) {
              text.flipState = 3;

              text.wobbleAngleCoe = 0;
              gsap.to(text, {
                duration: threeOpt.animation.wobbleAccel.time,
                wobbleAngleCoe: 1,
              });
            }
          },
        });
      });
      break;
    }
    default: {
      letterGroup.children.forEach((letter, i) => {
        const forwardTime =
          i !== lastFlipId
            ? mapRange(Math.random(), 0, 1, threeOpt.animation.flipText.time.forward.min, threeOpt.animation.flipText.time.forward.max)
            : threeOpt.animation.flipText.time.forward.max;
        const backwardTime =
          i !== lastFlipId
            ? mapRange(Math.random(), 0, 1, threeOpt.animation.flipText.time.backward.min, threeOpt.animation.flipText.time.backward.max)
            : threeOpt.animation.flipText.time.backward.max;
        gsap.to(letter.rotation, {
          duration: forwardTime,
          overwrite: true,
          y: Math.PI,
          onStart: () => {
            if (i === 0) text.flipState = 0;
          },
          onComplete: () => {
            if (i === lastFlipId) text.flipState = 1;
            gsap.to(letter.rotation, {
              duration: backwardTime,
              delay: threeOpt.animation.flipText.time.intermisstion,
              overwrite: true,
              y: 0,
              onStart: () => {
                if (i === 0) text.flipState = 2;
              },
              onComplete: () => {
                if (i === lastFlipId) {
                  text.flipState = 3;

                  text.wobbleAngleCoe = 0;
                  gsap.to(text, {
                    duration: threeOpt.animation.wobbleAccel.time,
                    wobbleAngleCoe: 1,
                  });
                }
              },
            });
          },
        });
      });
      break;
    }
  }
};

export const getIntersectedText = (mousePos, renderer, camera, raycaster, targetObjArr) => {
  if (!mousePos) return;

  const raycasterPos = {
    x: (mousePos.x / renderer.size.width) * 2 - 1,
    y: -(mousePos.y / renderer.size.height) * 2 + 1,
  };
  raycaster.setFromCamera(raycasterPos, camera);
  const intersects = raycaster.intersectObjects(targetObjArr);
  if (!intersects.length) return;
  const obj = intersects[0].object;

  if (obj.name === 'text') {
    return obj;
  } else if (obj.name === 'letterGroup' || obj.name === 'textCvs') {
    return obj.parent;
  } else if (obj.name === 'space') {
    return obj.parent.parent;
  } else {
    return obj.parent.parent.parent;
  }
};

export const toggleAllTextsVisible = (scene, camera) => {
  scene.children.forEach((text) => {
    const frustum = new THREE.Frustum();
    const matrix = new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
    frustum.setFromProjectionMatrix(matrix);
    const posTop = text.position.clone().add(new THREE.Vector3(0, scene.size.height / 4, 0));
    const posBottom = text.position.clone().sub(new THREE.Vector3(0, scene.size.height / 4, 0));
    if (frustum.containsPoint(text.position) || frustum.containsPoint(posTop) || frustum.containsPoint(posBottom)) {
      text.visible = true;
    } else {
      text.visible = false;
    }
  });
};

export const wobbleAllTexts = (scene, simplex, time) => {
  const maxAngle = scene.wobbleAngle;
  scene.children.forEach((text) => {
    if (text.visible && text.flipState === 3) {
      const letterGroup = text.getObjectByName('letterGroup');
      letterGroup.children.forEach((letter) => {
        const x = (text.position.x + letter.position.x + scene.size.width / 2) / scene.size.width;
        const y = (text.position.y + letter.position.y + scene.size.height / 2) / scene.size.height;
        letter.rotation.y = simplex.noise3D(x, y, time) * maxAngle * text.wobbleAngleCoe;
      });
    }
  });
};

const rollTextByTargetPos = (text, targetPos) => {
  text.position.z = targetPos.z;
  gsap.to(text.position, {
    duration: threeOpt.animation.updateText.time.roll,
    y: targetPos.y,
  });

  const letterGroup = text.getObjectByName('letterGroup');
  letterGroup.children.forEach((letter) => {
    letter.rotation.set(0, 0, 0);
    letter.updateMatrix();
    gsap.to(letter.rotation, {
      duration: threeOpt.animation.updateText.time.roll,
      x: Math.PI * 2,
      onComplete: () => {
        letter.rotation.set(0, 0, 0);
        letter.updateMatrix();
      },
    });
  });

  text.wobbleAngleCoe = 0;
  gsap.to(text, {
    duration: threeOpt.animation.wobbleAccel.time,
    delay: threeOpt.animation.updateText.time.roll,
    wobbleAngleCoe: 1,
  });
};

export const updateAllTextsInScene = (scene, userDataArr, camera, frustum) => {
  // case 0: no new data come
  if (!userDataArr.length) return;

  // case 1: all data is same
  if (scene.children.length === userDataArr.length) {
    let allSame = true;
    for (let i = 0; i < scene.children.length; i++) {
      if (scene.children[i].userDataId !== userDataArr[i].id) allSame = false;
    }
    if (allSame) return;
  }

  // clear all gsap and stop
  gsap.globalTimeline.clear();
  scene.wobble = false;

  // case 2: all data is different
  let newDataIdArr = [];
  userDataArr.forEach((userData) => {
    newDataIdArr.push(userData.id);
  });
  let allDifferent = true;
  for (let i = 0; i < scene.children.length; i++) {
    if (newDataIdArr.includes(scene.children[i].userDataId)) {
      allDifferent = false;
      break;
    }
  }
  if (allDifferent) {
    for (let i = scene.children.length - 1; i >= 0; i--) {
      const text = scene.children[i];
      if (i > 0) {
        shrinkText(text, scene);
      } else {
        shrinkText(text, scene).then(() => {
          const idNow = 0;
          const idMax = userDataArr.length - 1;
          popTextsIntoSceneByLoop(userDataArr, scene, idNow, idMax);
        });
      }
    }
    return;
  }

  // collect data in scene to be removed (at least one remains)
  const removeTextArr = [];
  const sceneTextArr = [];
  const sceneDataIdArr = [];
  for (let i = 0; i < scene.children.length; i++) {
    const text = scene.children[i];
    if (!newDataIdArr.includes(text.userDataId)) {
      removeTextArr.push(text);
    } else {
      sceneTextArr.push(text);
      sceneDataIdArr.push(text.userDataId);
    }
  }

  const sceneFirstId = userDataArr.findIndex((userData) => {
    return userData.id === sceneDataIdArr[0];
  });
  const prependUserDataArr = userDataArr.slice(0, sceneFirstId);
  const appendUserDataArr = userDataArr.slice(sceneFirstId, userDataArr.length);

  // remove data in appendUserDataArr if it already exists
  for (let i = appendUserDataArr.length - 1; i >= 0; i--) {
    const userData = appendUserDataArr[i];
    if (sceneDataIdArr.includes(userData.id)) appendUserDataArr.splice(i, 1);
  }

  const mergedUserDataArr = [...prependUserDataArr, ...appendUserDataArr];
  if (mergedUserDataArr.length) {
    // case 3: some remain and at least one new text exists
    let idNow = 0;
    const idMax = mergedUserDataArr.length - 1;
    const newTextArr = [];
    function createNewText(idNow) {
      const userData = mergedUserDataArr[idNow];
      createText(userData, scene).then((text) => {
        newTextArr.push(text);
        if (idNow < idMax) {
          idNow++;
          createNewText(idNow);
        } else {
          newTextArr.splice(prependUserDataArr.length, 0, ...sceneTextArr);

          const tempScene = {
            textArr: newTextArr,
            size: scene.size,
          };
          const textPosArr = updateAllTextsPos(tempScene, true);

          let prependTextOffset = 0;
          if (prependUserDataArr.length) {
            const lastPrependText = newTextArr[prependUserDataArr.length - 1];
            prependTextOffset = scene.size.height / 2 + lastPrependText.size.height / 2;
          }

          let appendTextOffset = 0;
          if (appendUserDataArr.length) {
            const lastRemainedText = sceneTextArr[sceneTextArr.length - 1];
            const firstAppendId = prependUserDataArr.length + sceneTextArr.length;
            const firstAppendText = newTextArr[firstAppendId];
            appendTextOffset = lastRemainedText.position.y - lastRemainedText.size.height / 2 - firstAppendText.size.height / 2;
          }

          newTextArr.forEach((newText, i) => {
            if (i < prependUserDataArr.length) {
              // prepend
              newText.position.y = textPosArr[i] + prependTextOffset;
              newText.visible = true;
            } else {
              if (newText.parent) {
                // remain
              } else {
                // append
                newText.position.y = textPosArr[i] + appendTextOffset;
              }
            }
          });

          for (let i = sceneTextArr.length - 1; i >= 0; i--) {
            const remainText = sceneTextArr[i];
            remainText.removeFromParent();
          }

          for (let i = 0; i < newTextArr.length; i++) {
            const newText = newTextArr[i];
            scene.add(newText);
          }

          for (let i = removeTextArr.length - 1; i >= 0; i--) {
            const removeText = removeTextArr[i];
            shrinkText(removeText, scene);
          }

          scene.textArr = sceneTextArr;
          newTextArr.forEach((newText, i) => {
            const targetPos = textPosArr[i];
            if (!newText.parent) scene.add(newText);
            rollTextByTargetPos(newText, targetPos);
          });

          const rollOpt = { param: 0 };
          gsap.to(rollOpt, {
            duration: threeOpt.animation.updateText.time.roll + 0.01,
            onComplete: () => {
              toggleAllTextsVisible(scene, camera, frustum);
              scene.wobble = true;
            },
          });
        }
      });
    }
    createNewText(idNow);
  } else {
    // case 4: some remains and no new text exists
    for (let i = removeTextArr.length - 1; i >= 0; i--) {
      const removeText = removeTextArr[i];
      shrinkText(removeText, scene);
    }

    const tempScene = {
      textArr: sceneTextArr,
      size: scene.size,
    };
    const textPosArr = updateAllTextsPos(tempScene, true);

    scene.textArr = sceneTextArr;
    sceneTextArr.forEach((remainedText, i) => {
      const targetPos = textPosArr[i];
      rollTextByTargetPos(remainedText, targetPos);
    });

    const rollOpt = { param: 0 };
    gsap.to(rollOpt, {
      duration: threeOpt.animation.updateText.time.roll + 0.01,
      onComplete: () => {
        toggleAllTextsVisible(scene, camera, frustum);
        scene.wobble = true;
      },
    });
  }
};

export const popTextsIntoSceneByLoop = (userDataArr, scene, camera, frustum, idNow, idMax, isLeftoverSpinner) => {
  return new Promise((resolve) => {
    const textArr = [];
    let spinnerArr, dim;
    if (isLeftoverSpinner) {
      spinnerArr = document.querySelectorAll('.spinner');
      dim = document.getElementById('dim');
    }
    createText(userDataArr[idNow], scene).then((text) => {
      popText(text, scene);
      textArr.push(text);
      if (idNow < idMax) {
        idNow++;
        popTextsIntoSceneByLoop(userDataArr, scene, camera, frustum, idNow, idMax, isLeftoverSpinner);
      } else {
        if (isLeftoverSpinner) {
          spinnerArr.forEach((spinner) => {
            spinner.style.backgroundColor = 'black';
          });
          dim.style.display = 'none';
        }
        updateAllTextsPos(scene);
        toggleAllTextsVisible(scene, camera);
        resolve(textArr);
      }
    });
  });
};

const popText = (text, scene) => {
  if (scene) {
    scene.textArr.push(text);
    scene.add(text);
  }

  text.wobbleAngleCoe = 0;
  gsap.to(text, {
    duration: threeOpt.animation.updateText.time.pop,
    wobbleAngleCoe: 1,
  });

  const textCvs = text.getObjectByName('textCvs');
  textCvs.material.opacity = 0;
  gsap.to(textCvs.material, {
    duration: threeOpt.animation.updateText.time.pop,
    opacity: 1,
  });

  const letterGroup = text.getObjectByName('letterGroup');
  letterGroup.children.forEach((letter) => {
    const scale = letter.scaleBackUp;
    letter.scale.set(0, 0, 0);
    gsap.to(letter.scale, {
      duration: threeOpt.animation.updateText.time.pop,
      x: scale,
      y: scale,
      z: scale,
    });
  });
};

const shrinkText = (text, scene) => {
  return new Promise((resolve) => {
    const letterGroup = text.getObjectByName('letterGroup');
    letterGroup.children.forEach((letter) => {
      gsap.to(letter.scale, {
        duration: threeOpt.animation.updateText.time.shrink,
        x: 0,
        y: 0,
        z: 0,
      });
    });

    const textCvs = text.getObjectByName('textCvs');
    gsap.to(textCvs.material, {
      duration: threeOpt.animation.updateText.time.shrink,
      opacity: 0,
      onComplete: () => {
        if (scene) {
          text.removeFromParent();
          const textId = scene.textArr.findIndex((testText) => {
            return testText.userDataId === text.userDataId;
          });
          scene.textArr.splice(textId, 1);
        }
        resolve();
      },
    });
  });
};

export const checkCamIsAtBottom = (scene, camera) => {
  if (!scene.children.length) return true;

  const lastText = scene.children[scene.children.length - 1];
  if (camera.position.y - scene.size.height / 2 < lastText.position.y - lastText.size.height / 2) {
    return true;
  } else {
    return false;
  }
};

const checkPtIsInBBox = (pt, bBox) => {
  if (pt.x <= bBox.x.min) return;
  if (pt.x >= bBox.x.max) return;
  if (pt.y <= bBox.y.min) return;
  if (pt.y >= bBox.y.max) return;

  return true;
};

const getBBoxData = (obj) => {
  const bBox = new THREE.Box3().setFromObject(obj);
  const center = bBox.getCenter(new THREE.Vector3());
  const size = bBox.getSize(new THREE.Vector3());

  return {
    bBox: bBox,
    center: center,
    size: size,
  };
};
