import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '@/contexts/auth';

const Bootstrap: React.FC = ({ children }) => {
  const { refresh, booted } = useAuth();
  const history = useHistory();
  const location = useLocation();
  // const state: any = location?.state || { from: { pathname: Paths.canvas } };
  const state: any = location?.state;

  useEffect(() => {
    if (!booted && refresh) {
      refresh(() => {
        if (state?.from) history.replace(state.from);
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [state]);

  return <>{children}</>;
};

export default Bootstrap;
