import * as THREE from 'three';
import SimplexNoise from 'simplex-noise';
import { assetIndex, threeOpt } from './mainConfig';
import {
  refineUserData,
  renderScene,
  fitCamToScene,
  updateAllTextsPos,
  scrollCam,
  getIntersectedText,
  getMousePosVec,
  flipText,
  toggleAllTextsVisible,
  wobbleAllTexts,
  popTextsIntoSceneByLoop,
  updateAllTextsInScene,
  checkCamIsAtBottom,
  loadFontByJSON,
} from './mainUtils';
import { checkMobile, createEvtKey, createEvtListener, mapRange, checkWebViewAndDevice } from './commonUtils';
import EventEmitter from '@/utils/EventEmitter';
import { LOAD_MORE_PARTS } from '@/utils/constants';

const domEl = {};
const mouseEl = {};
const threeEl = {};
const asset = {};
let userDataArr = [];

const useGallery = () => {
  const onInit = (isLeftoverSpinner, isGallery) => {
    /* initialize paramters */
    // dom
    domEl.divThree = document.getElementById('div-three');
    domEl.cvsThree = document.getElementById('cvs-three');
    domEl.inputCtrlArr = document.querySelectorAll('.input-ctrl');
    domEl.btnShareArr = document.querySelectorAll('.btn-share');

    // mouse
    mouseEl.evtList = [];
    mouseEl.evtKey = { click: 'click' };
    mouseEl.isMobile = checkMobile();
    const webViewAndDeviceData = checkWebViewAndDevice();
    mouseEl.isWebView = webViewAndDeviceData.isWebView;
    mouseEl.device = webViewAndDeviceData.device;
    createEvtKey(mouseEl);
    mouseEl.isPressed = mouseEl.isMobile ? false : true;

    // three
    threeEl.scene = {};
    threeEl.renderer = {};
    threeEl.camera = {};
    threeEl.raycaster = {};
    threeEl.frustum = {};
    threeEl.clock = {};
    threeEl.hole = {};
    threeEl.cell = {};
    threeEl.dot = {};
    threeEl.gallery = {};
    threeEl.noise = {};
    threeEl.font = { obj: asset.font };

    if (isGallery) threeEl.scene.pageReloadFrame = threeOpt.gallery.pageReloadTime * 60;
    threeEl.scene.partsLoadable = true;
    threeEl.scene.background = {};
    threeEl.scene.background.color = {
      blank: new THREE.Color(threeOpt.scene.background.color.blank),
    };

    const scaleObjKeyArr = ['hole', 'dot'];
    scaleObjKeyArr.forEach((scaleObjKey) => {
      threeEl[scaleObjKey].scale = JSON.parse(JSON.stringify(threeOpt[scaleObjKey].scale));
      threeEl[scaleObjKey].scale.default = threeOpt[scaleObjKey].scale.default = mapRange(
        2,
        0,
        4,
        threeEl[scaleObjKey].scale.min,
        threeEl[scaleObjKey].scale.max,
      );
    });
    threeEl.dot.distortSize = JSON.parse(JSON.stringify(threeOpt.dot.distortSize));
    threeEl.dot.distortSize.default = threeOpt.dot.distortSize.default = mapRange(2, 0, 4, threeEl.dot.distortSize.min, threeEl.dot.distortSize.max);

    /* initialize three */
    // create three.scene
    threeEl.scene.size = {
      width: window.innerWidth > 768 ? threeOpt.scene.width : threeOpt.scene.width * 0.5,
    };
    threeEl.scene.obj = new THREE.Scene();
    threeEl.scene.obj.background = threeEl.scene.background.color.blank;
    threeEl.scene.obj.colorBlank = threeEl.scene.background.color.blank.clone();
    threeEl.scene.obj.font = threeEl.font.obj;
    threeEl.scene.obj.size = threeEl.scene.size;
    threeEl.scene.obj.wobbleAngle = (threeOpt.gallery.wobble.angle * Math.PI) / 180;
    threeEl.scene.obj.wobble = true;
    threeEl.scene.obj.textArr = [];

    // create three.renderer
    onResize();
    threeEl.renderer.obj = new THREE.WebGLRenderer({
      powerPreference: 'high-performance',
      canvas: domEl.cvsThree,
    });
    threeEl.renderer.obj.setPixelRatio(threeEl.renderer.dpr);
    threeEl.renderer.obj.setSize(threeEl.renderer.size.width, threeEl.renderer.size.height);
    threeEl.renderer.obj.size = threeEl.renderer.size;
    threeEl.renderer.obj.dpr = threeEl.renderer.dpr;

    // create three.camera
    threeEl.camera.obj = new THREE.OrthographicCamera(
      threeEl.scene.size.width / -2,
      threeEl.scene.size.width / 2,
      threeEl.scene.size.height / 2,
      threeEl.scene.size.height / -2,
      1,
      threeOpt.scene.width * 2,
    );
    threeEl.camera.obj.position.set(0, 0, threeOpt.scene.width);
    threeEl.camera.obj.lookAt(0, 0, 0);
    threeEl.camera.obj.updateProjectionMatrix();

    // create three.raycaster
    threeEl.raycaster.obj = new THREE.Raycaster();

    // create three.frustum
    threeEl.frustum.obj = new THREE.Frustum();

    // create.there.clock
    threeEl.clock.obj = new THREE.Clock();

    // create three.noise with simplex.noise
    threeEl.noise.simplex = new SimplexNoise();

    /* initialize objects */
    if (userDataArr && userDataArr.length) {
      const idNow = 0;
      const idMax = userDataArr.length - 1;
      popTextsIntoSceneByLoop(userDataArr, threeEl.scene.obj, threeEl.camera.obj, threeEl.frustum.obj, idNow, idMax, isLeftoverSpinner);
    }

    /* initialize events */
    mouseEl.evtList.push(createEvtListener('resize', onResize));
    if (!mouseEl.isMobile) {
      mouseEl.evtList.push(createEvtListener('wheel', onWheel));
      mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.move, onHoverOnCvs, domEl.cvsThree));
    } else {
      mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.click, onClickToCvs, domEl.cvsThree));
      mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.down, onDownToCvs, domEl.cvsThree));
      mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.move, onMoveOnCvs, domEl.cvsThree));
      mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.up, onUpFromCvs, domEl.cvsThree));
      mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.out, onOutFromCvs, domEl.cvsThree));
    }

    mouseEl.evtList.forEach((evt) => {
      evt.targetDom.addEventListener(evt.evtKey, evt.callback, false);
    });

    /* start animation */
    threeEl.clock.obj.start();
    if (isGallery) {
      threeEl.scene.pageReloadCounter = 0;
      threeEl.renderer.obj.setAnimationLoop(onAnimateGallery);
    } else {
      if (mouseEl.isMobile && mouseEl.device === 'android') {
        threeEl.renderer.obj.setAnimationLoop(onAnimateAndroid);
      } else {
        threeEl.renderer.obj.setAnimationLoop(onAnimateDefault);
      }
    }
  };

  const onAnimateGallery = () => {
    threeEl.scene.pageReloadCounter++;
    if (threeEl.scene.pageReloadCounter === threeEl.scene.pageReloadFrame) {
      window.location.reload();
      return;
    }

    if (threeEl.scene.obj.wobble)
      wobbleAllTexts(threeEl.scene.obj, threeEl.noise.simplex, threeEl.clock.obj.getElapsedTime() * threeOpt.gallery.wobble.speedCoe);
    threeEl.renderer.obj.render(threeEl.scene.obj, threeEl.camera.obj);
  };

  const onAnimateDefault = () => {
    if (threeEl.scene.obj.wobble)
      wobbleAllTexts(threeEl.scene.obj, threeEl.noise.simplex, threeEl.clock.obj.getElapsedTime() * threeOpt.gallery.wobble.speedCoe);
    threeEl.renderer.obj.render(threeEl.scene.obj, threeEl.camera.obj);
  };

  const onAnimateAndroid = () => {
    threeEl.renderer.obj.render(threeEl.scene.obj, threeEl.camera.obj);
  };

  const onUpdateUserData = (rawDataArr) => {
    if (!rawDataArr || !rawDataArr.length) return;

    userDataArr = [];
    rawDataArr.forEach((rawData) => {
      const userData = refineUserData(rawData);
      userDataArr.push(userData);
    });

    if (!threeEl.scene || !threeEl.scene.obj) return;
    updateAllTextsInScene(threeEl.scene.obj, userDataArr, threeEl.camera.obj, threeEl.frustum.obj);
  };

  const onWheel = (evt) => {
    threeEl.scene.pageReloadCounter = 0;
    const deltaY = evt.deltaY * threeOpt.gallery.scroll.wheelCoe * -1;
    scrollCam(threeEl.camera.obj, deltaY);
    toggleAllTextsVisible(threeEl.scene.obj, threeEl.camera.obj, threeEl.frustum.obj);
    const isCamAtBottom = checkCamIsAtBottom(threeEl.scene.obj, threeEl.camera.obj);
    if (isCamAtBottom && threeEl.scene.partsLoadable && deltaY < 0 && threeEl.scene.obj.children.length < threeOpt.gallery.scrollLimitNum) {
      EventEmitter.emit(LOAD_MORE_PARTS);
      threeEl.scene.partsLoadable = false;
      setTimeout(() => {
        threeEl.scene.partsLoadable = true;
      }, 300);
    }
  };

  const onHoverOnCvs = (evt) => {
    if (!threeEl.scene.obj.wobble) return;

    threeEl.scene.pageReloadCounter = 0;
    const mousePos = getMousePosVec(evt);
    let text = getIntersectedText(mousePos, threeEl.renderer.obj, threeEl.camera.obj, threeEl.raycaster.obj, threeEl.scene.obj.children);
    if (!text) return;

    flipText(text);
  };

  const onClickToCvs = (evt) => {
    const mousePos = getMousePosVec(evt);
    let text = getIntersectedText(mousePos, threeEl.renderer.obj, threeEl.camera.obj, threeEl.raycaster.obj, threeEl.scene.obj.children);
    if (!text) return;

    flipText(text);
  };

  const onDownToCvs = (evt) => {
    mouseEl.isPressed = true;
    mouseEl.pos = getMousePosVec(evt);
  };

  const onMoveOnCvs = (evt) => {
    if (!mouseEl.isPressed) return;
    const mousePos = getMousePosVec(evt);
    const deltaY = (mousePos.y - mouseEl.pos.y) * threeOpt.gallery.scroll.touchMoveCoe;
    mouseEl.pos = mousePos.clone();
    scrollCam(threeEl.camera.obj, deltaY);
    toggleAllTextsVisible(threeEl.scene.obj, threeEl.camera.obj, threeEl.frustum.obj);
    const isCamAtBottom = checkCamIsAtBottom(threeEl.scene.obj, threeEl.camera.obj);
    if (isCamAtBottom && threeEl.scene.partsLoadable && deltaY < 0) {
      EventEmitter.emit(LOAD_MORE_PARTS);
      threeEl.scene.partsLoadable = false;
      setTimeout(() => {
        threeEl.scene.partsLoadable = true;
      }, 300);
    }
  };

  const onUpFromCvs = () => {
    onOutFromCvs();
  };

  const onOutFromCvs = () => {
    mouseEl.isPressed = false;
    mouseEl.pos = undefined;
  };

  const onLoad = (isGallery) => {
    const isLeftoverSpinner = true;
    loadFontByJSON(isLeftoverSpinner).then((font) => {
      asset.font = font;
      onInit(isLeftoverSpinner, isGallery);
    });
  };

  const onUnload = () => {
    /* clear event listeners */
    // mouse
    if (mouseEl.evtList) {
      mouseEl.evtList.forEach((evt) => {
        evt.targetDom?.removeEventListener(evt.evtKey, evt.callback, false);
      });
    }
    mouseEl.evtList = [];

    // three
    threeEl.renderer?.obj?.setAnimationLoop(null);

    // userData
    userDataArr = undefined;
  };

  const onResize = () => {
    const divThreeBBox = domEl.divThree.getBoundingClientRect();
    threeEl.renderer.dpr = window.devicePixelRatio;
    threeEl.renderer.size = {
      width: divThreeBBox.width,
      height: divThreeBBox.height,
    };
    threeEl.renderer.size.aspect = threeEl.renderer.size.width / threeEl.renderer.size.height;

    threeEl.scene.size.height = threeEl.scene.size.width / threeEl.renderer.size.aspect;
    threeEl.scene.size.aspect = threeEl.scene.size.width / threeEl.scene.size.height;
    threeEl.scene.size.diagonal = Math.sqrt(Math.pow(threeEl.scene.size.width, 2) + Math.pow(threeEl.scene.size.height, 2));

    if (threeEl.scene.obj) {
      threeEl.scene.obj.size = threeEl.scene.size;

      if (threeEl.scene.obj.children.length) {
        updateAllTextsPos(threeEl.scene.obj);
      }
    }

    if (threeEl.renderer.obj) {
      threeEl.renderer.obj.setPixelRatio(threeEl.renderer.dpr);
      threeEl.renderer.obj.setSize(threeEl.renderer.size.width, threeEl.renderer.size.height);
      threeEl.renderer.obj.size = threeEl.renderer.size;
      threeEl.renderer.obj.dpr = threeEl.renderer.dpr;
    }

    if (threeEl.camera.obj) {
      fitCamToScene(threeEl.camera.obj, threeEl.scene.obj.size);
      threeEl.camera.obj.updateProjectionMatrix();
      renderScene(threeEl.renderer.obj, threeEl.scene.obj, threeEl.camera.obj);
    }
  };

  return {
    onLoad,
    onUnload,
    onUpdateUserData,
  };
};

export default useGallery;
