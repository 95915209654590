import { useEffect } from 'react';
import { useParts } from '@/contexts/parts';
import useGallery from '../interactive/useGallery';
import EventEmitter from '@/utils/EventEmitter';
import { LOAD_MORE_PARTS } from '@/utils/constants';

const Gallery = () => {
  const { setWatching, isInit, partsList, getParts } = useParts();
  const { onLoad, onUnload, onUpdateUserData } = useGallery();

  useEffect(() => {
    EventEmitter.off(LOAD_MORE_PARTS);
    EventEmitter.on(LOAD_MORE_PARTS, () => getParts());

    return () => {
      EventEmitter.removeListener(LOAD_MORE_PARTS);
    };
  }, [getParts]);

  useEffect(() => {
    if (isInit) {
      setWatching(true);
      onLoad(true);
      return () => {
        onUnload();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInit, setWatching]);

  useEffect(() => {
    onUpdateUserData(partsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsList]);

  return (
    <>
      <div id="section-dot" className="w-full h-screen flex flex-col xl:flex-row overflow-hidden fixed">
        <div id="div-three" className="w-full h-full">
          <canvas id="cvs-three" />
        </div>
      </div>
      <div id="dim" className="fixed z-20 inset-0 bg-white bg-opacity-50 cursor-wait">
        <div className="flex flex-row absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {[...Array(10)].map((x, i) => (
            <div key={i} className="spinner w-10 h-10 rounded-full border border-black bg-white" />
          ))}
        </div>
      </div>
    </>
  );
};

export default Gallery;
