import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { gsap } from 'gsap';
import Portal from '@/components/common/Portal';
import Questions from './questionSteps';
import useMain from '../interactive/useMain';
import { placeholder } from './mainConfig';
import { waitForEl } from './commonUtils';
import { formatDate } from '@/utils';
import { useParts } from '@/contexts/parts';
import ReCAPTCHA from 'react-google-recaptcha';
import CaptchaCache from '@/utils/CaptchaCache';
import { APP_GOOGLE_RECAPTCHA_KEY_V2, Paths } from '@/utils/constants';
let letterProps = {};
let drawProps = {};

const ContentSection = () => {
  const { onInputChange, onStepChange, onGetBuildData } = useMain();
  const { createPart } = useParts();
  const [isShowRecaptcha, setShowRecaptcha] = useState(false); //useState(CaptchaCache.show);
  const { push } = useHistory();
  const [step, setStep] = useState(0);
  const [color, setColor] = useState(undefined);
  const [text, setText] = useState('');
  const rangeDefaultVal = 2;
  const [rangeValue, setRangeValue] = useState(rangeDefaultVal);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [colSize, setColSize] = useState(undefined);
  const [textLength, setTextLength] = useState(0);
  const [colWrapSize, setColWrapSize] = useState(undefined);
  const [pushDataOnce, setPushDataOnce] = useState(false);
  const [optStyled, setOptStyled] = useState({ left: false, right: false });
  const contentRef = useRef(null);
  const titleRef = useRef(null);
  const footerRef = useRef(null);
  const rangeRef = useRef(null);
  const textareaRef = useRef(null);
  const [toast, setToast] = useState(false);
  const toastStyle = useMemo(() => ({ transform: toast ? 'translate(0, 0)' : 'translate(calc(-100% - 2rem), 0)' }), [toast]);

  const isLastStep = useMemo(() => step === Questions.length - 1, [step]);

  useEffect(() => {
    function handleResize() {
      const colorSize = window.innerWidth >= 1024 ? (window.innerWidth / 2 - 32) / 5 : (window.innerWidth - 32) / 5;
      const wrap = contentRef?.current.clientHeight - titleRef?.current.clientHeight - footerRef?.current.clientHeight - 1;
      setColSize(Math.floor(colorSize));
      setColWrapSize(Math.floor(wrap));
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      letterProps = {};
      drawProps = {};
    };
  }, []);

  const handlePushData = useCallback(async () => {
    const newData = {
      sceneColor: drawProps.sceneColor,
      holeScale: drawProps.holeScale,
      holeScaleId: letterProps.holeScaleId,
      holeColor: drawProps.holeColor,
      cellColor: drawProps.cellColor,
      cellColorId: letterProps.cellColorId,
      cellOpacity: drawProps.cellOpacity,
      dotColor: drawProps.dotColor,
      dotDistortSize: drawProps.dotDistortSize,
      dotDistortId: letterProps.dotDistortId,
      dotScale: drawProps.dotScale,
      dotScaleId: letterProps.dotScaleId,
      textFuture: letterProps.textFuture,
      textPower: letterProps.textPower,
      createTime: `${formatDate(new Date())}`,
    };
    const result = await createPart(newData);
    if (result) push(`${Paths.dot}/${result.id}`);
  }, [createPart, push]);

  const handleStep = useCallback(
    (plus = true) => {
      if (plus === true) {
        // forward
        if (step >= Questions.length) return; // [8(no exist) -> 9(no exist)]
        switch (step) {
          case 0: // [0 -> 1]
          case 3: {
            // [3 -> 4]
            // current: select
            if (disabledBtn) {
              return window.alert('색을 선택해주세요 :-)');
            }
            letterProps[Questions[step].post.key] = color;
            break;
          }
          case 5: {
            // [5 -> 6]
            setToast(true);
            letterProps[Questions[step].post.key] = rangeValue.toString();
            if (step === 5) {
              // [5 -> 6]
              waitForEl('#div-textarea').then(() => {
                if (!letterProps[Questions[step + 1].post.key] || letterProps[Questions[step + 1].post.key] === '') {
                  textareaRef.current.value = '';
                  setDisabledBtn(true);
                } else {
                  textareaRef.current.value = letterProps[Questions[step + 1].post.key];
                  setTextLength(textareaRef.current.value.length);
                  setDisabledBtn(textareaRef.current.value < 5);
                }
              });
            }
            break;
          }
          case 6: // [6 -> 7]
          case 7: {
            // [7 -> 8]
            // current: textarea
            if (disabledBtn) {
              return window.alert('글을 다섯 글자 이상 작성해주세요 :-)');
            }
            if (step === 6) {
              // [6 -> 7]
              letterProps[Questions[step].post.key] = textareaRef.current.value;
              if (!letterProps[Questions[step + 1].post.key] || letterProps[Questions[step + 1].post.key] === '') {
                textareaRef.current.value = '';
              } else {
                waitForEl('#div-textarea').then(() => {
                  textareaRef.current.value = letterProps[Questions[step + 1].post.key];
                  setTextLength(textareaRef.current.value.length);
                  setDisabledBtn(textareaRef.current.value < 5);
                });
              }
            } else {
              // [7 -> 8]
              setPushDataOnce(true);
              letterProps[Questions[step].post.key] = textareaRef.current.value;
              drawProps = onGetBuildData();
              handlePushData();
            }
            break;
          }
          default: {
            // [1 -> 2], [2 -> 3], [4 -> 5]
            // current: range
            letterProps[Questions[step].post.key] = rangeValue.toString();
            break;
          }
        }
        if (step === Questions.length - 1) return;
        setStep(step + 1);
        setDisabledBtn(Questions[step + 1].disableNextBtn);
        onStepChange(parseFloat(step), true, letterProps);
      } else {
        // backward
        if (step <= 0) return; // [0 -> -1(no exist)]
        switch (step) {
          case 7: // [7 -> 6]
          case 6: {
            // [6 -> 5]
            // current: textarea
            letterProps[Questions[step].post.key] = textareaRef.current.value;
            setToast(false);
            if (step === 7) {
              // [7 -> 6]
              waitForEl('#div-textarea').then(() => {
                textareaRef.current.value = letterProps[Questions[step - 1].post.key];
                setTextLength(textareaRef.current.value.length);
                setDisabledBtn(textareaRef.current.value < 5);
              });
            }
            break;
          }
          default: {
            // [6 -> 5], [5 -> 4], [4 -> 3], [3 -> 2], [2 -> 1], [1 -> 0]
            // current: range or select or textarea
            break;
          }
        }
        setStep(step - 1);
        setDisabledBtn(Questions[step - 1].disableNextBtn);
        onStepChange(parseFloat(step), false, letterProps);
      }
      setRangeValue(rangeDefaultVal);
      setColor('');
      setOptStyled({ left: false, right: false });
    },
    [step, onStepChange, disabledBtn, color, onGetBuildData, handlePushData, rangeValue],
  );

  const handleTextLen = useCallback(
    (e) => {
      const currentTextLength = e.target.value.length;
      setTextLength(currentTextLength);
      setText(e.target.value);
      if (e.target.value !== text) onInputChange(parseFloat(step), e.target.value, Questions[step].sendKey);
      if (e.target.value.length < 5) {
        setDisabledBtn(true);
      } else {
        setDisabledBtn(false);
      }
    },
    [step, text, onInputChange],
  );

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === 13 || e.code === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const handleRange = useCallback(
    (value) => {
      setRangeValue(value);
      onInputChange(parseFloat(step), value, Questions[step].sendKey);
      if (value < rangeDefaultVal) {
        setOptStyled({ left: true, right: false });
      } else if (value > rangeDefaultVal) {
        setOptStyled({ left: false, right: true });
      } else {
        setOptStyled({ left: false, right: false });
      }
    },
    [step, onInputChange],
  );

  const handleSelectColor = useCallback(
    (e, hex) => {
      setColor(hex);
      setDisabledBtn(false);
      gsap.fromTo(
        e.target,
        {
          scale: 0.5,
        },
        {
          duration: 0.6,
          scale: 1,
          ease: 'back.out(3)',
        },
      );
      onInputChange(parseFloat(step), hex, Questions[step].sendKey);
    },
    [step, onInputChange],
  );

  const handleCaptchaChange = (value = null) => {
    if (value === null) return;
    setShowRecaptcha(false);
    CaptchaCache.cache();
  };

  return (
    <>
      <div ref={contentRef} className="w-full h-full flex flex-col keep-all">
        <div className="text-lg md:text-2xl lg:text-3xl xl:text-4xl leading-normal flex-grow flex flex-col pb-0">
          <div ref={titleRef} className="pt-2 sm:pt-4 px-4 md:py-6 xl:py-8 md:font-extralight">
            <span className="block">{`Q${step + 1}.`}</span>
            {Questions[step].title.map((p, i) => (
              <div key={i} className={`${p.block ? 'block' : 'inline'} leading-normal`}>
                {p.body === undefined ? (
                  <div className={`${p.block ? 'block' : 'inline'}`}>
                    <span className={`px-1 transition duration-300 ${optStyled.left ? 'border-black border-b-2' : ''}`}>
                      {Questions[step].option[0]}
                    </span>
                    &nbsp;/&nbsp;
                    <span className={`px-1 transition duration-300 ${optStyled.right ? 'border-black border-b-2' : ''}`}>
                      {Questions[step].option[1]}
                    </span>
                  </div>
                ) : (
                  p.body
                )}
                {p.blank && <div className="invisible">&nbsp;</div>}
              </div>
            ))}
          </div>
          {
            /* range */
            Questions[step].type === 'range' && (
              <div className="flex items-center h-1/3 flex-grow">
                <div className="px-4 relative w-full">
                  <input
                    ref={rangeRef}
                    type="range"
                    min={0}
                    max={4}
                    value={rangeValue}
                    onInput={(e) => handleRange(e.target.value)}
                    className="custom-slider z-10"
                  />
                  <div className="flex flex-row w-full justify-between px-4 md:px-7 text-base font-light">
                    <div className="select-none">{Questions[step].option[0]}</div>
                    <div className="select-none">{Questions[step].option[1]}</div>
                  </div>
                  <div className="flex flex-row h-4 px-4 -mx-4 w-full top-3 absolute pointer-events-none -z-10">
                    <div className="w-4 md:w-8 bg-white z-10" />
                    <div className="w-1/4 border-l border-black">&ensp;</div>
                    <div className="w-1/4 border-l border-black">&ensp;</div>
                    <div className="w-1/4 border-l border-black">&ensp;</div>
                    <div className="w-1/4 border-l border-r border-black">&ensp;</div>
                    <div className="w-4 md:w-8 bg-white" />
                  </div>
                  <div className="mx-4 md:mx-8 border-b border-black absolute top-5 -z-10 custom-range-track" />
                </div>
              </div>
            )
          }
          {
            /* select */
            Questions[step].type === 'select' && (
              <div className="overflow-auto leading-0 pt-2 px-4 sm:pt-4" style={{ height: colWrapSize + 'px' }}>
                {Questions[step].option.map((hex) => (
                  <button
                    key={hex}
                    onClick={(e) => handleSelectColor(e, hex)}
                    className={`${color === hex ? 'outline-none border-black border' : ''} w-1/5 inline-block rounded-full`}
                    style={{ backgroundColor: '#' + hex, height: colSize + 'px' }}>
                    <div className="sr-only">{hex}</div>
                  </button>
                ))}
              </div>
            )
          }
          {
            /* text */
            Questions[step].type === 'text' && (
              <div className="px-4 pt-4 pb-10 lg:pb-20 flex-grow">
                <textarea
                  id="div-textarea"
                  ref={textareaRef}
                  className="w-full h-full appearance-none border-black border rounded-xl break-words resize-none p-4 focus:outline-none focus:ring focus:ring-gray-200 lg:font-extralight"
                  placeholder={placeholder}
                  maxLength="100"
                  suppressContentEditableWarning={true}
                  spellCheck={false}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onInput={(e) => handleTextLen(e)}
                  onPaste={(e) => e.preventDefault()}
                />
                <div className="text-right text-base lg:mt-1">{textLength} / 100</div>
              </div>
            )
          }
          <span
            className={`scroll hidden md:flex bg-white text-base left-4 top-4 transition ease-out duration-500 bg-opacity-80`}
            style={{ ...toastStyle }}>
            패턴 위에서 마우스를 움직여보세요.
          </span>
        </div>
        <footer ref={footerRef} className="flex flex-row items-center justify-between mx-4 px-2 py-2 md:py-3 xl:py-4 border-t border-black relative">
          <button
            onClick={() => handleStep(false)}
            className="rounded-full border border-black p-2 w-9 lg:w-11 h-9 lg:h-11 flex items-center justify-center text-2xl font-extralight transition ease-out duration-300 select-none lg:hover:bg-black lg:hover:text-white"
            style={step === 0 ? { visibility: 'hidden' } : {}}>
            ←
          </button>
          <div className="text-lg absolute left-1/2 transform -translate-x-1/2 select-none">
            {step + 1}&ensp;/&ensp;{Questions.length}
          </div>
          <button
            onClick={() => handleStep(true)}
            disabled={pushDataOnce}
            className={`rounded-full border border-black py-2 px-3 h-9 lg:h-11 flex items-center justify-center text-2xl font-extralight bg-black text-white transition ease-out duration-300 select-none ${
              step < Questions.length - 1 && 'w-9 lg:w-auto'
            } ${disabledBtn ? 'opacity-20 cursor-not-allowed' : 'opacity-100 lg:hover:bg-white lg:hover:text-black'}`}>
            {isLastStep ? (
              <>
                {isShowRecaptcha ? (
                  <>
                    <Portal>
                      <div className="absolute right-2 bottom-2 z-50">
                        <ReCAPTCHA sitekey={APP_GOOGLE_RECAPTCHA_KEY_V2} onChange={handleCaptchaChange} hl="ko" />
                      </div>
                    </Portal>
                  </>
                ) : (
                  <>
                    <span className="text-xl pl-1">제출하기&nbsp;</span>
                  </>
                )}
              </>
            ) : (
              <span className="hidden lg:block text-xl pl-1">다음으로&nbsp;</span>
            )}
            →
          </button>
        </footer>
      </div>
    </>
  );
};

export default ContentSection;
