const Icons = ({ type = '', ...child }) => {
  switch (type) {
    case 'download':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" {...child}>
          <path
            fill="#000"
            fillRule="evenodd"
            d="M8.1.9H6.9v8L3.7 5.8l-.8.8 4.6 4.8 4.6-4.8-.8-.8L8 9V.9Zm6 13.2v-1.2H.9v1.2h13.2Z"
            clipRule="evenodd"
          />
        </svg>
      );
    case 'kakao':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" {...child}>
          <path
            fill="#000"
            fillRule="evenodd"
            d="M.4 7C.4 3.2 4.1.4 8.4.4c4.4 0 8.2 2.8 8.2 6.6s-3.7 6.6-8 6.6a9 9 0 0 1-1.3 0 203.8 203.8 0 0 0-2.6 1.7l-.3.2h-.6a.8.8 0 0 1-.5-.5v-.5l.1-.5.4-1.6C1.8 11.2.4 9.3.4 7Zm4.3 8.3ZM8.5 1.6c-4 0-6.9 2.5-6.9 5.4 0 1.9 1.2 3.6 3.2 4.6l.5.2-.2.5-.4 1.5a153.1 153.1 0 0 1 2-1.4l.3-.1h.2l1.3.1c4 0 6.9-2.5 6.9-5.4 0-2.9-3-5.4-7-5.4Z"
            clipRule="evenodd"
          />
        </svg>
      );
    case 'print':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" fill="none" {...child}>
          <path fill="#000" fillRule="evenodd" d="M13.6 0H2.4v11.8H0V13h16v-1.2h-2.4V0Zm-1.2 11.8V1.2H3.6v10.6h8.8Z" clipRule="evenodd" />
        </svg>
      );
    case 'link':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" {...child}>
          <path
            fill="#000"
            fillRule="evenodd"
            d="M4.06 5.915a4.587 4.587 0 0 0 1.344 3.409 4.587 4.587 0 0 0 3.409 1.345c-.101.137-.214.268-.338.392l-1.414 1.414a3.4 3.4 0 1 1-4.808-4.808l1.414-1.414c.124-.124.255-.237.393-.338Zm.318-1.537c-.568.225-1.1.567-1.56 1.026L1.404 6.818a4.6 4.6 0 0 0 6.506 6.506l1.414-1.414c.46-.46.801-.992 1.026-1.56a4.585 4.585 0 0 0 1.56-1.026l1.414-1.414a4.6 4.6 0 0 0-6.506-6.506L5.404 2.818c-.46.46-.801.992-1.026 1.56Zm.959.959a3.397 3.397 0 0 0 .916 3.138 3.397 3.397 0 0 0 3.138.916 3.397 3.397 0 0 0-.916-3.138 3.397 3.397 0 0 0-3.138-.916Zm5.332 3.476a4.588 4.588 0 0 0-1.345-3.409A4.587 4.587 0 0 0 5.915 4.06c.101-.138.214-.269.338-.393l1.414-1.414a3.4 3.4 0 1 1 4.808 4.808l-1.414 1.414a3.431 3.431 0 0 1-.393.338Z"
            clipRule="evenodd"
          />
        </svg>
      );
    case 'dencity-low':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...child}>
          <circle cx="10" cy="10" r="7" stroke="black" />
        </svg>
      );
    case 'dencity-high':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...child}>
          <circle cx="9.99999" cy="5.69231" r="3.69231" stroke="black" />
          <circle cx="5.07695" cy="14.3078" r="3.69231" stroke="black" />
          <circle cx="14.9231" cy="14.3078" r="3.69231" stroke="black" />
        </svg>
      );
    case 'arrow-down':
      return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...child}>
          <path d="M6.5 0V13M6.5 13L12 7.15M6.5 13L1 7.15" stroke="black" />
        </svg>
      );
    case 'rotate':
      return (
        <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...child}>
          <path d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8M15 8L17.5 2.5M15 8L10 5" stroke="black" />
        </svg>
      );
    case 'email':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" fill="none" {...child}>
          <path
            fill="#000"
            fillRule="evenodd"
            d="M16.6.4H.4v11.2h16.2V.4Zm-15 1.98v8.02h13.8V2.38L8.5 8.82 1.6 2.38Zm12.878-.78H2.522L8.5 7.18l5.978-5.58Z"
            clipRule="evenodd"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default Icons;
