import React, { useEffect, useContext, createContext, useState, useCallback, useMemo } from 'react';
import { partsApi } from '@/services/firebase';
import { PARTS_DATA } from '@/constants/parts';

const partsContext = createContext<any>(undefined);

type GerPartsFunc = (perPage?: number) => Promise<void>;

type PartsStore = {
  partsList: PartDataList | null;
  partsListAll: PartDataList;
  getPart: (id: string) => Promise<PartData | null>;
  getParts: GerPartsFunc;
  createPart: (data: PartDataForm) => Promise<PartData | null>;
  deletePart: (id: string) => Promise<boolean>;
  isInit: boolean;
  hasMore: boolean;
  hasFetched: boolean;
  isWatching: boolean; // 데이터베이스 실시간 모니터링 여부
  setWatching: (isWatching: boolean) => void;
};

export function useParts(): PartsStore {
  return useContext(partsContext);
}

export const PerPage = 5;
const initPage = 30;
let isFirstLoad = true;

let feching = false;

function useProvideParts(): PartsStore {
  const [partsList, setPartsList] = useState<PartDataList>([]);
  const [partFirst, setPartFirst] = useState<PartData | null>(null);
  const [partLast, setPartLast] = useState<PartData | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [hasFetched, setFetched] = useState<boolean>(false);
  const [isInit, setInit] = useState<boolean>(false);
  const [isWatching, setWatching] = useState<boolean>(false);

  const partsListAll = useMemo(
    () => (partsList.length > 0 ? [...partsList, ...PARTS_DATA] : PARTS_DATA.length > 0 ? [...PARTS_DATA] : []),
    [partsList],
  );

  const getParts: GerPartsFunc = useCallback(
    async (perPage?: number) => {
      if (!hasMore || feching) return;

      setFetched(true);
      feching = true;

      let dataNum = PerPage;
      if (typeof perPage === 'number' && perPage >= PerPage) {
        dataNum = perPage;
      } else if (isFirstLoad) {
        isFirstLoad = false;
        dataNum = initPage;
      }

      const data = await partsApi.gets({ after: partLast ?? undefined, size: dataNum });
      setFetched(false);
      feching = false;

      if (isInit === false) setInit(true);
      const exist = !!data && data?.length > 0;
      const hasMoreNext = !exist || data?.length !== dataNum;
      if (hasMore !== exist || hasMoreNext) setHasMore(false);

      if (exist) {
        setPartsList((list) => [...list, ...data]);
        if (!partFirst) setPartFirst(data[0]);
        setPartLast(data[data.length - 1]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore, partLast, isInit],
  );

  /**
   * 데이터베이스 실시간 모니터링
   */
  useEffect(() => {
    if (!partFirst || !isWatching) return;
    if (!isWatching) return;

    partsApi.onCreate(partFirst, (newList, unsubscribe) => {
      if (newList.length === 0) return;

      setPartFirst(newList[0]);
      setPartsList((prevList) => [...newList, ...prevList]);
      unsubscribe?.();
    });
  }, [partFirst, isWatching]);

  useEffect(() => {
    getParts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPart = useCallback(async (id: string) => {
    try {
      return await partsApi.get(id);
    } catch (error) {
      console.log(error);
      return null;
    }
  }, []);

  const createPart = useCallback(
    async (data: PartDataForm) => {
      try {
        const newPart = await partsApi.post(data);
        if (!isWatching && newPart) setPartsList((list) => [newPart, ...list]);
        return newPart;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    [isWatching],
  );

  const deletePart = useCallback(async (id: string) => {
    try {
      const isDeleted = await partsApi.delete(id);
      setPartsList((list) => list?.filter((o) => o.id !== id));
      return isDeleted;
    } catch (error) {
      console.error(error);
      return false;
    }
  }, []);

  return {
    partsList,
    partsListAll,

    getParts,
    getPart,
    createPart,
    deletePart,

    isInit,
    hasMore,
    hasFetched,

    isWatching,
    setWatching,
  };
}

export const ProvideParts: React.FC = ({ children }) => {
  const parts = useProvideParts();
  return <partsContext.Provider value={parts}>{children}</partsContext.Provider>;
};
