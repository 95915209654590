export const colorOpt = [
  '1C2A49', '243B62', '395582', '7486A4', 'C1CAD8',
  '2D3F4D', '4A6578', '6F889B', 'A8B9C6', 'C8D5DF',
  '254573', '3B72A5', '4E8FC2', '619ECA', '99C5E0',
  '274582', '3361A2', '4383C6', '7EB6E1', 'BEDCF2',
  '236396', '3096CD', '56AFD7', '87CBE3', 'C1E6F0',
  'B4E5F8', '58C4E8', '2E9EC3', '1C7790', '0D5564',
  'B4DEDC', '56B3AE', '26938B', '20766D', '194A42',
  'BDDDDE', '75B2B2', '5C908E', '4F7371', '3B4746',
  'D7DBDA', 'BBC2C4', '85979C', '62757B', '32383A',
  'DFDBD8', 'CEC8C4', 'B0AAA7', '716C68', '514C49',
  'FF6800', 'FF9B00', 'FFC61A', 'FFDD7E', 'FFEAB1',
  'DF5825', 'EA6E28', 'FD982F', 'FFB65D', 'FFE0B7',
  '531927', '753239', '93464A', 'BE7C7C', 'F8C1C0',
  '674C5A', '91566F', 'BF6282', 'D57398', 'E4A5C2',
  '9A272B', 'B8333B', 'D55359', 'E89799', 'FACBD1',
  'FBBCD0', 'F76592', 'F12663', 'CA1E5B', '901150',
  'D9C7CF', '9E7F8E', '744A60', '573649', '361F31',
  'C7B6CE', 'A386AE', '57006E', '3D005B', '2E004E',
  'C4C7E7', '9DA3D7', '5A64BB', '3E49AF', '191B79',
  'EDEDED', 'C0C0C0', '7E7E7E', '414141', '000000',
  //'', '', '', '', '',
]

const shuffledColorOpt = colorOpt
  .map(value => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value)

const Questions = [
  {
    type: 'select', // select, range, text
    title: [
      {
        body: '환기미술관에 온 당신,',
        block: true
      },
      {
        body: '오늘의 기분을 색으로 표현한다면?',
        block: true
      }
    ],
    option: colorOpt,
    post: {
      key: 'sceneColor',
      value: 'color'
    },
    disableNextBtn: true,
    sendKey: 'scene' //0
  },
  {
    type: 'range',
    option: ['혼자', '누군가와 함께'],
    title: [
      {
        body: '나는 미술관에서 ',
        block: false
      },
      {
        body: undefined,
        block: false
      },
      {
        body: ' 감상하는 것이 좋다.',
        block: true
      }
    ],
    post: {
      value: 'color',
      key: 'holeScaleId'
    },
    disableNextBtn: false,
    sendKey: 'hole' //1
  },
  {
    type: 'range',
    option: ['적어진다', '많아진다'],
    title: [
      {
        body: '미술관에 오면 생각이 ',
        block: false
      },
      {
        body: undefined,
        block: false
      },
      {
        body: '.',
        block: false
      },
      {
        body: '',
        block: true,
        blank: ['', '']
      }
    ],
    post: {
      value: 'color',
      key: 'cellColorId'
    },
    disableNextBtn: false,
    sendKey: 'cell' //2
  },
  {
    type: 'select',
    title: [
      {
        body: '오늘의 전시를 보고 떠오른 감정을',
        block: true
      },
      {
        body: '색으로 표현한다면?',
        block: true
      }
    ],
    option: shuffledColorOpt,
    post: {
      value: 'color',
      key: 'dotColor'
    },
    disableNextBtn: true,
    sendKey: 'dot' //3
  },
  {
    type: 'range',
    option: ['한 작품을 오래', '여러 작품을 빠르게'],
    title: [
      {
        body: '전시를 볼 때는 ',
        block: true
      },
      {
        body: undefined,
        block: true
      },
      {
        body: ' 감상하는 것이 좋다.',
        block: true
      }
    ],
    post: {
      value: 'color',
      key: 'dotDistortId'
    },
    disableNextBtn: false,
    sendKey: 'dot' //4
  },
  {
    type: 'range',
    option: ['멀리 떨어져', '가까이'],
    title: [
      {
        body: '작품을 볼 때는 ',
        block: false
      },
      {
        body: undefined,
        block: false
      },
      {
        body: ' 감상하는 것이 좋다.',
        block: true,
      },
      {
        body: '',
        block: true,
        blank: ['']
      }
    ],
    post: {
      value: 'color',
      key: 'dotScaleId'
    },
    disableNextBtn: false,
    sendKey: 'dot' //5
  },
  {
    type: 'text',
    title: [
      {
        body: '미래의 미술관에 남기고 싶은 이야기를',
        block: true
      },
      {
        body: '자유롭게 작성해주세요.',
        block: true
      }
    ],
    post: {
      value: 'textareaVal',
      key: 'textFuture'
    },
    disableNextBtn: true,
    sendKey: 'dot' //6
  },
  {
    type: 'text',
    title: [
      {
        body: '당신이 생각하는 “박물관의 힘”이란?',
        block: true
      },
      {
        body: '',
        block: true,
        blank: ['']
      }
    ],
    post: {
      value: 'textareaVal',
      key: 'textPower'
    },
    disableNextBtn: true,
    sendKey: 'dot' //7
  }
]

export default Questions