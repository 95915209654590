import { useEffect, useState, useMemo, useCallback } from 'react';
import { useParts } from '@/contexts/parts';
import { Link } from 'react-router-dom';
import { Paths } from '@/utils/constants';
import Loader from '@/components/custom/Loader';
import MainHeader from '@/components/custom/MainHeader';
import useGallery from '@/interactive/useGallery';
import EventEmitter from '@/utils/EventEmitter';
import { LOAD_MORE_PARTS } from '@/utils/constants';

let toastTimeOut;
let scrollOnce = true;

const Main = () => {
  const { onLoad, onUnload, onUpdateUserData } = useGallery();
  const { isInit, partsList, getParts } = useParts();
  const [showScroll, setShowScroll] = useState(false);
  const toastStyle = useMemo(() => ({ transform: showScroll ? 'translateX(0)' : 'translateX(calc(-100% - 2rem))' }), [showScroll]);

  useEffect(() => {
    EventEmitter.off(LOAD_MORE_PARTS);
    EventEmitter.on(LOAD_MORE_PARTS, () => getParts());
    return () => {
      EventEmitter.removeListener(LOAD_MORE_PARTS);
    };
  }, [getParts]);

  useEffect(() => {
    if (isInit) {
      onLoad();
      toastTimeOut = setTimeout(() => {
        setShowScroll(true);
        window.addEventListener('wheel', hideScroll);
        window.addEventListener('touchmove', hideScroll);
      }, 500);
      return () => {
        onUnload();
        window.removeEventListener('wheel', hideScroll);
        window.removeEventListener('touchmove', hideScroll);
        scrollOnce = true;
        if (toastTimeOut) clearTimeout(toastTimeOut);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInit]);

  useEffect(() => {
    onUpdateUserData(partsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsList]);

  const hideScroll = () => {
    if (scrollOnce) {
      setShowScroll(false);
      scrollOnce = false;
    }
  };

  return (
    <>
      {isInit && (
        <div className="fixed z-30 right-4 top-4 lg:right-8 lg:top-8">
          <Link to={Paths.draw} className="main-join w-28 h-28 lg:w-32 lg:h-32 lg:text-xl">
            참여하기 →
          </Link>
        </div>
      )}
      <div className="w-full h-fill flex flex-col overflow-hidden fixed">
        <div id="div-three" className="w-full flex-grow relative">
          <canvas id="cvs-three" />
          {isInit && (
            <span className="scroll flex left-4 bottom-4 lg:left-6 transition duration-500 bg-white bg-opacity-80" style={{ ...toastStyle }}>
              점들을 터치하고 스크롤을 해보세요↓
            </span>
          )}
        </div>
        <MainHeader />
      </div>
      <Loader />
    </>
  );
};

export default Main;
