export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = process.env.NODE_ENV === 'production';

export const APP_NAME = 'Whanki';

export const APP_KAKAO_KEY = '7a29c18a48f53e663b11156ffb44bdba'; // whanki
export const APP_GOOGLE_RECAPTCHA_KEY_V2 = '6Lf4-G8fAAAAAAxMVmTO0S5RrBxZbFuJSjNeo8wk'; // whanki recaptcha_v2

export const Paths = {
  main: '/',
  admin: '/admin',
  gallery: '/gallery',
  dot: '/dot',
  draw: '/draw',
  about: '/about',
  login: '/login',
};

export const MENU_LIST = [
  {
    name: '',
    children: [],
  },
];

export const LOAD_MORE_PARTS = 'LOAD_MORE_PARTS';
