import excel from 'exceljs';
import FileDownload from 'js-file-download';
import { partsApi } from './firebase';
import { formatDate, now } from '@/utils';
import { APP_NAME } from '@/utils/constants';

export async function downloadParts() {
  try {
    const result = await partsApi.getAll();
    if (!result) return window.alert('데이터가 없습니다.');

    const workbook = new excel.Workbook();
    const sheet = workbook.addWorksheet('Sheet1');

    sheet.columns = [
      { header: '생성 날짜', key: 'createTime' },
      { header: '1번 질문', key: 'sceneColor' },
      { header: '2번 질문', key: 'holeScaleId' },
      { header: '3번 질문', key: 'cellColorId' },
      { header: '4번 질문', key: 'dotColor' },
      { header: '5번 질문', key: 'dotDistortId' },
      { header: '6번 질문', key: 'dotScaleId' },
      { header: '7번 질문', key: 'textFuture' },
      { header: '8번 질문', key: 'textPower' },
    ];

    result.forEach((item) => {
      sheet.addRow([
        formatDate(item.createTime),
        item?.sceneColor ?? '',
        item?.holeScaleId ?? '',
        item?.cellColorId ?? '',
        item?.dotColor ?? '',
        item?.dotDistortId ?? '',
        item?.dotScaleId ?? '',
        item?.textFuture ?? '',
        item?.textPower ?? '',
      ]);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const blob = new Blob([buffer], { type: fileType });
    FileDownload(blob, `${APP_NAME}-parts-${formatDate(now(), 'YYYY-MM-DD')}.xlsx`);
  } catch (error) {
    console.error(error);
    window.alert('오류가 발생했습니다.');
  }
}
