import { BrowserRouter as Router, Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import Bootstrap from '@/page/Bootstrap';
import Main from '@/page/Main';
import Admin from '@/page/Admin';
import Login from '@/page/Login';
import Dot from '@/page/Dot';
import Gallery from '@/page/Gallery';
import Draw from '@/page/Draw';
import About from '@/page/About'
import Spinner from '@/components/common/Spinner';
import { useAuth, ProvideAuth } from '@/contexts/auth';
import { ProvideParts } from '@/contexts/parts';
import { ProvideUI } from '@/contexts/ui';
import { Paths } from '@/utils/constants';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Paths.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const App: React.FC = () => (
  <ProvideUI>
    <ProvideAuth>
      <ProvideParts>
        <Spinner />
        <Router>
          <Bootstrap>
            <Switch>
              <Route path={Paths.login}>
                <Login />
              </Route>
              <Route exact path={Paths.main}>
                <Main />
              </Route>
              <Route path={`${Paths.dot}/:id`}>
                <Dot />
              </Route>
              <Route path={Paths.draw}>
                <Draw />
              </Route>
              <Route path={Paths.about}>
                <About />
              </Route>
              <PrivateRoute path={Paths.gallery}>
                <Gallery />
              </PrivateRoute>
              <PrivateRoute path={Paths.admin}>
                <Admin />
              </PrivateRoute>
              <Route path="*">
                <Redirect to={Paths.login} />
              </Route>
            </Switch>
          </Bootstrap>
        </Router>
      </ProvideParts>
    </ProvideAuth>
  </ProvideUI>
);

export default App;
