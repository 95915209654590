import * as THREE from 'three';
import { assetIndex, threeOpt } from './mainConfig';
import {
  fillScene,
  createHole,
  expandHole,
  changeHoleScale,
  createCell,
  expandCell,
  changeCellColor,
  createDot,
  expandDot,
  distortDot,
  changeDotColor,
  changeDotScale,
  testSceneHasFilled,
  toggleText,
  wiggleObj,
  removeObj,
  updateText,
  createSpace,
  getIntersectedLetter,
  getMousePosVec,
  flipLetter,
  checkLetterExist,
  loadFontByJSON,
} from './mainUtils';
import { checkMobile, createEvtKey, createEvtListener, hexToRgb, mapRange, rgbToHex } from './commonUtils';

const domEl = {};
const mouseEl = {};
const threeEl = {};
const asset = {};

const useMain = () => {
  const onInit = () => {
    /* initialize paramters */
    // dom
    domEl.divCtrl = document.getElementById('div-ctrl');
    domEl.divThree = document.getElementById('div-three');
    domEl.cvsThree = document.getElementById('cvs-three');
    domEl.divCtrl.classList.remove('opacity-50');
    // mouse
    mouseEl.evtList = [];
    mouseEl.evtKey = { click: 'click' };
    mouseEl.isMobile = checkMobile();
    createEvtKey(mouseEl);
    mouseEl.isPressed = mouseEl.isMobile ? false : true;
    // three
    threeEl.scene = {};
    threeEl.renderer = {};
    threeEl.camera = {};
    threeEl.raycaster = {};
    threeEl.hole = {};
    threeEl.cell = {};
    threeEl.dot = {};
    threeEl.build = {};
    threeEl.text = {};
    threeEl.font = { obj: asset.font };
    threeEl.scene.background = {};
    threeEl.scene.background.color = {
      blank: new THREE.Color(threeOpt.scene.background.color.blank),
    };
    const scaleObjKeyArr = ['hole', 'dot'];
    scaleObjKeyArr.forEach((scaleObjKey) => {
      threeEl[scaleObjKey].scale = JSON.parse(JSON.stringify(threeOpt[scaleObjKey].scale));
      threeEl[scaleObjKey].scale.default = threeOpt[scaleObjKey].scale.default = mapRange(
        2,
        0,
        4,
        threeEl[scaleObjKey].scale.min,
        threeEl[scaleObjKey].scale.max,
      );
    });
    threeEl.dot.distortSize = JSON.parse(JSON.stringify(threeOpt.dot.distortSize));
    threeEl.dot.distortSize.default = threeOpt.dot.distortSize.default = mapRange(2, 0, 4, threeEl.dot.distortSize.min, threeEl.dot.distortSize.max);
    /* initialize three */
    // create three.scene
    threeEl.scene.obj = new THREE.Scene();
    threeEl.scene.obj.background = threeEl.scene.background.color.blank;
    threeEl.scene.obj.colorBlank = threeEl.scene.background.color.blank.clone();
    threeEl.scene.obj.elDivCtrl = domEl.divCtrl;
    threeEl.scene.obj.font = threeEl.font.obj;
    // create three.renderer
    onResize();
    threeEl.renderer.obj = new THREE.WebGLRenderer({
      powerPreference: 'high-performance',
      canvas: domEl.cvsThree,
    });
    threeEl.renderer.obj.setPixelRatio(threeEl.renderer.dpr);
    threeEl.renderer.obj.setSize(threeEl.renderer.size.width, threeEl.renderer.size.height);
    threeEl.renderer.obj.size = threeEl.renderer.size;
    threeEl.renderer.obj.dpr = threeEl.renderer.dpr;
    // create three.camera
    threeEl.camera.obj = new THREE.OrthographicCamera(
      threeEl.scene.size.width / -2,
      threeEl.scene.size.width / 2,
      threeEl.scene.size.height / 2,
      threeEl.scene.size.height / -2,
      1,
      threeOpt.scene.width * 2,
    );
    threeEl.camera.obj.position.set(0, 0, threeOpt.scene.width);
    threeEl.camera.obj.lookAt(0, 0, 0);
    threeEl.camera.obj.updateProjectionMatrix();
    // create three.raycaster
    threeEl.raycaster.obj = new THREE.Raycaster();
    /* initialize objects */
    const groupKeyArr = ['build', 'text'];
    groupKeyArr.forEach((groupKey) => {
      threeEl[groupKey].group = new THREE.Group();
      threeEl[groupKey].group.name = groupKey;
      threeEl.scene.obj.add(threeEl[groupKey].group);
    });
    threeEl.text.group.visible = false;
    const textKeyArr = threeOpt.text.subGroupNameArr;
    textKeyArr.forEach((textKey) => {
      const subGroup = new THREE.Group();
      subGroup.name = textKey;
      subGroup.patternArr = [];
      subGroup.letterArr = [];
      subGroup.str = '';
      subGroup.placeholder = threeOpt.text.placeholder;
      threeEl.text.group.add(subGroup);
      threeEl.text.group[textKey] = subGroup;
    });
    const gap = threeEl.text.group.gap;
    gap.patternArr.push(false);
    gap.letterArr.push(' ');
    gap.str = ' ';
    gap.add(createSpace());
    /* initialize events */
    mouseEl.evtList.push(createEvtListener('resize', onResize));
    mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.move, onMoveOnCvs));
    mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.down, onDownToCvs));
    mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.up, onUpFromCvs));
    mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.out, onOutFromCvs));
    mouseEl.evtList.forEach((evt) => {
      evt.targetDom.addEventListener(evt.evtKey, evt.callback, false);
    });
    /* start animation */
    threeEl.renderer.obj.setAnimationLoop(onAnimate);
  };

  const onAnimate = () => {
    if (threeEl.scene.obj.wiggleable) {
      if (!threeEl.cell.obj || threeEl.cell.obj.colorId !== 3) {
        wiggleObj(threeEl.hole.obj, threeOpt.scene.timeDelta);
      }
      if (threeEl.cell.obj?.colorId !== 3) wiggleObj(threeEl.cell.obj, threeOpt.scene.timeDelta);
      wiggleObj(threeEl.dot.obj, threeOpt.scene.timeDelta);
    }

    threeEl.renderer.obj.render(threeEl.scene.obj, threeEl.camera.obj);
  };

  const onInputChange = (step, value, targetObjKey) => {
    const targetObj = threeEl[targetObjKey]?.obj;
    if (!targetObj) return;

    switch (step) {
      case 0: {
        const rgbArr = hexToRgb(value);
        const color = new THREE.Color(rgbArr[0] / 255, rgbArr[1] / 255, rgbArr[2] / 255);
        fillScene(threeEl.scene.obj, color, true, true);
        break;
      }
      case 1: {
        const inputVal = parseFloat(value);
        const scale = mapRange(inputVal, 0, 4, threeEl.hole.scale.min, threeEl.hole.scale.max);
        changeHoleScale(threeEl.hole.obj, scale, true, true);
        break;
      }
      case 2: {
        const colorId = parseFloat(value);
        changeCellColor(threeEl.cell.obj, colorId, true, true);
        break;
      }
      case 3: {
        const rgbArr = hexToRgb(value);
        const color = new THREE.Color(rgbArr[0] / 255, rgbArr[1] / 255, rgbArr[2] / 255);
        if (!threeEl.dot.obj.visible) {
          // first time (no dot)
          threeEl.dot.obj.material.color = color;
          expandDot(threeEl.dot.obj, true, true);
        } else {
          // already dot is displayed
          changeDotColor(threeEl.dot.obj, color, true, true);
        }

        break;
      }
      case 4: {
        const inputVal = parseFloat(value);
        const distortSize = mapRange(inputVal, 0, 4, threeEl.dot.distortSize.min, threeEl.dot.distortSize.max);
        distortDot(threeEl.dot.obj, distortSize, true, true);
        break;
      }
      case 5: {
        const inputVal = parseFloat(value);
        const scale = mapRange(inputVal, 0, 4, threeEl.dot.scale.min, threeEl.dot.scale.max);
        changeDotScale(threeEl.dot.obj, scale, true, true);
        break;
      }
      case 6: {
        if (!threeEl.text.group.visible) return;
        const textStr = value === '' || value === undefined ? threeOpt.text.placeholder : value;
        const textSubGroup = threeEl.text.group[threeOpt.text.subGroupNameArr[0]];
        updateText(threeEl.text.group, textSubGroup, textStr);
        break;
      }
      case 7: {
        if (!threeEl.text.group.visible) return;
        const textStr = value;
        const textSubGroup = threeEl.text.group[threeOpt.text.subGroupNameArr[2]];
        updateText(threeEl.text.group, textSubGroup, textStr);
        break;
      }
      default: {
        return;
      }
    }
  };

  const onStepChange = (step, dir, letterProps) => {
    if (domEl.divCtrl.classList.contains('pointer-events-none')) return;
    const oldPageNum = step;
    const newPageNum = dir ? oldPageNum + 1 : oldPageNum - 1;

    switch (newPageNum) {
      case 0: {
        const hole = threeEl.build.group.getObjectByName('hole');
        removeObj(hole, true);

        fillScene(threeEl.scene.obj, threeEl.scene.background.color.blank, true, true);
        break;
      }
      case 1: {
        const cell = threeEl.build.group.getObjectByName('cell');
        if (cell) cell.colorId = 2;
        removeObj(cell, true);

        createHole(threeEl.scene.obj, true).then((hole) => {
          const sceneHasColor = testSceneHasFilled(threeEl.scene.obj);
          const sceneColor = threeEl.cell.obj?.material?.color?.clone();
          if (!sceneHasColor && sceneColor) {
            fillScene(threeEl.scene.obj, sceneColor, true, true);
          }

          threeEl.hole.obj = hole;
          threeEl.build.group.add(threeEl.hole.obj);
          threeEl.hole.obj.scaleOpt = JSON.parse(JSON.stringify(threeEl.hole.scale));
          expandHole(threeEl.hole.obj, true, true);
        });
        break;
      }
      case 2: {
        const dot = threeEl.build.group.getObjectByName('dot');
        removeObj(dot, true);

        const sceneHasColor = testSceneHasFilled(threeEl.scene.obj);
        const sceneColor = threeEl.cell.obj?.material?.color?.clone();
        if (!sceneHasColor) {
          const cell = threeEl.build.group.getObjectByName('cell');
          removeObj(cell, true, threeOpt.animation.brushScene.time);
          fillScene(threeEl.scene.obj, sceneColor, true, true).then(() => {
            const colorId = 2;
            createCell(threeEl.scene.obj, colorId, true).then((cell) => {
              threeEl.cell.obj = cell;
              threeEl.build.group.add(threeEl.cell.obj);
              expandCell(threeEl.cell.obj, true, true);
            });
          });
        } else {
          const colorId = 2;
          createCell(threeEl.scene.obj, colorId, true).then((cell) => {
            threeEl.cell.obj = cell;
            threeEl.build.group.add(threeEl.cell.obj);
            expandCell(threeEl.cell.obj, true, true);
          });
        }
        break;
      }
      case 3: {
        const dot = threeEl.build.group.getObjectByName('dot');
        if (dot) {
          removeObj(dot, threeOpt.animation.removeObj.time).then(() => {
            createDot(threeEl.scene.obj, threeEl.scene.background.color.blank.clone(), true).then((dot) => {
              threeEl.dot.obj = dot;
              threeEl.build.group.add(threeEl.dot.obj);
              threeEl.dot.obj.scaleOpt = JSON.parse(JSON.stringify(threeEl.dot.scale));
              threeEl.dot.obj.distortSizeOpt = JSON.parse(JSON.stringify(threeEl.dot.distortSize));
            });
          });
        } else {
          createDot(threeEl.scene.obj, threeEl.scene.background.color.blank.clone(), true).then((dot) => {
            threeEl.dot.obj = dot;
            threeEl.build.group.add(threeEl.dot.obj);
            threeEl.dot.obj.scaleOpt = JSON.parse(JSON.stringify(threeEl.dot.scale));
            threeEl.dot.obj.distortSizeOpt = JSON.parse(JSON.stringify(threeEl.dot.distortSize));
          });
        }
        break;
      }
      case 4: {
        const distortSize = threeEl.dot.distortSize.default;
        distortDot(threeEl.dot.obj, distortSize, true, true);
        changeDotScale(threeEl.dot.obj, threeEl.dot.scale.default, false, true);
        break;
      }
      case 5: {
        if (oldPageNum === 6) toggleText(threeEl.scene.obj, false);
        changeDotScale(threeEl.dot.obj, threeEl.dot.scale.default, true, true);
        break;
      }
      case 6: {
        if (oldPageNum === 5) {
          toggleText(threeEl.scene.obj, true);
        } else {
          const letterExist = checkLetterExist(letterProps.textFuture);
          const textStr = letterExist ? letterProps.textFuture : threeOpt.text.placeholder;
          const textSubGroup = threeEl.text.group[threeOpt.text.subGroupNameArr[0]];
          updateText(threeEl.text.group, textSubGroup, textStr);
        }
        break;
      }
      case 7: {
        if (oldPageNum === 6) {
          const letterExist = checkLetterExist(letterProps.textPower);
          const textStr = letterExist ? letterProps.textPower : '';
          const textSubGroup = threeEl.text.group[threeOpt.text.subGroupNameArr[2]];
          updateText(threeEl.text.group, textSubGroup, textStr);
        }
        break;
      }
      default: {
        return;
      }
    }
  };

  const onGetBuildData = () => {
    const textBuildSpec = threeEl.text.group.buildSpec;
    const sceneColor = rgbToHex(threeEl.scene.obj.background.r * 255, threeEl.scene.obj.background.g * 255, threeEl.scene.obj.background.b * 255);
    const holeColor = rgbToHex(textBuildSpec.hole.color.r * 255, textBuildSpec.hole.color.g * 255, textBuildSpec.hole.color.b * 255);
    const cellColor = rgbToHex(textBuildSpec.cell.color.r * 255, textBuildSpec.cell.color.g * 255, textBuildSpec.cell.color.b * 255);
    const dotColor = rgbToHex(textBuildSpec.dot.color.r * 255, textBuildSpec.dot.color.g * 255, textBuildSpec.dot.color.b * 255);
    return {
      sceneColor: sceneColor,
      holeScale: textBuildSpec.hole.scale.toString(),
      holeColor: holeColor,
      cellColor: cellColor,
      cellOpacity: textBuildSpec.cell.opacity.toString(),
      dotColor: dotColor,
      dotDistortSize: textBuildSpec.dot.distortSize.toString(),
      dotScale: textBuildSpec.dot.scale.toString(),
    };
  };

  const onMoveOnCvs = (evt) => {
    if (!threeEl.text.group.visible) return;
    if (!mouseEl.isPressed) return;

    const mousePos = getMousePosVec(evt);

    let letter = getIntersectedLetter(mousePos, threeEl.renderer.obj, threeEl.camera.obj, threeEl.raycaster.obj, threeEl.text.group.children);
    if (!letter) return;
    flipLetter(letter);
  };

  const onDownToCvs = () => {
    if (!threeEl.text.group.visible) return;
    if (mouseEl.isMobile) mouseEl.isPressed = true;
  };

  const onUpFromCvs = () => {
    if (!threeEl.text.group.visible) return;
    onOutFromCvs();
  };

  const onOutFromCvs = () => {
    if (!threeEl.text.group.visible) return;
    if (mouseEl.isMobile) mouseEl.isPressed = false;
  };

  const onLoad = () => {
    const isLeftoverSpinner = false;
    loadFontByJSON(isLeftoverSpinner).then((font) => {
      asset.font = font;
      onInit();
    });
  };

  const onUnload = () => {
    /* clear event listeners */
    // mouse
    if (mouseEl.evtList) {
      mouseEl.evtList.forEach((evt) => {
        evt.targetDom?.removeEventListener(evt.evtKey, evt.callback, false);
      });
    }
    mouseEl.evtList = [];

    // three
    threeEl.renderer?.obj?.setAnimationLoop(null);
  };

  const onResize = () => {
    const divThreeBBox = domEl.divThree.getBoundingClientRect();
    threeEl.renderer.dpr = window.devicePixelRatio;
    threeEl.renderer.size = {
      width: divThreeBBox.width,
      height: divThreeBBox.height,
    };
    threeEl.renderer.size.aspect = threeEl.renderer.size.width / threeEl.renderer.size.height;

    threeEl.scene.size = {
      aspect: threeEl.renderer.size.width / threeEl.renderer.size.height,
    };
    threeEl.scene.size.width = threeOpt.scene.width;
    threeEl.scene.size.height = threeOpt.scene.width / threeEl.scene.size.aspect;
    threeEl.scene.size.diagonal = Math.sqrt(Math.pow(threeEl.scene.size.width, 2) + Math.pow(threeEl.scene.size.height, 2));

    if (threeEl.scene.obj) {
      threeEl.scene.obj.size = threeEl.scene.size;
    }

    if (threeEl.renderer.obj) {
      threeEl.renderer.obj.setPixelRatio(threeEl.renderer.dpr);
      threeEl.renderer.obj.setSize(threeEl.renderer.size.width, threeEl.renderer.size.height);
    }

    if (threeEl.camera.obj) {
      threeEl.camera.obj.left = threeEl.scene.size.width / -2;
      threeEl.camera.obj.right = threeEl.scene.size.width / 2;
      threeEl.camera.obj.top = threeEl.scene.size.height / 2;
      threeEl.camera.obj.bottom = threeEl.scene.size.height / -2;
      threeEl.camera.obj.position.set(0, 0, threeOpt.scene.width);
      threeEl.camera.obj.updateProjectionMatrix();
    }
  };

  return {
    onLoad,
    onInit,
    onUnload,
    onInputChange,
    onStepChange,
    onGetBuildData,
  };
};

export default useMain;
