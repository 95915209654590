import * as THREE from 'three';
import { assetIndex, threeOpt } from './mainConfig';
import {
  fillScene,
  refineUserData,
  arrayLetters,
  calcArrayFitSceneSize,
  renderScene,
  runSaveAsImg,
  fitCamToScene,
  runCopyUrl,
  runMakeKakaoThumb,
  popUpWebViewGuide,
  loadSVG,
  createCopyright,
  updateCopyrightPosAndScale,
} from './mainUtils';
import { checkMobile, checkWebViewAndDevice, createEvtKey, createEvtListener, mapRange } from './commonUtils';

const domEl = {};
const mouseEl = {};
const threeEl = {};
const asset = {};

const useDot = () => {
  const onInit = (userData) => {
    /* initialize paramters */
    // dom
    domEl.divThree = document.getElementById('div-three');
    domEl.cvsThree = document.getElementById('cvs-three');
    domEl.inputCtrlArr = document.querySelectorAll('.input-ctrl');
    domEl.btnShareArr = document.querySelectorAll('.btn-share');

    // mouse
    mouseEl.evtList = [];
    mouseEl.evtKey = { click: 'click' };
    mouseEl.pos = { prev: undefined, now: undefined };
    mouseEl.isMobile = checkMobile();
    const webViewAndDeviceData = checkWebViewAndDevice();
    mouseEl.isWebView = webViewAndDeviceData.isWebView;
    mouseEl.device = webViewAndDeviceData.device;
    createEvtKey(mouseEl);
    mouseEl.isPressed = mouseEl.isMobile ? false : true;

    // three
    threeEl.scene = {};
    threeEl.renderer = {};
    threeEl.camera = {};
    threeEl.raycaster = {};
    threeEl.hole = {};
    threeEl.cell = {};
    threeEl.dot = {};
    threeEl.build = {};
    threeEl.text = {};
    threeEl.customArray = {};
    threeEl.copyright = {};

    threeEl.scene.background = {};
    threeEl.scene.background.color = {
      blank: new THREE.Color(threeOpt.scene.background.color.blank),
    };

    const scaleObjKeyArr = ['hole', 'dot'];
    scaleObjKeyArr.forEach((scaleObjKey) => {
      threeEl[scaleObjKey].scale = JSON.parse(JSON.stringify(threeOpt[scaleObjKey].scale));
      threeEl[scaleObjKey].scale.default = threeOpt[scaleObjKey].scale.default = mapRange(
        2,
        0,
        4,
        threeEl[scaleObjKey].scale.min,
        threeEl[scaleObjKey].scale.max,
      );
    });
    threeEl.dot.distortSize = JSON.parse(JSON.stringify(threeOpt.dot.distortSize));
    threeEl.dot.distortSize.default = threeOpt.dot.distortSize.default = mapRange(2, 0, 4, threeEl.dot.distortSize.min, threeEl.dot.distortSize.max);
    threeEl.customArray.squareSize =
      Math.min(threeOpt.text.letter.interval.default, threeOpt.text.line.height) * threeOpt.customArray.num.max * threeOpt.hole.size;

    /* initialize three */
    // create three.scene
    threeEl.scene.obj = new THREE.Scene();
    threeEl.scene.obj.background = threeEl.scene.background.color.blank;
    threeEl.scene.obj.colorBlank = threeEl.scene.background.color.blank.clone();
    threeEl.scene.obj.size = threeEl.scene.size;

    // create three.renderer
    onResize();
    threeEl.renderer.obj = new THREE.WebGLRenderer({
      powerPreference: 'high-performance',
      canvas: domEl.cvsThree,
      preserveDrawingBuffer: true,
    });
    threeEl.renderer.obj.setPixelRatio(threeEl.renderer.dpr);
    threeEl.renderer.obj.setSize(threeEl.renderer.size.width, threeEl.renderer.size.height);
    threeEl.renderer.obj.size = threeEl.renderer.size;
    threeEl.renderer.obj.dpr = threeEl.renderer.dpr;

    // create three.camera
    threeEl.camera.obj = new THREE.OrthographicCamera(
      threeEl.scene.size.width / -2,
      threeEl.scene.size.width / 2,
      threeEl.scene.size.height / 2,
      threeEl.scene.size.height / -2,
      1,
      threeOpt.scene.width * 2,
    );
    threeEl.camera.obj.position.set(0, 0, threeOpt.scene.width);
    threeEl.camera.obj.lookAt(0, 0, 0);
    const zoomSize = mapRange(5, 0, 10, threeOpt.customArray.zoom.min, threeOpt.customArray.zoom.max);
    threeEl.camera.obj.zoom = zoomSize;
    threeEl.camera.obj.updateProjectionMatrix();

    // create three.raycaster
    threeEl.raycaster.obj = new THREE.Raycaster();

    /* initialize objects */
    // custom array
    threeEl.customArray.group = new THREE.Group();
    threeEl.customArray.group.name = 'customArray';
    threeEl.scene.obj.add(threeEl.customArray.group);
    threeEl.customArray.group.squareSize = threeEl.customArray.squareSize;
    threeEl.customArray.group.letterStep = {};
    threeEl.customArray.group.posNoise = {};
    const arrayTypeArr = ['linear', 'radial'];
    arrayTypeArr.forEach((arrayType) => {
      threeEl.customArray.group.letterStep[arrayType] = {
        x: threeOpt.hole.size * threeOpt.customArray[arrayType].letter.interval,
        y: threeOpt.hole.size * threeOpt.customArray[arrayType].line.height,
      };
      threeEl.customArray.group.letterStep[arrayType].halfSquareDiagonal = Math.sqrt(2) * threeEl.customArray.group.letterStep[arrayType].x * 0.5;
      threeEl.customArray.group.posNoise[arrayType] = {};
      Object.keys(threeOpt.customArray[arrayType].posNoise).forEach((noiseKey) => {
        if (noiseKey === 'angle') {
          threeEl.customArray.group.posNoise[arrayType][noiseKey] = (threeOpt.customArray[arrayType].posNoise[noiseKey] / 180) * Math.PI;
        } else {
          threeEl.customArray.group.posNoise[arrayType][noiseKey] =
            threeEl.customArray.group.letterStep[arrayType][noiseKey] * threeOpt.customArray[arrayType].posNoise[noiseKey];
        }
      });
    });

    threeEl.customArray.userData = refineUserData(userData);
    fillScene(threeEl.scene.obj, threeEl.customArray.userData.sceneColor);
    threeEl.customArray.group.sceneData = {
      background: threeEl.scene.obj.background.clone(),
      colorBlank: threeEl.scene.obj.colorBlank.clone(),
    };
    arrayLetters('linear', threeEl.customArray.userData, threeEl.customArray.group);

    // copyright
    threeEl.copyright.obj = createCopyright(
      asset.copyrightSVG,
      threeEl.customArray.userData.sceneColor,
      threeEl.customArray.userData.dotColor,
      threeEl.customArray.squareSize,
    );
    threeEl.scene.obj.add(threeEl.copyright.obj);
    updateCopyrightPosAndScale(threeEl.copyright.obj, threeEl.camera.obj);

    /* initialize events */
    mouseEl.evtList.push(createEvtListener('resize', onResize));
    domEl.btnShareArr.forEach((btnShare) => {
      mouseEl.evtList.push(createEvtListener(mouseEl.evtKey.click, onShareClick, btnShare));
    });

    mouseEl.evtList.forEach((evt) => {
      evt.targetDom.addEventListener(evt.evtKey, evt.callback, false);
    });

    /* start and render first */
    renderScene(threeEl.renderer.obj, threeEl.scene.obj, threeEl.camera.obj);
  };

  const onShareClick = (evt) => {
    if (!threeEl.customArray.group) return;

    const shareType = evt.target ? evt.target.getAttribute('id') : evt;
    switch (shareType) {
      case 'copyUrl': {
        runCopyUrl();
        break;
      }
      case 'shareWithKakao': {
        return new Promise((resolve) => {
          runMakeKakaoThumb(threeEl.renderer.obj, threeEl.scene.obj, threeEl.camera.obj, threeEl.customArray.squareSize).then((dataURL) => {
            resolve(dataURL);
          });
        });
      }
      case 'shareWithEmail': {
        return new Promise((resolve) => {
          runMakeKakaoThumb(threeEl.renderer.obj, threeEl.scene.obj, threeEl.camera.obj, threeEl.customArray.squareSize, true).then((dataURL) => {
            resolve(dataURL);
          });
        });
      }
      case 'saveAsImg': {
        if (mouseEl.isWebView) {
          popUpWebViewGuide(mouseEl.device);
        } else {
          runSaveAsImg(threeEl.renderer.obj, threeEl.scene.obj, threeEl.camera.obj, threeEl.customArray.squareSize);
        }
        break;
      }
      default: {
        return;
      }
    }
  };

  const onFirstSliderChange = (value) => {
    const zoomSize = mapRange(parseFloat(value), 0, 10, threeOpt.customArray.zoom.max, threeOpt.customArray.zoom.min);
    threeEl.camera.obj.zoom = zoomSize;
    threeEl.camera.obj.updateProjectionMatrix();
    updateCopyrightPosAndScale(threeEl.copyright.obj, threeEl.camera.obj);
    renderScene(threeEl.renderer.obj, threeEl.scene.obj, threeEl.camera.obj);
  };

  const onSecondSliderChange = (value) => {
    if (threeEl.customArray.group.arrayType === 'linear') {
      // linear array
      const rotateZ = mapRange(parseFloat(value), 0, 10, Math.PI * 0.5, Math.PI * -0.5);
      threeEl.customArray.group.rotation.z = rotateZ;
    } else {
      // radial array
      const posY = mapRange(parseFloat(value), 0, 10, threeEl.customArray.squareSize * 0.5, threeEl.customArray.squareSize * -0.5);
      threeEl.customArray.group.position.y = posY;
    }
    renderScene(threeEl.renderer.obj, threeEl.scene.obj, threeEl.camera.obj);
  };

  const onArrayChange = (type) => {
    arrayLetters(type, threeEl.customArray.userData, threeEl.customArray.group);
    renderScene(threeEl.renderer.obj, threeEl.scene.obj, threeEl.camera.obj);
  };

  const onLoad = (userData) => {
    loadSVG(assetIndex.copyrightSVG).then((svgData) => {
      asset.copyrightSVG = svgData;
      onInit(userData);
    });
  };

  const onUnload = () => {
    /* clear event listeners */
    // mouse
    if (mouseEl.evtList) {
      mouseEl.evtList.forEach((evt) => {
        evt.targetDom?.removeEventListener(evt.evtKey, evt.callback, false);
      });
    }
    mouseEl.evtList = [];

    // three
    threeEl.renderer?.obj?.setAnimationLoop(null);
  };

  const onResize = () => {
    const divThreeBBox = domEl.divThree.getBoundingClientRect();
    threeEl.renderer.dpr = window.devicePixelRatio;
    threeEl.renderer.size = {
      width: divThreeBBox.width,
      height: divThreeBBox.height,
    };

    threeEl.scene.size = {
      aspect: threeEl.renderer.size.width / threeEl.renderer.size.height,
    };

    const arrayFitSceneSize = calcArrayFitSceneSize(threeEl.scene.size.aspect, threeEl.customArray.squareSize);
    threeEl.scene.size.width = arrayFitSceneSize.width;
    threeEl.scene.size.height = arrayFitSceneSize.height;
    threeEl.scene.size.diagonal = Math.sqrt(Math.pow(threeEl.scene.size.width, 2) + Math.pow(threeEl.scene.size.height, 2));

    if (threeEl.scene.obj) {
      threeEl.scene.obj.size = threeEl.scene.size;
    }

    if (threeEl.renderer.obj) {
      threeEl.renderer.obj.setPixelRatio(threeEl.renderer.dpr);
      threeEl.renderer.obj.setSize(threeEl.renderer.size.width, threeEl.renderer.size.height);
      threeEl.renderer.obj.size = threeEl.renderer.size;
      threeEl.renderer.obj.dpr = threeEl.renderer.dpr;
    }

    if (threeEl.customArray.group) {
      arrayLetters(threeEl.customArray.group.arrayType, threeEl.customArray.userData, threeEl.customArray.group);
    }

    if (threeEl.camera.obj) {
      fitCamToScene(threeEl.camera.obj, threeEl.scene.obj.size);
      threeEl.camera.obj.updateProjectionMatrix();
      renderScene(threeEl.renderer.obj, threeEl.scene.obj, threeEl.camera.obj);
    }
  };

  return {
    onLoad,
    onUnload,
    onShareClick,
    onFirstSliderChange,
    onSecondSliderChange,
    onArrayChange,
  };
};

export default useDot;
