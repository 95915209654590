import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import useKakao from '@/hooks/useKakao';
import { useParts } from '@/contexts/parts';
import { Paths } from '@/utils/constants';
import { dataURLtoFile } from '@/utils';
import useDot from '@/interactive/useDot';
import IconArray from '@/components/custom/IconArray';
import Icons from '@/components/custom/Icons';
import { email, upload } from '@/services/firebase';

const RangeTrack = () => {
  return (
    <div className="flex flex-row h-4 w-full top-3 absolute pointer-events-none select-none -z-10">
      <div className="w-4 md:w-8 bg-white z-10" />
      <div className="w-1/10 border-l border-black">&ensp;</div>
      <div className="w-1/10 border-l border-black">&ensp;</div>
      <div className="w-1/10 border-l border-black">&ensp;</div>
      <div className="w-1/10 border-l border-black">&ensp;</div>
      <div className="w-1/10 border-l border-black">&ensp;</div>
      <div className="w-1/10 border-l border-black">&ensp;</div>
      <div className="w-1/10 border-l border-black">&ensp;</div>
      <div className="w-1/10 border-l border-black">&ensp;</div>
      <div className="w-1/10 border-l border-black">&ensp;</div>
      <div className="w-1/10 border-l border-r border-black">&ensp;</div>
      <div className="w-4 md:w-8 bg-white z-10" />
    </div>
  );
};

const Dot = () => {
  const { onLoad, onUnload, onShareClick, onFirstSliderChange, onSecondSliderChange, onArrayChange } = useDot();
  const { id } = useParams();
  const { replace } = useHistory();
  const { getPart } = useParts();
  const Kakao = useKakao();
  const [isLoaded, setLoaded] = useState(false);
  const [partData, setPartData] = useState(null);
  const [isArray, setArray] = useState('linear');
  const [firstRange, setFirstRange] = useState(5);
  const [secondRange, setSecondRange] = useState(5);
  const [isSuccess, setSuccess] = useState(true);
  const [toast, setToast] = useState('');
  const toastStyle = useMemo(() => ({ transform: toast ? 'translate(-50%, 0)' : 'translate(-50%, calc(-2rem - 100%))' }), [toast]);

  useEffect(() => {
    (async () => {
      setLoaded(true);
      const result = await getPart(id);
      if (result) {
        setPartData({ id, ...result });
        onLoad(result);
      } else {
        replace(Paths.main);
      }
    })();
    return () => {
      onUnload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toast) {
      const timeout = setTimeout(() => {
        setToast('');
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [toast]);

  const handleFirstRange = useCallback(
    (e) => {
      setFirstRange(e.target.value);
      onFirstSliderChange(e.target.value);
    },
    [onFirstSliderChange],
  );

  const handleSecondRange = useCallback(
    (e) => {
      setSecondRange(e.target.value);
      onSecondSliderChange(e.target.value);
    },
    [onSecondSliderChange],
  );

  const handleArray = useCallback(
    (value) => {
      if (isArray === value) return;
      onArrayChange(value);
      setArray(value);
      setSecondRange(5);
    },
    [isArray, onArrayChange],
  );

  const handleShareKakao = useCallback(async () => {
    try {
      const dataURL = await onShareClick('shareWithKakao');
      if (!dataURL) throw new Error('이미지 변환 중 오류가 발생했습니다. 관리자에게 문의하세요.');

      const file = dataURLtoFile(dataURL, `${id}.png`);
      const upload = await Kakao?.Link?.uploadImage({ file: [file] });
      if (!upload) throw new Error('이미지 업로드 중 오류가 발생했습니다. 관리자에게 문의하세요.');

      const imageUrl = upload?.infos?.original?.url;
      if (!imageUrl) throw new Error('이미지 다운로드 중 오류가 발생했습니다. 관리자에게 문의하세요.');

      Kakao?.Link?.sendDefault?.({
        objectType: 'feed',
        content: {
          title: '뮤지엄 타임캡슐 : 미래로 향한 뮤지엄',
          description: '2022 환기미술관 특별 전시 프로그램',
          imageUrl,
          link: { mobileWebUrl: window.location.origin, webUrl: window.location.origin },
        },
        buttons: [
          {
            title: '참여하기',
            link: { mobileWebUrl: window.location.origin, webUrl: window.location.origin },
          },
          {
            title: '이미지보기',
            link: { mobileWebUrl: window.location.href, webUrl: window.location.href },
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  }, [Kakao, onShareClick, id]);

  const sendEmail = useCallback(async () => {
    const emailAddressInput = window.prompt('이메일을 입력해주세요.');

    if (!emailAddressInput) {
      setSuccess(false);
      setToast('이메일 보내기가 취소되었습니다.');
      return;
    } else if (!emailAddressInput.includes('@') || emailAddressInput === '') {
      setSuccess(false);
      setToast('메일 주소를 정확히 입력해주세요.');
      return;
    }

    setSuccess(true);
    setToast('이메일을 전송 중입니다...');

    const dataURL = await onShareClick('shareWithEmail');
    if (!dataURL) throw new Error('이미지 변환 중 오류가 발생했습니다. 관리자에게 문의하세요.');

    const file = dataURLtoFile(dataURL, `${id}.png`);

    const imageUrl = await upload.file({ fileName: `${id}.png`, file });
    if (!imageUrl) throw new Error('이미지 업로드 중 오류가 발생했습니다. 관리자에게 문의하세요.');

    const emailBodyHTML = `
      <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>《뮤지엄 타임캡슐》: 미래로 향한 뮤지엄</title>
        </head>
        <body style="font-family: pretendard;">
          <div style="width:90%; margin: auto; text-align: left;">
            <p
              style="display: inline-block; margin:0; padding-top: 3rem; padding-bottom: 0.5rem; line-height: 1.6; font-weight: 400;">
              「2022 박물관·미술관 주간」 ‘박물관의 힘’ 주제형 프로그램</p>
            <br>
            <h1 style="display: inline-block; margin:0; padding-bottom: 2rem; font-weight: 400;">
              《뮤지엄 타임캡슐》: 미래로 향한 뮤지엄</h1>
            <br>
            <p
              style="display: inline-block; line-height: 1.6; margin:0; padding-bottom: 2rem; font-weight: 300;">
              환기미술관에서의 체험은 즐거우셨나요?<br>
              환기미술관의 미래를 함께할 모든 이들을 위한 디지털 전시 콘텐츠 '뮤지엄 타임캡슐'.<br>
              '뮤지엄 타임캡슐'은 관람객에게 질문을 던지고 이에 답한 관람객의 감상은 색·면·점이 되어 새로운 조형언어로 공간을 넘어 미래로 향해 갑니다.<br>
              지금 바로 당신의 작품을 확인해보세요.</p>
            <br>
            <img style="margin: auto; width: 100%; height: auto; display: block; padding-bottom:1rem;"
              src="${imageUrl}" alt="뮤지엄 타임캡슐" />
            <a href="${imageUrl}" target="_blank" style="color: black;">[이미지 다운로드]</a><br><br>
            <a href="${window.location.href}" target="_blank" style="color: black; padding-bottom:3rem;">[내 패턴 수정하기]</a>
          </div>
        </body>
        </html>`;

    const result = await email.send({
      to: [emailAddressInput],
      message: { subject: '[환기미술관] ‘뮤지엄 타임캡슐’의 체험 결과물입니다.', html: emailBodyHTML },
    });

    if (result) {
      setSuccess(true);
      setToast('이메일이 성공적으로 전송되었습니다.');
    } else {
      setSuccess(false);
      setToast('오류가 발생했습니다. 관리자에게 문의하세요.');
    }
  }, [id, onShareClick]);

  if (!isLoaded || !id || !partData?.id) return null;

  return (
    <>
      <div id="section-dot" className="print:block w-full h-fill flex flex-col lg:flex-row overflow-hidden fixed">
        <div id="div-three" className="w-full h-2/5 lg:w-1/2 lg:h-full">
          <div id="div-temp" className="print:hidden w-full h-2/5 lg:w-1/2 lg:h-full absolute left-0 top-0" style={{ zIndex: '-10' }} />
          <canvas id="cvs-three" />
        </div>
        <div className="print:hidden w-full h-3/5 lg:w-1/2 lg:h-full font-light flex flex-col">
          <div className="p-4 flex flex-col overflow-y-auto flex-grow">
            <div className="flex flex-col flex-grow justify-center items-center py-4 gap-y-8 text-center">
              <div>
                <h2 className="text-lg lg:text-xl mb-4 lg:mb-6">1. 패턴을 골라주세요.</h2>
                <div className="flex flex-row items-center gap-x-10 mb-4">
                  <button onClick={() => handleArray('linear')}>
                    <IconArray type="linear" select={isArray === 'linear'} />
                  </button>
                  <button onClick={() => handleArray('radial')}>
                    <IconArray type="radial" select={isArray === 'radial'} />
                  </button>
                </div>
              </div>
              <div className="w-full">
                <h2 className="text-lg lg:text-xl mb-4 lg:mb-6">2. 밀도를 선택해주세요.</h2>
                <div className="relative w-full">
                  <input
                    type="range"
                    id="paramFirst"
                    className="custom-slider-share"
                    min="0"
                    max="10"
                    step="1"
                    value={firstRange}
                    onChange={handleFirstRange}
                  />
                  <div className="flex flex-row px-2 pt-2 md:pt-0 w-full justify-between md:px-5 select-none">
                    <Icons type="dencity-low" />
                    <Icons type="dencity-high" />
                  </div>
                  <div className="mx-4 border-b border-black absolute top-5 -z-10 custom-range-track-share" />
                  <RangeTrack />
                </div>
              </div>
              <div className="w-full">
                <h2 className="text-lg lg:text-xl mb-4 lg:mb-6">
                  {isArray === 'linear' ? '3. 패턴의 각도를 선택해주세요.' : '3. 동심원의 위치를 선택해주세요.'}
                </h2>
                <div className="relative w-full">
                  <input
                    type="range"
                    id="paramFirst"
                    className="custom-slider-share"
                    min="0"
                    max="10"
                    step="1"
                    value={secondRange}
                    onChange={handleSecondRange}
                  />
                  {isArray === 'linear' ? (
                    <div className="flex flex-row w-full px-2 pt-2 md:pt-0 justify-between md:px-5 select-none">
                      <Icons type="rotate" style={{ transform: 'scaleX(-1)' }} />
                      <Icons type="rotate" />
                    </div>
                  ) : (
                    <div className="flex flex-row w-full px-2 pt-2 md:pt-0 justify-between md:px-5 select-none">
                      <Icons type="arrow-down" />
                      <Icons type="arrow-down" className="transform rotate-180" />
                    </div>
                  )}
                  <div className="mx-4 border-b border-black absolute top-5 -z-10 custom-range-track-share" />
                  <RangeTrack />
                </div>
              </div>
              <div className="flex flex-col items-center gap-y-2">
                <h2 className="text-lg lg:text-xl mb-4">4. 이미지를 저장할 수 있어요.</h2>
                <div className="flex flex-wrap flex-row gap-2 justify-center items-center">
                  <button id="saveAsImg" className="btn-share btn-social">
                    이미지 저장
                    <Icons type="download" className="ml-1" />
                  </button>
                  <button id="mail" className="btn-social" onClick={() => sendEmail()}>
                    내 메일로 전송
                    <Icons type="email" className="ml-1" />
                  </button>
                </div>
              </div>
              <div className="flex flex-col items-center gap-y-2">
                <h2 className="text-lg lg:text-xl mb-4">5. 공유할 수 있어요.</h2>
                <div className="flex flex-wrap flex-row gap-2 justify-center items-center">
                  <button id="copyUrl" className="btn-share btn-social">
                    링크로 공유
                    <Icons type="link" className="ml-1" />
                  </button>
                  <button onClick={handleShareKakao} className="btn-social">
                    카카오톡으로 공유
                    <Icons type="kakao" className="ml-1" />
                  </button>
                </div>
              </div>
              <div className="flex flex-col items-center gap-y-2 mt-3">
                <h2 className="text-lg lg:text-xl mb-4">5. 홈으로 이동해서 내가 만든 패턴을 찾아보세요 :)</h2>
                <div className="flex flex-wrap flex-row gap-2 justify-center items-center font-normal">
                  <Link to={Paths.draw} className="btn-social">
                    ← 새로 만들기
                  </Link>
                  <Link to={Paths.main} className="btn-social">
                    홈으로 이동 →
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <span
            className={`scroll flex left-1/2 transform -translate-x-1/2 top-4 transition ease-out duration-500 text-center z-50 ${
              isSuccess ? 'bg-green-100' : 'bg-red-200'
            } bg-opacity-80`}
            style={{ ...toastStyle }}>
            {isSuccess ? <span className="font-bold text-green-600">✓&ensp;</span> : <span className="font-bold text-red-600">⚠&ensp;</span>}
            {toast}
          </span>
        </div>
      </div>
    </>
  );
};

export default Dot;
