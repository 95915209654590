import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Paths } from '@/utils/constants';

const MainHeader = () => {
  const { pathname } = useLocation();
  const [isMobMenuOpen, setMobMenuOpen] = useState();

  const MenuList = ({ className }) => {
    return (
      <div className={`font-light flex flex-row items-center ${className}`}>
        <Link to={Paths.main} className={`${pathname === Paths.main ? 'pointer-events-none currentPath' : 'md:hover:underline'}`}>
          Home
        </Link>
        <Link to={Paths.about} className={`${pathname === Paths.about ? 'pointer-events-none currentPath' : 'md:hover:underline'}`}>
          About
        </Link>
        <Link to={Paths.draw} className="md:hover:underline">
          Let's go!
        </Link>
      </div>
    );
  };

  return (
    <>
      <MenuList
        className={`lg:hidden absolute bottom-0 left-0 transform ${
          isMobMenuOpen && '-translate-y-full'
        } h-14 bg-white w-full px-4 justify-between border-t border-black`}
      />
      <div className="w-full h-14 bg-white flex flex-row border-t border-black items-center justify-between px-4 md:px-8 font-normal md:text-lg relative flex-shrink-0">
        <Link to={Paths.main} className={`${pathname === Paths.main ? 'pointer-events-none' : 'md:hover:underline'} group`}>
          <span className="hidden md:inline-block group-hover:underline">2022 환기미술관 특별 전시 프로그램&ensp;</span>《뮤지엄 타임캡슐》 : 미래로
          향한 뮤지엄
        </Link>
        <MenuList className="gap-x-12 hidden lg:flex" />
        <button onClick={() => setMobMenuOpen(!isMobMenuOpen)} className="lg:hidden w-5 h-5 flex relative items-center justify-center">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth="2">
            <line
              x1="8"
              y1="-4.37114e-08"
              x2="8"
              y2="16"
              stroke="black"
              className={`transform origin-center ${isMobMenuOpen && 'rotate-90'} transition duration-300`}
            />
            <line x1="16" y1="8" y2="8" stroke="black" />
          </svg>
        </button>
      </div>
    </>
  );
};

export default MainHeader;
