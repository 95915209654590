import React, { useEffect, useState } from 'react';
import Header from '@/components/layout/Header';
import Container from '@/components/layout/Container';
import { useAuth } from '@/contexts/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { Paths } from '@/utils/constants';
import { useUI } from '@/contexts/ui';

const Login: React.FC = () => {
  const { booted, user, login } = useAuth();
  const [id, setId] = useState('');
  const [pwd, setPwd] = useState('');
  const history = useHistory();
  const location = useLocation();
  const { showSpinner, hideSpinner } = useUI();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { from }: any = location.state || { from: { pathname: Paths.admin } };

  useEffect(() => {
    if (user) history.replace(from);
  }, [user, history, from]);

  const handleLogin = (e: React.MouseEvent) => {
    e.preventDefault();
    showSpinner();
    login({ username: id, password: pwd }, (success) => {
      if (success) {
        history.replace(from);
      }
      hideSpinner();
    });
  };

  if (user) return null;
  if (!booted) return null;

  return (
    <>
      <Header />
      <Container>
        <form className="w-full md:w-96 mt-5 mx-auto">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              이메일
            </label>
            <input
              autoCorrect="off"
              autoCapitalize="none"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="username"
              value={id}
              onChange={(e) => setId(e.target.value)}
              autoComplete="username"
            />
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              비밀번호
            </label>
            <input
              type="password"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              value={pwd}
              onChange={(e) => setPwd(e.target.value)}
              autoComplete="current-password"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-500 hover:bg-indigo-600 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleLogin}>
            로그인
          </button>
        </form>
      </Container>
    </>
  );
};

export default Login;
