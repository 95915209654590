import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault('Asia/Seoul');

export const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor = 300) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};

export function date(date: number | string): Date {
  return new Date(isNaN(Number(date)) ? date : Number(date));
}

export function now(): number {
  return moment().valueOf();
}

export function formatDate(date: Date | number, format = 'YYYY-MM-DD HH:mm:ss') {
  return moment(date).format(format);
}

export function dataURLtoFile(dataUrl: string, fileName: string) {
  const arr = dataUrl.split(',');
  const mime = arr?.[0]?.match?.(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length || 0;
  const u8arr = new Uint8Array(n);
  while (n--) u8arr[n] = bstr.charCodeAt(n);
  return new File([u8arr], fileName, { type: mime });
}

export function throttle(func: any, wait = 300) {
  let previous = 0,
    timeout: any,
    args: any;

  const later = (context: any) => {
    previous = now();
    timeout = null;
    func.apply(context, args);
    args = null;
  };

  return function (...rest: any) {
    const time = now(),
      remaining = wait - (time - previous);

    args = rest;

    if (remaining <= 0 || remaining > wait) {
      previous = time;
      // @ts-ignore
      func.apply(this, args);

      clearTimeout(timeout);
      timeout = null;
      args = null;
    } else if (!timeout) {
      timeout = setTimeout(() => {
        // @ts-ignore
        later(this);
      }, remaining);
    } else {
    }
  };
}
