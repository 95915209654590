import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, User } from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  deleteDoc,
  query,
  limit,
  startAfter,
  endBefore,
  orderBy,
  onSnapshot,
  QuerySnapshot,
  Unsubscribe,
  DocumentData,
  CollectionReference,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { now } from '@/utils';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const app = initializeApp(firebaseConfig);

const AuthErrorMessages: any = {
  PERMISSION_DENIED: '편집 권한이 없습니다. 관리자에게 문의하세요.',
  'auth/invalid-email': '이메일 형식에 맞게 입력해주세요.',
  'auth/wrong-password': '비밀번호가 일치히지 않습니다.',
  'auth/session-cookie-expired': '제공된 세션 쿠키가 만료되었습니다.',
  'auth/session-cookie-revoked': '세션 쿠키가 취소되었습니다.',
  'auth/uid-already-exists': '이미 사용중인 아이디입니다.',
  'auth/unauthorized-continue-uri': '연결 URL의 도메인이 허용 목록에 포함되어 있지 않습니다. Firebase Console에서 도메인을 허용해야 합니다.',
  'auth/user-not-found': '해당 사용자가 없습니다.',
};

const Collection = {
  parts: 'parts',
  mail: 'mail',
};

export const authService = {
  refresh(loggedInCb: (user: User) => void, notLoggedInCb: () => void): void {
    try {
      const auth = getAuth(app);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          loggedInCb(user);
        } else {
          notLoggedInCb();
        }
      });
    } catch (error: any) {
      console.error(error);
    }
  },
  async signIn(email: string, password: string): Promise<User | undefined> {
    try {
      const auth = getAuth(app);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential.user;
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = AuthErrorMessages[errorCode] || error.message || error.code;
      if (errorMessage) alert(errorMessage);
    }
  },
  async signOut(): Promise<void> {
    try {
      const auth = getAuth(app);
      await signOut(auth);
    } catch (error: any) {
      console.error(error);
    }
  },
};

const db = getFirestore(app);

const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(db, collectionName) as CollectionReference<T>;
};

const partsListCollection = createCollection<PartDataList>(Collection.parts);
const partsDataCollection = createCollection<PartData>(Collection.parts);

export const partsApi = {
  onCreate(first: PartData, callback: (list: PartDataList, unsubscribe: Unsubscribe) => void) {
    const condition = query(partsListCollection, orderBy('createTime', 'desc'), endBefore(first.createTime));
    const unsubscribe: Unsubscribe = onSnapshot(condition, (snapshot) => callback(resolvePartDataList(snapshot), unsubscribe));
  },
  async gets({ after, size }: { after?: PartData; size: number }): Promise<PartDataList | null> {
    try {
      const condition = after?.createTime
        ? query(partsListCollection, orderBy('createTime', 'desc'), limit(size), startAfter(after.createTime))
        : query(partsListCollection, orderBy('createTime', 'desc'), limit(size));
      const snapshot = await getDocs(condition);
      return resolvePartDataList(snapshot);
    } catch (error: any) {
      console.error(error);
      return null;
    }
  },
  async get(id: string): Promise<PartData | null> {
    try {
      const docRef = doc(partsDataCollection, id);
      const docSnap = await getDoc<PartData>(docRef);
      return docSnap.exists() ? docSnap.data() : null;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  async getAll(): Promise<PartDataList | null> {
    try {
      const snapshot = await getDocs(query(partsListCollection, orderBy('createTime', 'desc')));
      return resolvePartDataList(snapshot);
    } catch (error: any) {
      console.error(error);
      return null;
    }
  },
  async post(item: PartDataForm): Promise<PartData | null> {
    try {
      const newItem = { ...item, createTime: now() };
      const docRef = await addDoc(collection(db, Collection.parts), newItem);
      return { id: docRef.id, ...newItem };
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = AuthErrorMessages[errorCode] || error.message || error.code;
      if (errorMessage) alert(errorMessage);
      return null;
    }
  },
  async delete(id: string) {
    try {
      await deleteDoc(doc(db, Collection.parts, id));
      return true;
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = AuthErrorMessages[errorCode] || error.message || error.code;
      if (errorMessage) window.alert(errorMessage);
      return false;
    }
  },
};

function resolvePartDataList(snapshot: QuerySnapshot): PartDataList {
  return (snapshot?.docs?.map((doc) => ({ ...doc?.data(), id: doc.id })) as PartDataList) || [];
}

export const email = {
  async send(email: EmailData): Promise<string | null> {
    try {
      const docRef = await addDoc(collection(db, Collection.mail), email);
      return docRef.id;
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = AuthErrorMessages[errorCode] || error.message || error.code;
      if (errorMessage) alert(errorMessage);
      return null;
    }
  },
};

const storage = getStorage();

export const upload = {
  async file({ fileName, file }: { fileName: string; file: File }) {
    try {
      const imageRef = ref(storage, `images/${fileName}`);
      const snapshot = await uploadBytes(imageRef, file);
      const imageUrl = await getDownloadURL(snapshot.ref);
      return imageUrl;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  },
};
