export const assetIndex = {
  fontJSON: { dir: '/font/KCC-Kimwhanki.json', contentLng: 22580397 },
  copyrightSVG: { dir: '/dot/copyright.svg', contentLng: 37239 },
};
export const placeholder = '다섯 글자 이상 작성해주세요.';
export const threeOpt = {
  scene: {
    width: 300, // three unit
    background: {
      color: {
        blank: 'rgb(245, 235, 225)', // rgb, default background color
      },
      brush: {
        strokeNum: 4, // number, stroke number while brushing
      },
      colorDiffLimit: 0.001, // %, threshold for checking same color
    },
    timeDelta: 0.1, // number, time speed for wiggling
  },
  camera: {
    zoomPerAspect: {
      // number, camera zoom relate to window aspect
      1.8: 0.5,
      1.5: 0.7,
      1.2: 0.9,
      1: 1,
    },
  },
  hole: {
    size: 100, // three unit
    sideNodeNum: 3, // number, node number per each side (total 4 sides)
    filletRadius: 0.05, // %, ratio to hole size
    distortSize: 0.04, // %, ratio to hole size
    scale: {
      // %, ratio to hole size
      min: 0.8,
      max: 1.2,
    },
  },
  cell: {
    scale: {
      // ratio to hole size
      default: 1.1,
    },
    sideNodeNum: 3, // number, node number per each side (total 4 sides)
    filletRadius: 0.05, // %, ratio to cell size
    distortSize: 0.04, // %, ratio to cell size
    colorLevelDelta: {
      // level in hsl [0 - 100]
      brighter: 20,
      darker: -30,
    },
    colorLevelBound: {
      // level in hsl [0 - 100]
      min: 10,
      max: 90,
    },
    opacity: {
      // %
      brighter: 0.5,
      darker: 0.8,
    },
  },
  dot: {
    scale: {
      // %, ratio to hole size
      min: 0.2,
      max: 0.8,
    },
    sideNodeNum: 3, // number, node number per each side (total 4 sides)
    filletRadius: 0.1, // %, ratio to dot size
    distortSize: {
      // %, ratio to dot size
      min: 0.02,
      max: 0.16,
      delta: 0.01,
    },
  },
  text: {
    scalePerScene: 0.8, // %, ratio to hole size
    letter: {
      interval: {
        // %, ratio to text size
        default: 1.2,
        space: 0.3,
      },
      scalePerHole: 0.8, // %, ratio to hole size
      offset: {
        // %, ratio to hole size
        x: 0.5,
        y: 0.35,
      },
    },
    line: {
      height: 1.5, // %, ratio to text size
    },
    placeholder: placeholder, // string, Q7, Q8 placeholder text
    subGroupNameArr: ['textFuture', 'gap', 'textPower'], // array, group name for draw page
  },
  customArray: {
    rendererSize: {
      // px, width and height of square image
      saveAsImg: 1024,
      printAsImg: 595,
    },
    sizePerScene: 0.9, // bounding box of array width per scene width
    num: {
      max: 15, // number, maximum number per scene width
    },
    zoom: {
      // %
      min: 1, // more dots & small size
      max: 5, // less dots, big size
    },
    posNoiseScale: {
      // %
      x: 0.1, // ratio to letter.interval.default
      y: 0.1, // ratio to line.height
    },
    linear: {
      letter: {
        interval: 1.2, // %, ratio to hole size
      },
      line: {
        height: 1.3, // %, ratio to hole size
      },
      posNoise: {
        // %
        x: 0.1, // ratio to letter.interval.default
        y: 0.1, // ratio to line.height
      },
    },
    radial: {
      letter: {
        interval: 1.2, // three unit
      },
      line: {
        height: 1.2, // three unit
      },
      posNoise: {
        angle: 1, // degree
        y: 0.1, // %, ratio to line.height
      },
    },
    copyright: {
      textWidthPerBBox: 0.7,
      aspect: 1024 / 64, // number
      padding: 0.02, // three unit
      strokeWidth: 3,
    },
    webViewAlert: {
      front: '기본 브라우저에서만 지원하는 기능입니다.',
      back: {
        ios: '오른쪽 하단의 버튼을 눌러서 "safari로 열기"를 눌러주세요.',
        android: '오른쪽 하단의 버튼을 눌러서 "다른 브라우저로 열기"를 눌러주세요.',
      },
    },
  },
  gallery: {
    scrollLimitNum: 100, // number
    pageReloadTime: 600, // second
    letter: {
      size: 8, // three unit
      offset: {
        x: 0.2, // three unit
        y: 0.25, // three unit
      },
    },
    textCvs: {
      verticeNum: 40, // number
      nodeNum: 14, // number
      randomness: {
        x: 0.3, // %, ratio to (scene.width / nodeNum)
        y: 0.25, // %, ratio to letter size
      },
    },
    wobble: {
      angle: 90, // degree, wobble maximum angle
      speedCoe: 0.15, // number, coefficent to wobble speed
    },
    scroll: {
      wheelCoe: 0.05, // number, coefficent to scroll delta on wheel
      touchMoveCoe: 0.3, // number, coefficent to scroll data on touch move
    },
  },
  animation: {
    brushScene: {
      time: 1, // second
    },
    expandHole: {
      time: 1, // second
    },
    changeHoleScale: {
      time: 1, // second
    },
    expandCell: {
      time: 1, // second
    },
    changeCellOpacity: {
      time: 1, // second
    },
    changeDotOpacity: {
      time: 1, // second
    },
    expandDot: {
      time: 1, // second
    },
    distortDot: {
      time: 1, // second
    },
    changeDotScale: {
      time: 1, // second
    },
    removeObj: {
      time: 1, // second
    },
    flipLetter: {
      time: {
        // second
        forward: 1,
        intermission: 1,
        backward: 1,
      },
    },
    flipText: {
      time: {
        forward: {
          // second
          min: 0.5,
          max: 2,
        },
        intermisstion: 2, // second
        backward: {
          // second
          min: 0.5,
          max: 1,
        },
      },
    },
    wobbleAccel: {
      time: 1, // second
    },
    updateText: {
      time: {
        // second
        remove: 0.5,
        roll: 1,
        pop: 1,
        shrink: 0.5,
      },
    },
  },
};
