import React, { useContext, createContext, useState, useCallback } from 'react';

const uiContext = createContext<any>(undefined);

type UIStore = {
  isShowModal: boolean;
  showModal: () => void;
  hideModal: () => void;
  isShowSpinner: boolean;
  showSpinner: () => void;
  hideSpinner: () => void;
};

export function useUI(): UIStore {
  return useContext(uiContext);
}

function useProvideUI() {
  const [isShowModal, setModal] = useState<boolean>(false);
  const [isShowSpinner, setSpinner] = useState<boolean>(false);

  const showModal = useCallback(() => setModal(true), []);
  const hideModal = useCallback(() => setModal(false), []);
  const showSpinner = useCallback(() => setSpinner(true), []);
  const hideSpinner = useCallback(() => setSpinner(false), []);

  return {
    isShowModal,
    showModal,
    hideModal,

    isShowSpinner,
    showSpinner,
    hideSpinner,
  };
}

export const ProvideUI: React.FC = ({ children }) => {
  const ui = useProvideUI();
  return <uiContext.Provider value={ui}>{children}</uiContext.Provider>;
};
