import { useEffect } from 'react';
import useMain from '../interactive/useMain';
import ContentSection from '@/interactive/ContentSection';
import Loader from '@/components/custom/Loader';

const Main = () => {
  const { onLoad, onUnload } = useMain();

  useEffect(() => {
    onLoad();
    return () => {
      onUnload();
    };
  });

  return (
    <>
      <div className="w-full h-fill flex flex-col lg:flex-row overflow-hidden fixed">
        <div id="div-three" className="w-full h-2/5 lg:w-1/2 lg:h-full">
          <canvas id="cvs-three" />
        </div>
        <div id="div-ctrl" className=" w-full h-3/5 lg:w-1/2 lg:h-full">
          <ContentSection />
        </div>
      </div>
      <Loader />
    </>
  );
};

export default Main;
