import React, { useCallback } from 'react';
import Container from '@/components/layout/Container';
import Header from '@/components/layout/Header';
import { useParts } from '@/contexts/parts';
import { downloadParts } from '@/services/excel';
import { formatDate } from '@/utils';

const Admin: React.FC = () => {
  const { partsList, getParts, deletePart, hasMore, isInit } = useParts();

  const handleLoadMore = () => {
    getParts(30);
  };

  const handleDownloadExcel = () => {
    downloadParts();
  };

  const handleDelete = useCallback(
    (id: string) => async (e: React.MouseEvent) => {
      e.preventDefault();
      if (window.confirm('정말로 삭제하시겠어요?\n이 작업은 복구할 수 없습니다.')) {
        await deletePart(id);
      }
    },
    [deletePart],
  );

  if (!isInit) return null;

  return (
    <>
      <Header />
      <Container>
        <div className="my-24 mx-auto">
          <div className="relative overflow-x-auto shadow-lg">
            <table className="min-w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-center">
                    아이디
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    생성일시
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    텍스트
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    편집
                  </th>
                </tr>
              </thead>
              <tbody>
                {partsList?.map((o, i) => {
                  return (
                    <tr key={`${o.id}_${i}`} className="bg-white border-b border-gray-100 text-xs">
                      <th scope="row" className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap text-center">
                        {o.id}
                      </th>
                      <td className="px-2 py-4 whitespace-nowrap text-center">{o?.createTime ? formatDate(o.createTime, 'YY.MM.DD HH:mm') : ''}</td>
                      <td className="px-2 py-4 whitespace-nowrap leading-5">
                        {o?.textFuture}
                        <br />
                        {o?.textPower}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-center">
                        <a href="#delete" className="font-medium text-red-400 hover:text-red-500" onClick={handleDelete(o.id)}>
                          삭제
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {hasMore && (
            <div className="h-12 w-full flex justify-between items-center mt-2">
              <button
                onClick={handleDownloadExcel}
                className="h-full bg-pink-500 hover:bg-pink-600 text-white px-2 py-1 font-bold text-sm rounded ml-4">
                엑셀 다운로드
              </button>
              <div>{partsList?.length}개</div>
              <button
                onClick={handleLoadMore}
                className="h-full bg-purple-500 hover:bg-purple-600 text-white px-2 py-1 font-bold text-sm rounded mr-4">
                콘텐츠 더보기
              </button>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Admin;
