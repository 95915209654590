
const IconArray = ({ type = "", select = false }) => {
  switch (type) {
    case "linear":
      return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.75 6.75H2.25V17.25H12.75V6.75Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M12.75 42.75H2.25V53.25H12.75V42.75Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M21.75 24.75H11.25V35.25H21.75V24.75Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M30.75 6.75H20.25V17.25H30.75V6.75Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M30.75 42.75H20.25V53.25H30.75V42.75Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M39.75 24.75H29.25V35.25H39.75V24.75Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M48.75 6.75H38.25V17.25H48.75V6.75Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M48.75 42.75H38.25V53.25H48.75V42.75Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M57.75 24.75H47.25V35.25H57.75V24.75Z" fill={select ? "black" : "none"} stroke="black" />
        </svg>
      )
    case "radial":
      return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M34.99 25.01H25.01V34.99H34.99V25.01Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M34.99 2.25H25.01V12.23H34.99V2.25Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M13.9085 6.84226L6.85156 13.8992L13.9085 20.9561L20.9654 13.8992L13.9085 6.84226Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M12.23 25.01H2.25V34.99H12.23V25.01Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M13.9102 39.0339L6.85333 46.0908L13.9103 53.1477L20.9672 46.0908L13.9102 39.0339Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M34.99 47.77H25.01V57.75H34.99V47.77Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M46.0949 39.0393L39.038 46.0962L46.0949 53.1531L53.1519 46.0962L46.0949 39.0393Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M57.75 25.01H47.77V34.99H57.75V25.01Z" fill={select ? "black" : "none"} stroke="black" />
          <path d="M46.0932 6.84763L39.0363 13.9045L46.0932 20.9615L53.1501 13.9046L46.0932 6.84763Z" fill={select ? "black" : "none"} stroke="black" />
        </svg>
      )
    default:
      return null
  }

}

export default IconArray