import React from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Paths, APP_NAME } from '@/utils/constants';
import { useAuth } from '@/contexts/auth';

export default function Header() {
  const auth = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    auth.logout(() => {
      history.replace(Paths.login);
    });
  };

  return (
    <div className="fixed flex flex-wrap items-center bg-white p-4 border-b border-gray-200 h-16 w-full z-30">
      <div className="flex items-center">
        <Link to={Paths.main} className="text-gray-900 text-base">
          {APP_NAME}
        </Link>
      </div>
      <div className="flex items-center space-x-4 ml-4 text-sm text-gray-600">
        <Link to={Paths.gallery} className={`hover:font-black hover:text-black ${pathname === Paths.gallery ? 'font-black' : ''}`}>
          갤러리
        </Link>
        <Link to={Paths.admin} className={`hover:font-black hover:text-black ${pathname === Paths.admin ? 'font-black' : ''}`}>
          관리자
        </Link>
      </div>

      <div className="flex flex-col items-center ml-auto">
        <nav className="flex items-center">
          {auth?.user ? (
            <>
              <span className="mr-4 text-sm truncate" style={{ maxWidth: '4rem' }}>
                {auth?.user?.email?.split('@')[0]}
              </span>
              <a className="text-red-500 bg-red-100 py-1 px-2 rounded-sm text-sm" href="logout" onClick={handleLogout}>
                로그아웃
              </a>
            </>
          ) : (
            <>
              <Link to={Paths.login} className="border text-gray-800 py-1 px-2 text-sm rounded-sm">
                로그인
              </Link>
            </>
          )}
        </nav>
      </div>
    </div>
  );
}
