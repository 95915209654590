import { useEffect } from 'react';
import { APP_KAKAO_KEY } from '@/utils/constants';

export default function useKakao() {
  useEffect(() => {
    if (window?.Kakao?.isInitialized && !window.Kakao.isInitialized()) {
      window.Kakao.init(APP_KAKAO_KEY);
    }
  }, []);

  return window?.Kakao ?? null;
}
