class CaptchaCache {
  private key = 'CAPCHA_CACHED_AT';
  private time = 1000 * 60 * 60 * 24; // 24시간

  cache() {
    window.localStorage.setItem(this.key, `${new Date().getTime()}`);
  }

  get show() {
    const item = window.localStorage.getItem(this.key);
    if (item) {
      const cachedAt = Number(item);
      return cachedAt + this.time < new Date().getTime();
    }
    return true;
  }

  private has() {
    return !!window.localStorage.getItem(this.key);
  }
}

export default new CaptchaCache();
