const Loader = () => {
  return (
    <div id='dim' className='fixed z-20 inset-0 bg-white bg-opacity-50 cursor-wait'>
      <div className='flex flex-row absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        {[...Array(10)].map((x, i) =>
          <div key={i} className='spinner w-6 h-6 md:w-10 md:h-10 rounded-full border border-black bg-white'>{x}</div>
        )}
      </div>
    </div>
  )
}

export default Loader