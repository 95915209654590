import React, { useContext, createContext, useState } from 'react';
import { User as IAuthUser } from 'firebase/auth';
import { authService } from '@/services/firebase';

interface IAuthForm {
  username: string;
  password: string;
}

const authContext = createContext<any>(undefined);

type AuthStore = {
  user: IAuthUser | null;
  booted: boolean;
  login: (params: IAuthForm, cb: (success: boolean) => void) => Promise<void>;
  logout: (cb: () => void) => Promise<void>;
  refresh: (cb: () => void) => Promise<void>;
};

export function useAuth(): AuthStore {
  return useContext(authContext);
}

function useProvideAuth(): AuthStore {
  const [user, setUser] = useState<IAuthUser | null>(null);
  const [booted, bootstrap] = useState(false);

  const login = async (params: IAuthForm, cb: (success: boolean) => void) => {
    try {
      const user = await authService.signIn(params.username, params.password);
      if (user) {
        setUser(user);
        cb(true);
      } else {
        cb(false);
      }
    } catch (error) {
      console.error(error);
      cb(false);
    }
  };

  const logout = async (cb: () => void) => {
    authService.signOut().then(() => {
      setUser(null);
      cb();
    });
  };

  const refresh = async (cb: () => void) => {
    authService.refresh(
      (user) => {
        bootstrap(true);
        setUser(user);
        cb();
      },
      () => {
        bootstrap(true);
      },
    );
  };

  return {
    user,
    booted,
    login,
    logout,
    refresh,
  };
}

export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
